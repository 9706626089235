import React, {useState} from 'react';
import {Container, Row, Col, Form} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import ButtonSubmit from '../../../components/ButtonSubmit';
import Message from '../../../components/PopupMessage';

// img
import rightArrow from '../../../assets/images/btn-arrow-black.svg';

import {connect} from 'react-redux'
import {postFormSubmit} from "../../../redux/actions/form";

let ContactForm = (props) => {
  let { formFields, submitForm, formSubmitErr } = props
  let [name, setName] = useState('')
  let handleName = (e) => {
    setName(e.target.value)
  }
  let [phone, setPhone] = useState('')
  let handlePhone = (e) => {
    setPhone(e.target.value)
  }
  let [email, setEmail] = useState('')
  let handleEmail = (e) => {
    setEmail(e.target.value)
  }
  let [message, setMessage] = useState('')
  let handleMessage = e => {
    setMessage(e.target.value)
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let formField = {
      form_id: 'contact-form'
    }
    let len = formFields.length

    for(let i = 0; i < len; i++) {
      formField[formFields[i]["field_key"]] = ""
    }

    formField["name"] = name
    formField["phone"] = phone
    formField["email"] = email
    formField["message"] = message

    submitForm(formField)
    if(!props.formSubmitLoading) {
      setName('')
      setPhone('')
      setEmail('')
      setMessage('')
    }
  }

  return (

  <>
    <Message
        title={formSubmitErr ? 'Error' : 'Form Submitted'}
    />
    <Col sm={6} className="map-right pt-110 pb-110">
      <SubTitle text={'contact us'} margin={'0 0 50px 0'}/>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={'Enter name'}
            value={name}
            onChange={handleName}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            type="number"
            placeholder={'Enter mobile number'}
            value={phone}
            onChange={handlePhone}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            type="email"
            placeholder={'Enter email address'}
            value={email}
            onChange={handleEmail}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={'Enter message'}
            value={message}
            onChange={handleMessage}
          />
        </Form.Group>
        <ButtonSubmit
          onSubmit={handleSubmit}
          margin={'30px 0 0 0'}
          text={'Submit'}
          fontSize={'16'}
          fontWeight={'500'}
          color='#000'
          img={rightArrow}
          border={'1px solid #000000'}
          width={'100%'}
        />
      </Form>

    </Col>
    </>
  )
};

function mapStateToProps(state) {
  return {
    formFields: state.formReducer.formFields,
    formSubmitErr: state.formReducer.formSubmitErr,
    formSubmitLoading: state.formReducer.formSubmitLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitForm: body => dispatch(postFormSubmit(body))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
