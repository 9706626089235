import React, {Component} from 'react';
import {PageAnimation} from "../../../components/PageAnimation";
import {motion} from "framer-motion";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {Container, Table} from "react-bootstrap";
import {connect} from 'react-redux'
import {fetchQuantumTableOne, fetchQuantumTableTwo} from '../../../redux/actions/quantum'
import {ApiParams} from "../../../constants/apiParams";

class QuantumTable extends Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_slug]: this.props.slug
    }
    if(this.props.slug === 'quantum-table-one') {
      this.props.getTableOne(params)
    } else {
      this.props.getTableTwo(params)
    }
  }

  render() {
    let { tableOne, tableTwo } = this.props;
    let tablePage = this.props.slug === 'quantum-table-one' ? tableOne : tableTwo

    let posts = tablePage ? tablePage.posts ? tablePage.posts.list : [] : []
    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <StyledQuantumTable>
         <Container className="pt-110 pb-110">
           <div className="division-content__wrap" >
             <div className="division-content__wrap__at-a-glance">
               {posts.length ? posts.map(item => (
                   <div key={item.data.id} className={`${item.data.slug}`}>
                     {ReactHtmlParser(item.data.description)}
                   </div>
               )) : ''}
             </div>
           </div>
         </Container>
        </StyledQuantumTable>
      </motion.div>
    )
  }
};

const StyledQuantumTable = styled.div`
  background-color: #2E3744;

  .division-content__wrap {
    position: relative;
    color: #fff;
    z-index: 2;

    .button-explore {
      position: absolute;
      right: 100px;
      padding-top: 25px;
    }

    &__at-a-glance {
      position: relative;
      
      h2 {
        color: #FF6868;
        font-size: 33px;
        text-align: center;
      }
      p{
        color: #fff;
        font-size: 18px;
        line-height: 28px;
      }
      ul {
        li {
          padding-top: 18px;
          padding-bottom: 24px;
          border-bottom: 1px solid #707070;
        }
      }

      .table-dark {
        background-color: #2E3744;
      }

      .table-two {
        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;

              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1px solid #00000000 !important;
                border-right: 1rem solid #2E3744;

                &:nth-last-of-type(1) {
                  border-right: 0px solid #2E3744;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(even) {
              background-color: #39414e;
            }

            &:nth-of-type(odd) {
              background-color: #2E3744;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1px solid #00000000 !important;
              border-right: 1rem solid #2E3744;

              &:nth-last-of-type(1) {
                border-right: 0px solid #2E3744;
              }
            }
          }
        }
      }

      .table-three {
        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;

              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #2E3744 !important;
                border-right: 1rem solid #2E3744;

                &:nth-last-of-type(1) {
                  border-right: 0px solid #2E3744;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #39414e;
            }

            &:nth-of-type(even) {
              background-color: #2E3744;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #2E3744 !important;
              border-right: 1rem solid #2E3744;

              &:nth-last-of-type(1) {
                border-right: 0px solid #2E3744;
              }
            }
          }
        }
      }

      .table-four {
        width: 50%;

        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;

              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #2E3744 !important;
                border-right: 1rem solid #2E3744;

                &:nth-last-of-type(1) {
                  border-right: 0px solid #2E3744;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #39414e;
            }

            &:nth-of-type(even) {
              background-color: #2E3744;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #2E3744 !important;
              border-right: 1rem solid #2E3744;

              &:nth-last-of-type(1) {
                border-right: 0px solid #2E3744;
              }
            }
          }
        }
      }

      .table-five {
        table {
          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #39414e;
            }

            &:nth-of-type(even) {
              background-color: #2E3744;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #2E3744 !important;
              border-right: 1rem solid #2E3744;

              &:nth-last-of-type(1) {
                border-right: 0px solid #2E3744;
              }

              &:nth-of-type(1) {
                background-color: #00000060;
              }
            }
          }
        }
      }

      .table-six {
        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;
              &:nth-of-type(1) {
                text-align: center;
              }
              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #2E3744 !important;
                border-right: 1rem solid #2E3744;
                
                &:nth-last-of-type(1) {
                  border-right: 0px solid #2E3744;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #39414e;
            }

            &:nth-of-type(even) {
              background-color: #2E3744;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #2E3744 !important;
              border-right: 1rem solid #2E3744;

              &:nth-last-of-type(1) {
                border-right: 0px solid #2E3744;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .division-content__wrap {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 767px) {

    .pb-110, .pb-150 {
      padding-bottom: 48px;
    }

    .pt-110, .pt-150 {
      padding-top: 48px;
    }
  }
`

function mapStateToProps(state) {
  return {
    tableOne: state.quantumReducer.tablePageOne,
    tableTwo: state.quantumReducer.tablePageTwo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTableOne: params => dispatch(fetchQuantumTableOne(params)),
    getTableTwo: params => dispatch(fetchQuantumTableTwo(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuantumTable);
