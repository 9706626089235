import React, {useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import ReactHtmlParser from "react-html-parser";
import Button from '../../../components/Button'
import Modal from "../../../components/Modal";

const PerformanceText = ({ posts, sectionId }) => {
    let sectionPost = posts ? posts.find(item => item.data.slug === 'title-section') : null

    let [openModal, setOpenModal] = useState(false)
    let [title, setTitle] = useState('')
    let [description, setDescription] = useState('')

    let handleModal = () => {
       setOpenModal(!openModal)
    }

    let handlePost = (title, description) => {
        setTitle(title)
        setDescription(description)
        handleModal()
    }

    return (
        <StyledPerformanceText id="performence-text" className="head-content pt-110 pb-110">
            <Container>
                <Modal
                    show={openModal}
                    handleClose={handleModal}
                    post={sectionPost ? sectionPost.data ? sectionPost.data.description : '' : ''}
                    title={sectionPost ? sectionPost.data.title : ''}
                />
                <Row>
                    <Col md={12} className="head-content-text">
                        <Title
                            color={'#BC101D'}
                            fontSize={'56'}
                            fontWeight={'500'}
                            margin={'0 0 12px 0'}
                            lineHeight={'58'}
                            text={sectionPost ? sectionPost.data.title : ''}
                        />
                        <SubTitle
                            color={'#FFF'}
                            letterSpacing={'4'}
                            fontSize={'20'}
                            fontWeight={'300'}
                            text={sectionPost ? sectionPost.data['Extra description one'] : ''}
                            textTransform={'uppercase'}
                            margin={'0 0 64px 0'}
                        />
                        <Text
                            color={'#FFF'}
                            fontWeight={'300'}
                            fontSize={'18'}
                            margin={'0 0 32px 0'}
                            lineHeight={28}
                            line={14}
                        >
                           <p>{sectionPost ? sectionPost.data ?
                             ReactHtmlParser(sectionPost.data.description) : ''
                             : ''
                           }</p>
                        </Text>

                        <div onClick={() => handlePost(sectionPost ? sectionPost.data.title : '', sectionPost ? sectionPost.data ? sectionPost.data.description : '' : '')}>
                           <Button text={'Read More'} color={'#fff'} border={'1px solid #fff'} fontSize={20}/>
                       </div>
                    </Col>
                </Row>
            </Container>

        </StyledPerformanceText>

    )
};

const StyledPerformanceText = styled.div`
   .head-content-text {
     h2 {
       text-align: center;
     }
     h3 {
       text-align: center;
     }
   }

  @media (max-width: 991px) {
    &.pb-110 {
      padding-bottom: 160px;
    }

    .col-md-7 {
      min-width: 100%;
    }

    .title {
      margin-bottom: 30px;
    }
  }
`;

export default PerformanceText;
