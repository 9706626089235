import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import SubText from '../../../components/SubText';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import Modal from "../../../components/Modal";

// img
import icon from '../../../assets/images/Elevator System.svg'
import bg from "../../../assets/images/achievements.jpg";
import {baseUrl} from "../../../components/BaseUrl";
import Text from "../../../components/Text";
import ReactHtmlParser from "react-html-parser";
import {fetchHomeBusinessDivisionData} from "../../../redux/actions/home";
import {connect} from 'react-redux'
import {ApiParams} from "../../../constants/apiParams";

class WhyGPHIspat extends Component{
  state  = {
    openModal: false,
    title: '',
    description: ''
  }
  componentDidMount() {
    let params = {
      [ApiParams.page_type]: 'home-business-division'
    }
    this.props.getHomeBusinessData(params)
  }

  handleModal = () => {
    this.setState({ openModal: !this.state.openModal })
  }

  handlePost = (title, description) => {
    this.setState({ title: title, description: description })
    this.handleModal()
  }

  render() {
    let { businessData } = this.props;
    let pageData = businessData ? businessData.page_data : null;
    let pageTitle = pageData ? pageData.title : '';
    let subTitle = pageData ? pageData.sub_title : ''

    let posts = businessData ? businessData.posts ? businessData.posts.list : [] : []

    return (
      <StyledWhyGPHIspat className="pt-110 pb-110">
        <Container className="title-here">
          <Modal
            show={this.state.openModal}
            handleClose={this.handleModal}
            post={this.state.description ? this.state.description : ''}
            title={this.state.title ? this.state.title : ''}
          />
          <SubTitle
            text={pageTitle}
            fontSize={56}
            margin={'32px 0 21px 0'}
            color={'#fff'}
          />
          <SubTitle
            color={'#FFF'}
            letterSpacing={'0'}
            fontSize={'16'}
            fontWeight={'300'}
            text={subTitle}
            textTransform={'capitalize'}
            margin={'0 0 40px 0'}
          />
        </Container>
        {posts.length ? posts.map((item, key) => {
          let title = item.data.title;
          let description = item.data.description;
          let image = item.images ?
            item.images.length ? baseUrl + item.images[0].path + item.images[0].img_name : ''
            : ''
          return (
            <div key={item.data.id} className={key % 2 ? 'content-right' : 'content'}>
              {
                key % 2 ? (
                    <Container className="text-body">
                      <Row className="row-grid">
                        <Col md={12}>
                          <Title
                              text={ReactHtmlParser(title)}
                              fontSize={54}
                              margin={'32px 0 21px 0'}
                              color={'#fff'}
                              fontWeight={500}
                              textAlign={'right'}
                          />
                          <div className="red-back">
                            <Text
                              text={description}
                              color={'#fff'}
                              fontSize={16}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="button-grid">
                          <div onClick={() => this.handlePost(title, description)}>
                            <Button text={'Explore'} fontSize={20}/>
                          </div>
                        </Col>
                      </Row>
                      <img src={image}/>
                    </Container>
                ) : (
                    <Container className="text-body">
                      <img src={image}/>
                      <Row className="row-grid">
                        <Col md={12}>
                          <Title
                              text={ReactHtmlParser(title)}
                              fontSize={54}
                              margin={'32px 0 21px 0'}
                              color={'#fff'}
                              fontWeight={500}
                          />
                          <div className="red-back">
                            <Text
                              text={description}
                              color={'#fff'}
                              fontSize={16}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="button-grid">
                          <div onClick={() => this.handlePost(title, description)}>
                            <Button text={'Explore'} fontSize={18}/>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                )
              }

            </div>
          )
        }) : null}
      </StyledWhyGPHIspat>

    )
  }
};

const StyledWhyGPHIspat = styled.div`
  .title-here {
    .title {
      width: 60%;
    }
  }

  .content {
    //.container {
    //  margin-left: 0;
    //  padding-left: 0;
    //}
    .text-body {
      display: flex;
      &:nth-last-of-type(1) {
        margin-top: 50px;
      }
      img {
        width: 80%;
        margin-left: -200px;
        margin-right: 0;
        object-fit: cover;
        transform: scale(1.01);
        transition: all 2s ease;
      }

      .row-grid {
        padding-left: 48px;
        z-index: 4;
        .red-back{
          background-color: #BC101D;
          padding: 30px 30px 10px 70px;
          position: absolute;
          left: -200px;
          margin-top: 24px;
          margin-right: 15px;
        }
        .button-grid {
          padding-top: 100px;
        }
      }
    }
    
    @media(max-width: 1600px) {
      //.container {
      //  margin-left: 0;
      //  padding-left: 0;
      //}
      .text-body {
        display: flex;
        &:nth-last-of-type(1) {
          margin-top: 50px;
        }
        img {
          width: 80%;
          margin-left: -171px;
          margin-right: 0;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 2s ease;
        }

        .row-grid {
          padding-left: 48px;
          z-index: 4;
          .red-back{
            background-color: #BC101D;
            padding: 16px 16px 0px 30px;
            position: absolute;
            left: -200px;
            margin-top: 24px;
          }
          .button-grid {
            padding-top: 150px;
          }
        }
      }
    }

    @media(max-width: 1199px) {
      //.container {
      //  margin-left: 0;
      //  padding-left: 0;
      //}
      .text-body {
        display: flex;
        &:nth-last-of-type(1) {
          margin-top: 50px;
        }
        img {
          width: 75%;
          margin-left: -130px;
          margin-right: 0;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 2s ease;
        }

        .row-grid {
          padding-left: 48px;
          z-index: 4;
          .red-back{
            background-color: #BC101D;
            padding: 16px 16px 0px 30px;
            position: absolute;
            left: -200px;
            margin-top: 24px;
          }
          .button-grid {
            padding-top: 150px;
          }
        }
      }
    }

    @media(max-width: 767px) {
      .container {
        margin-left: 0;
        padding-left: 0;
      }
      .title {
        font-size: 20px;
        line-height: 23px;
        height: auto !important;
        margin: 15px 0 25px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        line-height: 24px;
        -webkit-line-clamp: 3;
      }
      .text-body {
        display: flex;
        flex-direction: column;
        position: relative;
        &:nth-last-of-type(1) {
          margin-top: 50px;
        }
        img {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 2s ease 0s;
          padding-left: 15px;
        }

        .row-grid {
          padding-left: 15px;
          z-index: 4;
          width: 100%;
          display: block;
          .red-back{
            background-color: rgb(188, 16, 29);
            padding: 16px 16px;
            position: absolute;
            right: 0px;
            top: -150px;
            left: 0;
            width: 100%;
            margin-top: 0;
          }
          .button-grid {
            padding-top: 0px;
          }
        }
      }
    }
  }

  .content-right {
    //.container {
    //  margin-right: 0;
    //  padding-right: 0;
    //}
    .text-body {
      display: flex;
      margin-top: 50px;
      img {
        width: 80%;
        margin-left: 0;
        margin-right: -200px;
        object-fit: cover;
        transform: scale(1.01);
        transition: all 2s ease;
      }

      .row-grid {
        padding-right: 48px;
        z-index: 4;
        .red-back{
          background-color: #BC101D;
          padding: 30px 30px 10px 70px;
          position: absolute;
          right: -200px;
          margin-top: 24px;
          margin-left: 15px;
        }
        .button-grid {
          direction: rtl;
          padding-top: 100px;
        }
      }
    }

    @media(max-width: 1600px) {
      //.container {
      //  margin-right: 0;
      //  padding-right: 0;
      //}
      .text-body {
        display: flex;
        margin-top: 50px;
        img {
          width: 80%;
          margin-left: 0;
          margin-right: -171px;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 2s ease;
        }

        .row-grid {
          padding-right: 48px;
          z-index: 4;
          .red-back{
            background-color: #BC101D;
            padding: 16px 16px 0px 30px;
            position: absolute;
            right: -200px;
            margin-top: 24px;
          }
          .button-grid {
            direction: rtl;
            padding-top: 150px;
          }
        }
      }
    }

    @media(max-width: 1199px) {
      //.container {
      //  margin-right: 0;
      //  padding-right: 0;
      //}
      .text-body {
        display: flex;
        margin-top: 50px;
        img {
          width: 75%;
          margin-left: 0;
          margin-right: -130px;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 2s ease;
        }

        .row-grid {
          padding-right: 48px;
          z-index: 4;
          .red-back{
            background-color: #BC101D;
            padding: 16px 16px 0px 30px;
            position: absolute;
            right: -200px;
            margin-top: 24px;
          }
          .button-grid {
            direction: rtl;
            padding-top: 150px;
          }
        }
      }
    }

    @media(max-width: 767px) {
      .container {
        margin-right: 0;
        padding-right: 0;
      }
      .title {
        font-size: 20px;
        line-height: 23px;
        height: auto !important;
        margin: 15px 0 25px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        line-height: 24px;
        text-align: right;
        -webkit-line-clamp: 3;
      }
      .text-body {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        margin-top: 50px;
        img {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          padding-right: 15px;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 2s ease;
        }

        .row-grid {
          padding-right: 16px;
          z-index: 4;
          .red-back{
            background-color: #BC101D;
            padding: 16px 16px 16px 16px;
            position: absolute;
            right: 0px;
            top: -150px;
            width: 93%;
            margin-top: 0;
          }
          .button-grid {
            direction: rtl;
            padding-top: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {

    .title-here .sub-title {
      margin: 16px 0 10px 0 !important;
      font-size: 18px;
    }

    .title-here .sub-title {
      margin-bottom: 30px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }
  }
`;

function mapStateToProps(state) {
  return {
    businessData: state.homeReducer.businessData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getHomeBusinessData: params => dispatch(fetchHomeBusinessDivisionData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhyGPHIspat);
