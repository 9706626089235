import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../components/SubTitle';
import Text from '../components/Text';
import Button from "../components/Button"
import {baseUrl} from "../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import news from '../assets/images/news.svg'
import {connect} from 'react-redux'
import {fetchEventPublications} from "../redux/actions/event-publication";
import {ApiParams} from "../constants/apiParams";
import {withRouter, Link} from 'react-router-dom'

class EventPublications extends Component {
  componentDidMount() {
    let params = {
      [ApiParams.page_type]: 'publications'
    };
    this.props.getEventPublications(params);
  }

  render() {
    let {publication} = this.props;
    let lists = publication ?
      publication.posts ?
        publication.posts.list
        : []
      : []
    let deskImage = publication ?
      publication.images ?
        publication.images.list[0] ?
          baseUrl + publication.images.list[0].path + publication.images.list[0].img_name : ''
        : ''
      : ''

    return (
      <StyledEventPublications className="event-publication" deskImage={deskImage}>
        <Container className="flex-grid" fluid>
          <Row>
            <Col md={3}>
              <img src={deskImage}/>
            </Col>
            <Col md={9}>
              <Container fluid>
                <div className="event-publication__text pt-110">
                  <SubTitle
                      text={'Events & Publication'}
                      fontSize={32}
                      fontWeight={'normal'}
                      textTransform={'normal'}
                      color={'#FF9C68'}
                  />
                </div>
                <Row>
                  <Col md={12} className="d-flex">
                    {lists.length ? lists.map((list) => {
                      let title = list.data.title
                      let icon = list.images ? baseUrl + list.images[0].path + list.images[0].img_name : ''
                      return (
                          <div key={list.data.id} className="grid">
                            <Link
                                to={
                                  list.data.slug === 'news' ?
                                      `/${list.data.slug}` :
                                      `/event-publication/${list.data.slug}`
                                }
                            >
                              <div className="event-publication__grid">
                                <img src={icon} alt="events-icon"/>
                                <SubTitle
                                    text={title}
                                    fontSize={18}
                                    fontWeight={'normal'}
                                    textTransform={'normal'}
                                    color={'#fff'}
                                    margin={'16px 0 0 0'}
                                />
                              </div>
                            </Link>
                          </div>
                      )
                    }) : ''}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </StyledEventPublications>
    )
  }
};

const StyledEventPublications = styled.div`
  // background-image: url(${props => props.deskImage || ''});
  // background-position: revert;
  // background-repeat: no-repeat;
  // -webkit-background-size: cover;
  // background-size: cover;

  background-color: #272E39;

  .event-publication__text {
    padding: 100px 0px 50px;
  }
  
  .flex-grid {
    padding-left: 0px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100%);
    }
    
    .d-flex {
      display: flex !important;
      //justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 100px;
    }
    
    .grid {
      padding-right: 25px;
      padding-bottom: 25px;
      width: 25%;
      float: left;
      .sub-title {
        font-size: 14px;
      }
    }
    
    .event-publication__grid {
      padding: 60px 60px 40px 60px;
      width: 100%;
      height: 188px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
      cursor: pointer;
      
      img{
        width: 50%;
        height: 60%;
        object-fit: contain;
      }
    }
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-4 {
    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .col-8 {
    img {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 4%;
      padding-right: 0;
    }

    .col-md-3 {
      display: none;
      img {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    .grid {
      padding-right: 15px !important;
      padding-bottom: 15px !important;
      width: 25%;
      float: left;

      .sub-title {
        font-size: 14px;
      }
    }
    .flex-grid {
      padding-left: 3%;
      padding-right: 1%;
      .event-publication__grid {
        padding: 40px;
        width: 100% !important;
        height: 170px !important;
        text-align: center;
        background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.05);
      }
    }
    .event-publication__text {
      padding-top: 70px;
      padding-left: 0px;
      padding-right: 24px;
      height: 100%;
    }

    .col-8 {
      min-width: 100%;
      margin-left: 0;

      img {
        display: block;
        max-width: 100%;
        object-fit: contain;
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
    }

    .col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .col-md-3 {
      display: none;
      img {
        display: none;
      }
    }
    .col-4 {
      img {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .grid {
      padding-right: 15px !important;
      padding-bottom: 15px !important;
      width: 50% !important;
      float: left;
      
      .sub-title {
        font-size: 14px;
      }
    }
    
    .flex-grid {
      padding-left: 16px;
      padding-right: 0px;
    }
    
    .event-publication__text {
      padding: 20px 0px 0px;
      height: 100%;
    }

    .event-publication__grid {
      padding: 40px;
      width: 100% !important;
      height: 170px !important;
      text-align: center;
      background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.05);
    }
  }

  @media (max-width: 365px) {
    .d-flex {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .flex-grid .event-publication__grid {
      padding: 40px;
      width: 130px;
      height: 130px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media (max-width: 325px) {
    .d-flex {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .flex-grid .event-publication__grid {
      padding: 30px;
      width: 120px;
      height: 120px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    publication: state.publicationReducer.publicationMain
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getEventPublications: params => dispatch(fetchEventPublications(params))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventPublications));
