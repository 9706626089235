import Index from './container/home/';
import React, {useEffect} from 'react';
import Division from './container/division/';
import DivisionDetail from './container/division/division-detail';
import About from './container/about/';
import Career from './container/career/';
import sustainabilityDetail from './container/home/general-detail';
import CareerApply from './container/career/apply';
import Media from './container/media-news/';
import MediaDetails from './container/media-news/details';
import Services from './container/services/';
import StyledGlobal from './globalStyle';
import Footer from './components/Footer';
import Contact from './container/contact';
import Common from './container/common'
import Error from './container/error';
import Sustainability from './container/sustainability'
import Products from './container/products'
import Investor from "./container/investor";
import PublicationInner from './container/event-publication'
import Specification from './container/productSpecification'
import {Route, Switch,useLocation} from 'react-router-dom';
import {AnimatePresence } from 'framer-motion';
import Quantum from "./container/quantum";

function App() {
    const location = useLocation()
    return (
        <React.Fragment>
            <StyledGlobal/>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname} >
                    <Route exact path="/" component={Index}/>
                    <Route exact path="/division" component={Division}/>
                    <Route exact path="/division-detail/:slug" component={DivisionDetail}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/career" component={Career}/>
                    <Route exact path="/career/apply/:title" component={CareerApply}/>
                    <Route exact path="/media" component={Media}/>
                    <Route exact path="/quantum" component={Quantum} />
                    {/*<Route exact path="/sustainability/details/" component={sustainabilityDetail}/>*/}
                    <Route exact path="/quantum" component={Quantum} />
                    <Route exact path="/services" component={Services}/>
                    <Route exact path="/contact" component={Contact}/>

                    <Route exact path="/media/details/:slug/:post_slug" component={MediaDetails}/>
                    <Route exact path="/:page_type" component={Common}/>
                    <Route exact path="/sustainability/:page_slug" component={Sustainability} />
                    <Route exact path="/products/:page_slug" component={Products} />
                    <Route exact path="/investor/:page_slug" component={Investor} />
                    <Route exact path="/event-publication/:page_slug" component={PublicationInner} />
                    <Route exact path="/products-detail/:page_slug" component={Specification} />
                    <Route component={Error}/>
                </Switch>
            </AnimatePresence>
            <Footer/>
        </React.Fragment>
    );
}


export default App;
