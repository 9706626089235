import {FORM} from "../../constants/form";

let initialState = {
  formFields: [],
  formFieldLoading: false,
  formFieldErr: '',
  formSubmitData: [],
  formSubmitLoading: false,
  popup: false,
  formSubmitErr: ''
}

export default function formReducer(state= initialState, action) {
  switch (action.type) {
    case FORM.FETCH_FORM_DATA.MAIN:
      return {
        ...state,
        formFields: [],
        formFieldLoading: true,
        formFieldErr: '',
        popup: false
      }

    case FORM.FETCH_FORM_DATA.SUCCESS:
      return {
        ...state,
        formFields: action.result,
        formFieldLoading: false,
        formFieldErr: '',
        popup: false
      }

    case FORM.FETCH_FORM_DATA.FAILURE:
      return {
        ...state,
        formFields: [],
        formFieldLoading: false,
        formFieldErr: 'Error from Fetching form field',
        popup: false
      }

    case FORM.POST_FORM_DATA.MAIN:
      return {
        ...state,
        formSubmitData: [],
        formSubmitLoading: true,
        formSubmitErr: '',
        popup: false
      }

    case FORM.POST_FORM_DATA.SUCCESS:
      return {
        ...state,
        formSubmitData: action.result,
        formSubmitLoading: false,
        formSubmitErr: '',
        popup: true
      }

    case FORM.POST_FORM_DATA.FAILURE:
      return {
        ...state,
        formSubmitData: [],
        formSubmitLoading: false,
        formSubmitErr: 'Error from Form Submit',
        popup: true
      }

    default:
      return state
  }
}
