import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from "react-redux";
// css

// img

import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";
import $ from "jquery";

const Gallery = (props) => {
  let { posts,footerOffset } = props;
  // let [leftOffset, setLeftOffset] = useState(0);

  let galleryData = posts ? posts.find(post => post.data.slug === 'gallery') : null

  let galleryImages = galleryData ? galleryData.images : [];

  let len = galleryImages.length;

  const params = {
    // effect: "fade",
    fadeEffect: {crossFade: true},
    speed: 1500,
    autoplay: {
      delay: 5500,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
  };

  useEffect(() => {
    let getOffset = $('.container').offset().left;
    setTimeout(function () {
      // if($('.business__single').length < 10){
        $('.division-gallery .swiper-pagination').prepend('<span class="total-0">0</span><span class="current-0">0</span>');
      // }
      if($(window).width() > 769){
        $('.division-gallery .swiper-pagination').css('right',getOffset+15);
        $('.division-gallery .swiper-button-next').css('right',getOffset+15);
        $('.division-gallery .swiper-button-prev').css('right',getOffset+15);
      }
    },500);

  }, []);

  return (
    <StyledGallery className='division-gallery' offset={footerOffset}>
      <Container className="gallery-title">
        <SubTitle text={'gallery'} />
      </Container>

      <Swiper  {...params}>
        {len && len === galleryImages.length ? galleryImages.map(data => {
          let imageSlide = baseUrl + data.path + data.img_name
          return (
            <div className="gallery__single">
              <img className="gallery__single__img" src={imageSlide} alt=""/>
              <Container id="main-container">
                <Row>
                  <Col sm={6}>
                    <div className="gallery__single__content">
                      <Title color={'#FFF'} margin={'0 0 30px 0'} text={data.short_title} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        }) : null}
      </Swiper>
    </StyledGallery>
  )
};

const StyledGallery = styled.div`
    position: relative;
    
    .gallery-title{
      position: absolute;
      z-index: 3;
      margin: auto;
      left: 0;
      right: 0;
      top: 110px;
    }
    
    .gallery__single{
      position: relative;
      height: 100vh;
      max-height: 600px;
       &:after{
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.5);
        position: absolute;
       }
    .container{
      position: relative;
    }
      &__img{
         height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
     
      }
      
      &__content{
        position: relative;
        z-index: 2;
        margin-top: 220px;
      }
      
    }

   
   .swiper-pagination{
       width: fit-content;
       position: absolute;
       top: 100px;
       right:${props=> props.offset+29 || '100'}px;;
       z-index: 5;
       color:transparent;
       flex-direction: column;
       height: fit-content;
       span{
        font-size: 50px;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
        display: flex;
        &.swiper-pagination-current{
          position: relative;
          &:after{
            content: '';
             height: 2px;
            width: 200%;
            bottom: -6px;
            background-color: #969696;
            position: absolute;
           left: -113%;
          }
        }
        &.swiper-pagination-total{
          //margin-top: -31px;
          color: #969696;
          margin-top: -5px;
        }
       }
   }

  .swiper-button-next{
       position: absolute;
        right:${props=> props.offset+15 || '100'}px;
        top: 0;
        
        margin: auto;
        z-index: 3;
        bottom: -60px;
        height: 50px;
        width: 50px;
        background-image: url(${RightIcon});
        background-position: center;
        background-size: contain;
         &:focus{
          outline:none;
        }
        &:after{
          display: none;
        }
  }
  .swiper-button-prev{
      left: auto;
       position: absolute;
        right:${props=> props.offset+15 || '100'}px;;
        top: 0;
        bottom: 60px;
        margin: auto;
        z-index: 3;
       
        height: 50px;
        width: 50px;
        background-image: url(${LeftIcon});
        background-size: contain;
        background-repeat: no-repeat;
         &:after{
          display: none;
        }
        &:focus{
          outline:none;
        }
  }
  
  .swiper-pagination-fraction{
    left: auto;
  }
  
    .total-0{
    position: absolute;
    bottom: 0;
    right: 29px;
    color: #969696 !important;
  }
  .current-0{
    position: absolute;
    right: 29px;
  }
  
  @media(max-width:768px){
    .swiper-pagination{
      top: 126px;
      right: 50px;
      
    }
    .swiper-button-next{
      right: 50px !important;
    }
    .swiper-button-prev{
      right: 50px !important;
    }
  }
  
  @media(max-width:767px){
  
   .gallery__single{
      height: 400px;
     }
     .gallery-title{
      top: 50px !important;
     }
    .swiper-pagination{
      top: 50px;
      right: 15px;
      left: auto;
      span{
        font-size: 30px;
        font-weight: 300;
        line-height: 28px;
        &:nth-last-of-type(1){
          margin-top: -9px;
        }
      }
    }
    .swiper-button-next{
      right: 15px !important;
      bottom: 20px;
      top: auto;
      
    }
    .swiper-button-prev{
      right: 15px !important;
      bottom: 80px;
      top: auto;
      left:auto ;
    }
    .gallery__single__content{
      margin-top: 100px;
    }
    .col-sm-6{
      min-width: 100% !important;
    }
    .gallery__single__content .link{
      margin-top: 100px;
    }
     .business__single__content .link{
      margin-top: 100px;
    }
    .current-0,.total-0{
      right: 20px;
    }
  }
  
`;

let mapStateToProps = state => {
  return {
    footerOffset: state.footerReducer.footerOffset
  }
};
export default connect(mapStateToProps)(Gallery);
