import React, {useEffect, useLayoutEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import JobDesc from './components/JobDescription';
import Menu from "../../components/Menu";

const Apply = ()=>{
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
            <Menu />
            <JobDesc />
        </div>
    )
};

export default Apply;