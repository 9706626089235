import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import SubText from '../../../components/SubText';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';

// img
import icon from '../../../assets/images/Elevator System.svg'
import bg from "../../../assets/images/achievements.jpg";
import {baseUrl} from "../../../components/BaseUrl";
import { fetchHomeAboutUsData } from '../../../redux/actions/home'
import {connect} from 'react-redux'
import {ApiParams} from "../../../constants/apiParams";

class Quantum extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'quantum-eaf'
        }
        this.props.getHomeAboutData(params)
    }

    render() {
        let { quantumData } = this.props;
        let pageData = quantumData ? quantumData.page_data : null
        let title = pageData ? pageData.title : ''
        let subTitle = pageData ? pageData.sub_title : ''
        let imagePath = quantumData ?
          quantumData.images ?
            quantumData.images.list ?
                quantumData.images.list[0] ?
                  baseUrl + quantumData.images.list[0].path + quantumData.images.list[0].img_name : ''
              : ''
            : ''
          : ''

        let posts = quantumData ? quantumData.posts ? quantumData.posts.list : [] : []

        return (
          <StyledQuantum className="pt-110 pb-110">
              <Container className="title-here">
                  <div className="borders">
                      <SubTitle text={title} fontSize={56} margin={'32px 0 21px 0'} color={'#fff'}/>
                  </div>
                  <div className="title-div">
                      <SubTitle
                        color={'#FFF'}
                        letterSpacing={'0'}
                        fontSize={'16'}
                        fontWeight={'300'}
                        text={subTitle}
                        textTransform={'lowercase'}
                        margin={'0 0 40px 0'}
                      />
                  </div>
                  <div className="image-div">
                      <img src={imagePath}/>
                  </div>
                  <Row>
                      <Col md={12} className="d-flex">
                          {posts.length ? posts.map(post => {
                              let title = post.data.title
                              let image = post.images ? baseUrl + post.images[0].path + post.images[0].img_name : ''

                              return (
                                <StyledBlock>
                                    <div className="box">
                                        <div className="content">
                                            <img src={image} alt="events-icon"/>
                                            <p>{title}</p>
                                        </div>
                                    </div>
                                </StyledBlock>
                              )
                          }) : ''}
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="title-div">
                              <Button text={'Explore'} fontSize={20} link={'/quantum'}/>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </StyledQuantum>
        )
    }
};

const StyledBlock = styled.div`
  color: white;
  width: calc(25% - 25px);
  margin-bottom: 50px;

  .box {
    padding-top: 100%;
    border: 1px solid #fff;
    position: relative;

    &:hover {
      border: 1px solid #E8434F;
    }
  }

  .content {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    img {
      padding: 24px;
      text-align: center;
    }
  }

  h1 {
    font-size: 54px;
    font-weight: normal;
    color: white;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }

  @media (max-width: 1200px) {
    .content {
      img {
        width: 40%;
        padding: 24px;
        text-align: center;
      }
    }
  }

  @media (max-width: 1200px) {
    .content {
      img {
        width: 50%;
        padding: 24px;
        text-align: center;
      }
    }
  }

  @media (max-width: 767px) {
    width: calc(50% - 15px);
    .content {
      img {
        width: 50%;
        padding: 24px;
        text-align: center;
      }

      p {
        font-size: 16px;
      }
    }
  }
`

const StyledQuantum = styled.div`
  border-bottom: 1px solid rgba(148, 148, 148, 0.42);

  .d-flex {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .title-here {
    .borders {
      text-align: center;
    }

    .title {
      width: 60%;
    }

    .title-div {
      display: flex;
      justify-content: center;
    }

    .image-div {
      display: flex;
      justify-content: center;
      margin: 24px;
      padding-right: 32px;

      img {
        width: 400px;
      }
    }
  }

  @media (max-width: 991px) {
    .image-div {
      img {
        width: 48% !important;
      }
    }
  }

  @media (max-width: 767px) {

    .borders .sub-title {
      margin: 16px 0 10px 0 !important;
    }

    .title-here .sub-title {
      margin-bottom: 60px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }

    .image-div {
      img {
        width: 60% !important;
      }
    }
  }
`;

function mapStateToProps(state) {
    return {
        quantumData: state.homeReducer.aboutData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeAboutData: params => dispatch(fetchHomeAboutUsData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quantum);
