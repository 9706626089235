import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import {baseUrl} from '../../../components/BaseUrl';
import Button from '../../../components/Button';
import 'swiper/css/swiper.css';
import {connect} from 'react-redux';
import {fetchHomeClientData} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import VisibilitySensor from "react-visibility-sensor";

import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";

class ProductsSlider extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-product'
        }
        this.props.getHomeClientData(params)
    }

    render() {
        let {homeClients} = this.props
        const clientList = homeClients ? homeClients.images ? homeClients.images.list : [] : [];
        let len = clientList.length

        const params = {
            slidesPerView: 2,
            spaceBetween: 20,
            speed: 1000,
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true
            // },
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            breakpoints: {
                767: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                769: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },

        };

        return (
            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                {({isVisible}) =>
                    <StyledClient className="pt-110 pb-110">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <SubTitle
                                      color={'#fff'}
                                      fontSize={56}
                                      margin={'0 0 30px 0'}
                                      text={'Products'}
                                    />
                                    <SubTitle
                                      color={'#fff'}
                                      fontSize={16}
                                      fontWeight={200}
                                      textTransform={'capitalize'}
                                      margin={'0 0 30px 0'}
                                      text={'Diversified production'}
                                    />
                                    <div className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                        {
                                            len && len === clientList.length ?
                                                <Swiper  {...params} >
                                                    {clientList.map(data => (
                                                        <div key={data.id} className="client-single">
                                                            <div className="client-single__inner">
                                                                <img
                                                                  src={baseUrl + data.path + data.img_name}
                                                                  alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Swiper> : null
                                        }
                                    </div>

                                    <div className="Products-slider__bottom">
                                        <Button
                                          margin={'-50px 0 0 0'}
                                          link={'/products'}
                                          text={'explore'}
                                          fontSize={20}
                                          color={'#ffffff'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </StyledClient>
                }
            </VisibilitySensor>
        )
    }
};

const StyledClient = styled.div`
    background-color: #414D60;
    .client-single{
      &__inner{
        padding-top: 116.11%;
        border:1px solid #707070;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          bottom:0;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .Products-slider__bottom{
        position: relative;
        z-index: 3;
        margin-top: 100px;
    }
    .swiper-slide{
      margin-bottom: 10px;
    }

  .swiper-container {
    margin-left: -50px;
    margin-right: -50px;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #414D60;
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #414D60;
      right: 0;
      top: 0;
      z-index: 2;
    }

  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }
  
  @media(max-width: 1024px){
    .swiper-button-next {
      position: absolute;
      right: 12px;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: 0;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }

    @media(min-width:769px){
      .swiper-pagination{
        display: none !important;
      }

    }

    @media(max-width:768px){
      .client-single__inner {
        padding-top: 100%;
      }
     .swiper-pagination{
        display: block !important;
      }
      //.swiper-container{
      //  padding-bottom: 45px;
      //}
      .swiper-pagination{
        bottom: 0px;
        .swiper-pagination-bullet{
            height: 5px;
            width: 5px;
            background-color: #000;
            position: relative;
            margin-right: 20px;
            opacity: 1;
          &:after{
            content: '';
            height: 17px;
            width: 17px;
            border-radius: 50%;
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            left: -6px;
            top: -6px;
            opacity: 0;
            z-index: -1;
          }
          &.swiper-pagination-bullet-active{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      .sub-title{
        text-align: center;
      }

    }
`;

function mapStateToProps(state) {
    return {
        homeClients: state.homeReducer.homeClients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeClientData: (params) => dispatch(fetchHomeClientData(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSlider);
