import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {CAREER} from "../../constants/career";
import {ApiServices} from "../../../network/ApiServices";

function* fetchCareerPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    );

    yield put({
      type: CAREER.FETCH_CAREER_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: CAREER.FETCH_CAREER_PAGE.FAILURE
    })
  }
}

function* fetchCareerListData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: CAREER.FETCH_CAREER_LIST.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: CAREER.FETCH_CAREER_LIST.FAILURE
    })
  }
}

export default function* careerSaga() {
  yield takeLatest(CAREER.FETCH_CAREER_PAGE.MAIN, fetchCareerPageData)
  yield takeLatest(CAREER.FETCH_CAREER_LIST.MAIN, fetchCareerListData)
}
