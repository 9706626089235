import React, {Component} from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {baseUrl} from '../../../components/BaseUrl';
// css
import "swiper/swiper.scss";

// img
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";

class EmployeeFacility extends Component {
    render() {
        let { posts } = this.props

        let postData = posts ? posts.find(item => item.data.slug === 'facilities') : null

        let postImages = postData ? postData.images ? postData.images : [] : []

        let len = postImages.length

        const params = {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 1500,
            autoplay: {
                delay: 5500,
                disableOnInteraction: true,
            },
            breakpoints:{
                769: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        };

        return (
            <StyledNewsEvents className="pt-110 pb-110">
                <Container>
                    <SubTitle
                        color={'#fff'}
                        fontSize={56}
                        margin={'0 0 30px 0'}
                        text={'employee facilities'}
                    />
                    <SubTitle
                        color={'#fff'}
                        fontSize={16}
                        margin={'0 0 30px 0'}
                        text={'All our latest facilities'}
                        textTransform={'capitalize'}
                        fontWeight={300}
                    />
                </Container>

                <Container className="news-events-slider">
                    {len && len === postImages.length ? (
                        <Swiper  {...params}>
                            {postImages.map(data => (
                                <div key={data.id} className="news-events-slider__single">
                                    <div className="news-events-slider__single__img">
                                        <img src={baseUrl + data.path + data.img_name} alt=""/>
                                    </div>
                                    <div className="news-events-slider__single__content">
                                        <Title
                                            text={data.short_desc}
                                            fontSize={'20'}
                                            color='#ffffff'
                                            fontWeight={200}
                                            lineHeight={'32'}
                                            margin={'20px 0 20px 0'}
                                        />
                                    </div>
                                </div>
                            ))}

                        </Swiper>
                    ) : null}
                </Container>
            </StyledNewsEvents>
        )
    }
};


const StyledNewsEvents = styled.div`
   background-color: #2E3744 ;
   position: relative;
   .news-events-slider{
       &__single{
       
           &__content{
               h4{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                line-height: 21px;
                margin: 0 0 20px 0;
                color: rgba(9,32,59,0.50);
              }
              p{
                font-size: 20px;
                font-weight: bold;
                line-height: 30px;
                margin-bottom: 40px;
              }
              h2{
                width: 80%;
              } 
               .line {
                   margin-top: 4px;
                   border-top: 1px solid rgba(255,255,255,0.50);
               }
           }
           
            &__img{
                padding-top: 62.50%;
                position: relative;
                overflow: hidden;
                margin-top: 40px;
                z-index: 3;
                a{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  z-index: 3;
                }
                img{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  transform: scale(1.01);
                  top: 0;
                  left: 0;
                  transition:all 2s ease;
                  &:nth-of-type(1) {
                    width: 110%;
                  }
                }
                &:hover{
                  img{
                    transform: scale(1.05);
                    filter:contrast(140%);
                  }
                }
           }
       }

     &__bottom {

       margin-top: -50px;
     }
   }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    margin-left: -50px;
    margin-right: -50px;
    padding-right: 50px;
    padding-left: 50px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #2E3744;
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #2E3744;
      right: 0;
      top: 0;
      z-index: 2;
    }

  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }


  @media(max-width: 1024px){
    .swiper-button-next {
      position: absolute;
      right: 12px;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: 0;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }
  
  @media (max-width: 767px) {
    .sub-title:nth-of-type(1) {
      margin-bottom: 58px;
    }

    .title {
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }
  }
`;

export default EmployeeFacility;

