import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Lines from '../../../components/VerticalLine';
import {Link} from "react-router-dom";

import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../../../redux/actions/home";

class AboutUs extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-about'
        };
        this.props.getHomeAboutUsData(params)
    }

    render() {
        let {aboutData} = this.props;
        const aboutPageData = aboutData ? aboutData.page_data : null;
        const aboutPost = aboutData ? aboutData.posts ? aboutData.posts.list : [] : [];
        return (
            <StyledAbout className="pt-110 pb-110" id="godown">
                <Lines/>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <SubTitle margin={'0 0 40px 0'} text={'About us'}/>
                        </Col>

                        <Col sm={6} className="about__left">
                            {/*<Text text={aboutPageData.description} />*/}
                            {aboutPageData ?
                                <>
                                    <Title fontSize={'48'} lineHeight={'58'} margin={'0 0 40px 0'} fontWeight={'500'}
                                           text={aboutPageData.short_desc}/>
                                    <Text text={<div dangerouslySetInnerHTML={{__html: aboutPageData.description}}/>}/>
                                </> : ''
                            }
                            <Link to='/about' style={{textDecoration: 'none'}}>
                                <Button margin={'40px 0 0 0'} text={'explore'}/>
                            </Link>
                        </Col>

                        <Col sm={{span: 5, offset: 1}} className="about__right">

                            {aboutPost ? aboutPost.map((data) => (
                                <div key={data.data.id} className="about__right__single">
                                    <h4><CountUp start={0} end={parseInt(data.data.title)}>
                                        {({countUpRef, start}) => (
                                            <VisibilitySensor onChange={start} partialVisibility={{top: 0, bottom: 20}}
                                                              delayedCall>
                                                <span ref={countUpRef}></span>
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>+</h4>
                                    <div dangerouslySetInnerHTML={{__html: data.data.description}}/>
                                </div>
                            )) : null}
                        </Col>

                    </Row>
                </Container>
            </StyledAbout>
        )
    }
}


const StyledAbout = styled.div`
    background-color: rgba(30,195,170,0.05);
    position: relative;
    .container{
      position: relative;
    }

    
    .about__left{
         @media(max-width:767px){
            min-width: 100%;
            .title{
              margin-bottom: 17px !important;
            }
         }
    }
    .about__right{
      &__single{
      margin-bottom: 8px;
        h4{
          font-size: 110px;
          line-height: 147px;
          font-weight: 300;
          margin: 0;
        }
        p{
           font-size: 16px;
           line-height: 24px;
           font-weight: 300;
           margin: 0;
           text-transform: uppercase;
           span{
            font-weight: bold;
           }
        }
        &:nth-of-type(2){
          text-align: right;
        }
      }
      @media(max-width:767px){
         min-width: 100%;
         margin-top: 60px;
         margin-left: 0;
         .about__right__single{
         text-align: left;
         margin-bottom: 40px;
         &:nth-last-of-type(1){
          margin-bottom: 0;
         }
           h4{
              font-size: 50px;
              line-height: 80px;
           }
            p{
              font-weight: 400;
              br{
                display: none;
              }
            }
         }
       
      }
    }
`;

function mapStateToProps(state) {
    return {
        aboutData: state.homeReducer.aboutData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeAboutUsData: (params) => dispatch(fetchHomeAboutUsData(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);




