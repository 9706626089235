import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from '../../../components/Title';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import Button from "../../../components/Button";
import {hover,darkGreen} from "../../../globalStyleVars";
import Select from 'react-select';
import {postFormSubmit} from "../../../redux/actions/form";
import {connect} from 'react-redux';
import Message from '../../../components/PopupMessage';

const options = [
    { value: 'Washing', label: 'Washing' },
    { value: 'Knitting', label: 'Knitting' },
    { value: 'Printing', label: 'Printing' }
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor:state.isSelected ? '#1EC3AA' : '#FFF',
        margin:0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor:state.isSelected ? '#000' : '#FFF',
        margin:0
    }),
    menuList:(provided, state) => ({
        ...provided,
        backgroundColor:state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};


const BannerDescription = (props) => {
    let { formFields, submitForm, formSubmitErr } = props;
    let [state, setState] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    })

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    }

    let handleInit = () => {
        setState({
            name: '',
            phone: '',
            email: '',
            message: ''
        })
    }

    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'contact-form'
        }
        let len = formFields.length

        for(let i = 0; i < len; i++) {
            formField[formFields[i]["field_key"]] = ""
        }

        formField["name"] = state.name
        formField["phone"] = state.phone
        formField["email"] = state.email
        formField["message"] = state.message

        submitForm(formField)
        handleInit()
    };


    return (
        <StyledContactForm>
            <Message
                title={formSubmitErr ? 'Error' : 'Form Submitted'}
            />
            <div className="contact-form">
                <SubTitle
                  text={'Get in touched'}
                  margin={'0 0 90px 0'}
                  fontSize={42}
                  textAlign={'left'}
                  color={'#ffffff'}
                />
                <div className="contact-form__form">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder="Enter your name here"
                                      name="name"
                                      value={state.name}
                                      onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control
                                      type='email'
                                      placeholder="Enter your name here"
                                      name="email"
                                      value={state.email}
                                      onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder="Enter your contact number here"
                                      name="phone"
                                      value={state.phone}
                                      onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      placeholder='Enter your message here'
                                      name="message"
                                      value={state.message}
                                      onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Button
                                      text={'submit'}
                                      onSubmit={handleSubmit}
                                      fontSize={'16'}
                                      color={'#ffffff'}
                                      letterSpacing={'0'}
                                      width={'100%'}
                                      margin={'20px 0 0 0'}
                                    />
                                </Form.Group>

                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </StyledContactForm>
    )
};

const StyledContactForm = styled.div`
  background-color:#2E3744;
    padding: 25px 0px; 
   a{
      color: ${hover};
      &:hover{
        color: ${darkGreen};
      }
        
     }
  .contact-form{
     background-color: #2E3744;
     padding: 50px 100px;
    &__form{
      form{
        label{
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 10px;
            color: white;
        }
        .form-group{
          margin-bottom: 30px;
          &:nth-last-of-type(1){
            margin-bottom: 0px;
          }
          &:hover{
            cursor: pointer;
          }
        }
        .form-control{
            background-color: #2E3744;
          height: 50px;
          line-height: 50px;
          border-radius: 0;
          border-color:#D9D9D9;
          padding-left: 20px;
          &:focus{
            border-color:${hover};
              color: white;
          }
           &::placeholder{
            font-size: 16px;
            font-weight: 300;
               color: white;
            line-height: 21px;
          }
        }
        textarea{
          min-height: 165px;
          max-height: 150px;
          max-width: 100%;
          min-width: 100%;
          line-height: unset !important;
          padding-top: 14px;
          padding-left: 20px;
        }
        
        .input-file{
          position: relative;
          height: 50px;
          //opacity: 0;
          &:after{
           content: 'Upload CV/Drag your CV';
            position: absolute;
            height: 50px;
            width: 100%;
            top: 0;
            background: #FFF;
            border: 1px solid #D9D9D9;
            z-index: -1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0,0,0,0.5);
          }
        }
        .form-control-file{
          cursor: pointer;
          opacity: 0;
        }
        .dc-btn{
          margin-top: 64px;
          height: 50px;
          
           a{
            background-color: transparent;
            text-transform: uppercase;
            &:after{
            background-color: ${hover};
           }
           }
          img{
            display: none;
          }
        }
     }
    }
  }
  
  @media(max-width:1000px){
   .contact-form{
    padding: 100px 30px 100px 30px;
   }
   
  }
  
  @media(max-width:767px){
    //background-color:#FFF;
    padding-bottom: 0 !important;
    .container{
        padding-left: 0;
        padding-right: 0;
        .col-md-4{
          padding-left: 30px;
          padding-right: 30px;
        }
    }
    .contact-form{
      padding: 60px 15px;
      margin-top: 0;
       .form-group{
          margin-bottom: 20px !important;
         &:nth-last-of-type(1){
           margin-bottom: 20px !important;
         }
        }
    }

    .contact-form__form form .dc-btn {
      margin-top: 32px;
    }
    
  }
  
`;

function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        formSubmitErr: state.formReducer.formSubmitErr,
        formSubmitLoading: state.formReducer.formSubmitLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        submitForm: body => dispatch(postFormSubmit(body))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerDescription);
