import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import SubText from '../../../components/SubText';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';

// img
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import aw1 from "../../../assets/images/dynamic/blur.jpg";
import bg from "../../../assets/images/achievements.jpg";
import {baseUrl} from "../../../components/BaseUrl";

const QuantamGallery = ({ posts }) => {
    let sliderPost = posts ? posts.find(post => post.data.slug === 'slider') : null
    let sliderImage = sliderPost ? sliderPost.images ? sliderPost.images : [] : []

    let len = sliderImage.length

    console.log('Slider', sliderPost)

    const params = {
        spaceBetween: 20,
        slidesPerView: 1,
        speed: 1500,
        // centeredSlides: true,
        // roundLengths: true,
        // loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        // autoplay: {
        //     delay: 5500,
        //     disableOnInteraction: true,
        // },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
                centeredSlides: false,
                loop: false
            },
            769: {
                slidesPerView: 4,
                spaceBetween: 30,
                centeredSlides: false,
                loop: false
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    };

    let imagePath = 'https://res.cloudinary.com/app-alo-social/image/upload/v1617782608/the_leading_steel_manufacturer_2x_ef5yjp.jpg'

    return (
        <StyledQuantamGallery className="pt-110 pb-110">
            <Container className="achievement-slider">
                <SubTitle
                  text={'কন্সট্রাক্সন ষ্টীল তৈরিতে জী পি এইচ স্টিল কোয়ান্টাম প্রযুক্তি কেন এখন ও বিশ্ব সেরা'}
                  fontSize={48}
                  lineHeight={54}
                  margin={'0px 0 64px 0'}
                  color={'#fff'}
                />

                {len && len === sliderImage.length ? (
                    <Swiper  {...params}>
                        {sliderImage.map(image => {
                            let imagePath = baseUrl + image.path + image.img_name
                            return (
                                <div key={image.id} className="achievement-slider__single">
                                    <div className="achievement-slider__single__img">
                                        <img src={imagePath} alt=""/>
                                    </div>
                                </div>
                            )
                        })}
                    </Swiper>
                ) : ''}

                {/*<Swiper  {...params}>*/}
                {/*    <div className="achievement-slider__single">*/}
                {/*        <div className="achievement-slider__single__img">*/}
                {/*            <img src={imagePath} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="achievement-slider__single">*/}
                {/*        <div className="achievement-slider__single__img">*/}
                {/*            <img src={imagePath} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="achievement-slider__single">*/}
                {/*        <div className="achievement-slider__single__img">*/}
                {/*            <img src={imagePath} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="achievement-slider__single">*/}
                {/*        <div className="achievement-slider__single__img">*/}
                {/*            <img src={imagePath} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="achievement-slider__single">*/}
                {/*        <div className="achievement-slider__single__img">*/}
                {/*            <img src={imagePath} alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Swiper>*/}
            </Container>
        </StyledQuantamGallery>

    )
};


const StyledQuantamGallery = styled.div`
  background-color: #2E3744;

  .achievement-slider {
    .text-name{
      background-color: #00000090;
      position: absolute;
      left: 48px;
      bottom: 32px;
      text-align: left;
      width: calc(100% - 48px);
    }
    &__single {
      h4 {
        margin-top: 8px;
        margin-left: 16px;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        color: #fff;
        margin-bottom: 0;
      }

      p {
        font-size: 12px;
        margin-left: 16px;
        font-weight: 300;
        line-height: 21px;
        color: #FFFFFF;
        margin-bottom: 8px;
      }

      &__img {
        padding-top: 116.11%;
        position: relative;
        overflow: hidden;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .swiper-container {
    margin-left: -50px;
    margin-right: -50px;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #2E3744;
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #2E3744;
      right: 0;
      top: 0;
      z-index: 2;
    }

  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }

  @media (min-width: 768px) {
    .swiper-pagination-bullet {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .achievement-slider__single__img{
      padding-top: 90%;
    }
    .swiper-wrapper .sub-title {
      margin-bottom: 10px;
    }

    .borders .sub-title {
      margin: 16px 0 10px 0 !important;
    }

    .title-here .sub-title {
      margin-bottom: 60px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }

    .swiper-wrapper {
      margin-bottom: 50px;
    }

    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
  }

  .swiper-pagination {
    bottom: 0px;

    .swiper-pagination-bullet {
      height: 5px;
      width: 5px;
      background-color: #7B7B7B;
      position: relative;
      margin-right: 20px;
      opacity: 1;

      &:after {
        content: '';
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background-color: #E2E2E2;
        position: absolute;
        left: -6px;
        top: -6px;
        opacity: 0;
        z-index: -1;
      }

      &.swiper-pagination-bullet-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;


export default QuantamGallery;
