import React, { Component } from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from '../../components/Menu';
import {ApiParams} from "../../constants/apiParams";
import {fetchQuantumPage} from "../../redux/actions/quantum";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import PerformanceText from "./components/performanceText";
import QuantumTable from "./components/quantumTables";
import GPSQuantam from "../productSpecification/components/GPSQuantam";
import ClientSlider from "../home/components/ProductsSlider";
import QuantamGallery from "./components/QuantamGallery";
import QuantumElectric from "./components/qurantamElectric";
import GridSection from "./components/GridSection";

class Quantum extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'quantum-home'
        }
        this.props.getQuantumPage(mainParam);

        if(this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId)
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }

    render() {
        let { quantumMain } = this.props

        let bannerImagePath = quantumMain ?
          quantumMain.images ?
            quantumMain.images.list ?
              quantumMain.images.list[0] ?
                quantumMain.images.list[0].path + quantumMain.images.list[0].img_name : null
                    : null
                : null
            : null;

        let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

      let backGround = quantumMain ?
        quantumMain.images ?
          quantumMain.images.list ?
            quantumMain.images.list[1] ?
               baseUrl + quantumMain.images.list[1].path + quantumMain.images.list[1].img_name : ''
            : ''
          : ''
        : '';

      let posts = quantumMain ? quantumMain.posts ? quantumMain.posts.list : [] : []

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
              <Menu />
              <InnerBanner
                subTitle={'Quantam EAF'}
                image={banner ? banner : ''}
              />
              <PerformanceText posts={posts}/>
              <QuantumTable slug={'quantum-table-one'} />
              <GPSQuantam posts={posts} background={backGround}/>
              <QuantumTable slug={'quantum-table-two'} />
              <QuantamGallery posts={posts}/>
              <GridSection posts={posts} />
              <QuantumElectric posts={posts}/>
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        quantumMain: state.quantumReducer.quantumPage,
        sectionId: state.menuReducer.sectionId
    }
}

function mapDispatchToProps(dispatch) {
    return {
      getQuantumPage: params => dispatch(fetchQuantumPage(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quantum);
