export const FORM = {
  FETCH_FORM_DATA: {
    MAIN: 'FETCH_FORM_DATA',
    SUCCESS: 'FETCH_FORM_DATA_SUCCESS',
    FAILURE: 'FETCH_FORM_DATA_FAILURE'
  },
  POST_FORM_DATA: {
    MAIN: 'POST_FORM_DATA',
    SUCCESS: 'POST_FORM_DATA_SUCCESS',
    FAILURE: 'POST_FORM_DATA_FAILURE'
  }
}
