import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubText from '../../../components/SubText';

let HistorySlider = props => {
    let { backgroundColor, color, hoverColor, history } = props

    let len = history.length

    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1500,
      freeMode: false,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: true,

        },
      }
    };

    return (
      <StyledParallelPages className="pt-110 pb-110" backgroundColor={backgroundColor} hoverColor={hoverColor}>
        <Container className="title-here">
          <div className="sub-text-view">
            {len && len === history.length ? (
              <Swiper {...params}>
                {history.map(item => (
                  <SubText
                    text={item.data.title}
                    fontSize={24}
                    color={color}
                    letterSpacing={1}
                    hoverColor={hoverColor}
                    borderRight={'1px solid #adadad'}
                  />
                ))}
              </Swiper>
            ) : null}
          </div>
        </Container>
      </StyledParallelPages>
    )
};


const StyledParallelPages = styled.div`
  .title-here {
    .borders {
      border-top: 1px solid #B9B9B9;
      border-bottom: 1px solid #B9B9B9;
      margin: 10px 0 24px 0;
      text-align: center;
    }
    
    .sub-text-view {
        display: flex;
        justify-content: center;
    }

    .title {
      width: 60%;
    }
  }

  @media (min-width: 768px) {
    
  }

  @media (max-width: 767px) {
    .borders .sub-title {
      margin: 16px 0 10px 0 !important;
    }

    .title-here .sub-title {
      margin-bottom: 0px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }
  }
`;

export default HistorySlider;
