import {HOME} from "../../constants/home";

export function fetchHomeBannerSlider(params) {
  return {
    type: HOME.FETCH_HOME_BANNER_SLIDER.MAIN,
    params
  }
}

export function fetchHomeAboutUsData(params) {
  return {
    type: HOME.FETCH_HOME_ABOUT_US.MAIN,
    params
  }
}

export function fetchHomeBusinessDivisionData(params) {
  return {
    type: HOME.FETCH_HOME_BUSINESS_DIVISIONS.MAIN,
    params
  }
}

export function fetchHomeClientData(params) {
  return {
    type: HOME.FETCH_HOME_CLIENTS.MAIN,
    params
  }
}

export function fetchHomeAchievementData(params) {
  return {
    type: HOME.FETCH_HOME_ACHIEVEMENTS.MAIN,
    params
  }
}

export function fetchHomeCertificationData(params) {
  return {
    type: HOME.FETCH_HOME_CERTIFICATIONS.MAIN,
    params
  }
}

export function fetchHomeNewsData(params) {
  return {
    type: HOME.FETCH_HOME_NEWS_EVENTS.MAIN,
    params
  }
}

export function fetchHomeSustainability(params) {
  return {
    type: HOME.FETCH_HOME_SUSTAINABILITY.MAIN,
    params
  }
}
