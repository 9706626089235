import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {Link} from 'react-router-dom';
import Links from '../../../components/Link';
import arrow from '../../../assets/images/btn-arrow-green.svg';
import Modal from '../../../components/Modal';
import Select from "react-select";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux'
import {fetchCareerListData} from "../../../redux/actions/career";
import moment from 'moment'

const options = [
    {value: 'Washing', label: 'Chocolate'},
    {value: 'Knitting', label: 'Strawberry'},
    {value: 'Printing', label: 'Vanilla'}
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#1EC3AA' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};

class Jobs extends Component {
    state = {
        show: false,
        activePage: 0,
        page: null,
        activePost: null,
        activeTitle: ''
    }
    componentDidMount() {
        let param = {
            [ApiParams.page_id]: this.props.page_id
        }

        this.props.getCareerListData(param)
    }

    handlePageChange = (active, page) => {
        this.setState({ activePage: active, page: page })

    }

    handleActivePost = (post) => {
        this.setState({
            show: true,
            activePost: post
        })
    }

    handleModal = (value, post, title) => {
        this.setState({ show: value, activePost: post, activeTitle: title})
    }

    render() {
        let { careerPageList } = this.props

        let careerPosts = this.state.page ?
          this.state.page.posts ?
            this.state.page.posts.list : []
          : careerPageList[0] ?  careerPageList[0].posts.list : []


        let mobileOption = careerPageList ? careerPageList.map(page => {
            let data = {value: page, label: page.page_data.title}

            return data
        }) : []

        return (
          <div>
              <Modal
                show={this.state.show}
                handleClose={() => this.handleModal(false, null)}
                post={this.state.activePost}
                title={this.state.activeTitle}

              />
              <StyledJobFilter className="job-filter">
                  <Container>
                      <Row>
                          <Col xs={12}>
                              <ul className="job__menu">
                                  {careerPageList.length ? careerPageList.map((page, key) => {
                                      let pageData = page.page_data
                                      return (
                                        <li
                                          key={pageData.id}
                                          className={key === this.state.activePage ? "active" : ''}
                                          onClick={() => this.handlePageChange(key, page)}
                                        >
                                            <a>{pageData.title}</a>
                                        </li>
                                      )
                                  }) : null}
                              </ul>
                          </Col>
                          <Col sm={12} className='mobile-filter'>
                              <Select
                                styles={customStyles}
                                className='select-here'
                                placeholder='Select Division'
                                options={mobileOption}
                                onChange={op => this.handlePageChange(0, op.value)}
                              />
                          </Col>
                      </Row>
                  </Container>
              </StyledJobFilter>

              <StyledJobItems className="pt-110 pb-110">
                  <Container>
                      <Row>
                          <Col md={12}>
                              <SubTitle color={'#58585A'} margin={'0 0 30px 0'} text={'LET’S GROW TOGETHER'}/>
                              <Title
                                fontSize={'30'}
                                lineHeight={'45'}
                                fontWeight={'500'}
                                margin={'0 0 40px 0'}
                                text={'We are currently looking'}
                              />
                              <div className="job__list">
                                  {careerPosts.length ? careerPosts.map(post => {
                                      return (
                                        <div key={post.data.id} className="job__list__single">
                                            <div className="job__list__single__date">
                                                <p>{moment(post.data.Date).format('MMMM')}</p>
                                                <h4>{moment(post.data.Date).format('DD')}</h4>
                                                <p>Deadline</p>
                                            </div>

                                            <div className="job__list__single__desc">
                                                <h4>{post.data.title}</h4>
                                                <ul>
                                                    <li>
                                                        <span>Employment Status:</span> <span>{post.data["Extra description one"]}</span>
                                                    </li>
                                                    <li>
                                                        <span>Exp. Requirements:</span> <span>{post.data["Extra description two"]}</span>
                                                    </li>
                                                    <li>
                                                        <span>Salary:</span> <span>{post.data["Extra description three"]}</span>
                                                    </li>
                                                </ul>

                                                <div
                                                  className="popup-on"
                                                  onClick={() => this.handleModal(true, post.data.description, post.data.title)}
                                                >
                                                    <p>View Details <img src={arrow} alt=""/></p>
                                                </div>

                                            </div>
                                        </div>
                                      )
                                  }) : ''}
                              </div>
                          </Col>
                      </Row>

                  </Container>

              </StyledJobItems>

          </div>
        )
    }
};


// filter
const StyledJobFilter = styled.div`
  background-color: #F3FCFB;
  padding:68px 0;
  .container{
    overflow: visible;
  }
  .job__menu{
    display: flex;
    justify-content: space-between;
  li{

    a{
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      color: rgba(0,0,0,0.15);
      display: block;
      text-transform: capitalize;
      transition:color .3s ease;
      &:hover{
        color: #000;
      }
    }
     &.active a{
      color: #000;
    }
  }
 }


  @media(max-width:767px){
    padding: 10px 0 25px 0;
  .job__menu{
    display: none;
  }
    &.division-filter{
    .sub-title{
      margin-bottom: 10px;
    }
      .container{
        overflow: visible !important;
      }
    }
  }
  
  .css-g1d714-ValueContainer{
    padding-left: 0;
  }
  .css-yk16xz-control,.css-1pahdxg-control{
    padding-left: 0;
     border:none;
    border-bottom:1px solid #D9D9D9 !important;
    background-color: transparent !important;
  }
  .css-tlfecz-indicatorContainer{
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
   .css-1uccc91-singleValue{
    font-size: 20px;
    font-weight: bold;
   }
    
    @media(min-width:768px){
      .mobile-filter{
        display: none;  
      }
    }
   

`;

//items
const StyledJobItems = styled.div`
  background-color: #FAFAFA;
  .job__list{
    &__single{
      background-color: #fff;
      padding: 35px 40px 35px 70px;
      box-shadow: 0 0 10px rgba(0,0,0,0.06);
      display: inline-flex;
      width: 100%;
      margin-bottom: 20px;
      align-items: center;
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
      &__date{
      padding-right: 45px;
     
        p{
          font-size: 14px;
          font-weight: 300;
          height: 21px;
          color: #58585A;
          margin: 0;
        }
        h4{
          font-size: 48px;
          color: ${hover};
          margin: 0;
          line-height: 58px;
          font-weight: bold;
        }
        
        
      }
      &__desc{
        padding-left: 30px;
        width: 100%;
         border-left:1px solid #EBEBEB;
        h4{
          font-size: 30px;
          line-height: 34px;
          margin: 0 0 20px 0;
          font-weight: 500;
        }
        li{
          font-size: 14px;
          line-height: 21px;
          font-weight: 300;
          color: #58585a;
          margin-bottom: 5px;
          width: 100%;
          display: flex;
          span{
            display: inline-flex;
            &:nth-of-type(1){
             flex:1;
             
            }
            &:nth-of-type(2){
             flex:3;
            
            }
          }
        }
        a{
           margin-top: 10px;
         }
      }
    }
  } 
  
   
 .popup-on{
  p{
    margin: 20px 0 0 0 ;
    color: #1EC3AA;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 21px;
    cursor: pointer;
    transition: letter-spacing .3s ease;
    img{
      margin-left: 10px;
    }
    &:hover{
      letter-spacing: 2px;
    }
  }
 }
  
  
  @media(max-width:992px){
    .job__list__single__desc li{
       justify-content: space-between;
       span{
         flex: 1 !important;
       }
    }
  }
  @media(max-width:767px){
    padding-top: 80px !important;
    .job__list__single{
      padding: 30px 20px;
      .job__list__single__desc{
      padding-left: 15px;
       h4{
          font-size: 20px;
        }
        li{
          font-size: 14px;
          font-weight: 400;
          span{
            &:nth-of-type(1){
             flex: 2;
            }
            &:nth-of-type(2){
              flex: 1;
              padding-left: 20px;
            }
          }
        }
      }
    }
    .job__list__single__date{
      padding-right: 20px;
      h4{
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
  @media (max-width: 410px){
    .job__list__single .job__list__single__desc li {
        font-size: 11px;
        line-height: 18px;
    } 
   } 
   
`;

function mapStateToProps(state) {
    return {
        careerPageList: state.careerReducer.careerPageList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerListData: params => dispatch(fetchCareerListData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
