import React, {Component} from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {fetchHomeNewsData} from "../../../redux/actions/home";
import {Link} from 'react-router-dom'
// css
import "swiper/swiper.scss";

// img
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import {ApiParams} from "../../../constants/apiParams";

class NewsEvents extends Component {
    componentDidMount() {
        let params = {
           [ApiParams.page_type]: 'home-news-and-events'
        }
        this.props.getHomeNewsData(params)
    }

    render() {
        let { newsEvents } = this.props

        const newsEventData = newsEvents ? newsEvents.posts ? newsEvents.posts.list : [] : [];

        let len = newsEventData.length

        const params = {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 1500,
            autoplay: {
                delay: 5500,
                disableOnInteraction: true,
            },
            breakpoints:{
                769: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        };

        let type = newsEvents ? newsEvents.page_data ? newsEvents.page_data.slug : '' : ''

        return (
            <StyledNewsEvents className="pt-110 pb-110">
                {/*<Lines/>*/}
                <Container>
                    <SubTitle
                      color={'#fff'}
                      fontSize={56}
                      margin={'0 0 30px 0'}
                      text={'Latest News'}
                    />
                    <SubTitle
                      color={'#fff'}
                      fontWeight={200}
                      textTransform={'capitalize'}
                      fontSize={16}
                      margin={'0 0 30px 0'}
                      text={'Ongoing affairs and press releases'}
                    />
                </Container>

                <Container className="news-events-slider">
                    {len && len === newsEventData.length ? (
                      <Swiper  {...params}>
                          {newsEventData.map(data => {
                              console.log(data)
                              return (
                                  <div key={data.data.id} className="news-events-slider__single">
                                      <div className="news-events-slider__single__img">
                                          <Link to={`/media/details/${type}/${data.data.slug}`}></Link>
                                          {data.images.map(img => (
                                              <img key={img.id} src={baseUrl + img.path + img.img_name} alt=""/>
                                          ))}

                                      </div>
                                      <div className="news-events-slider__single__content">
                                          {/*{data.data.type === '10' ?*/}
                                          {/*  <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>*/}
                                          {/*      {({isVisible}) =>*/}
                                          {/*        <h4 className={`${isVisible ? 'anim-active' : ''} fade-up`}>News</h4>*/}
                                          {/*      }*/}
                                          {/*  </VisibilitySensor>*/}
                                          {/*  :*/}
                                          {/*  <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>*/}
                                          {/*      {({isVisible}) =>*/}
                                          {/*        <h4 className={`${isVisible ? 'anim-active' : ''} fade-up`}>events</h4>*/}
                                          {/*      }*/}
                                          {/*  </VisibilitySensor> post.data["Extra Description one"] */}
                                          {/*}*/}
                                          <Title
                                              text={data.data.title}
                                              fontSize={24}
                                              color='#ffffff'
                                              fontWeight={200}
                                              lineHeight={'32'}
                                              margin={'20px 0 20px 0'}
                                          />
                                          <Title
                                              text={data.data["Extra description one"]}
                                              //text={'Hackathon on'}
                                              fontSize={20}
                                              color='#ffffff'
                                              fontWeight={'500'}
                                              textTransform={'uppercase'}
                                              lineHeight={'30'}
                                              letterSpacing={1}
                                              margin={'0 0 10px 0'}
                                          />
                                          <Title
                                              text={data.data["Extra description two"]}
                                              fontSize={14}
                                              color='#ffffff'
                                              fontWeight={100}
                                              lineHeight={'21'}
                                              margin={'0 0 20px 0'}
                                          />
                                          <div className="line" />
                                          <Title
                                              text={data.data["Extra description three"]}
                                              fontSize={20}
                                              color='#ffffff'
                                              fontWeight={'500'}
                                              lineHeight={'30'}
                                              textTransform={'uppercase'}
                                              letterSpacing={1}
                                              margin={'10px 0 10px 0'}
                                          />
                                          <Title
                                              text={data.data["Extra description four"]}
                                              fontSize={14}
                                              color='#ffffff'
                                              fontWeight={100}
                                              lineHeight={'21'}
                                              margin={'0 0 20px 0'}
                                          />
                                          <div className="line" />
                                          {/*<Links*/}
                                          {/*  fontWeight={'500'}*/}
                                          {/*  textTransform={'initial'}*/}
                                          {/*  fontSize={'12'}*/}
                                          {/*  color={'rgba(9,32,59,0.5)'} text={'Read more'}*/}
                                          {/*  img={arrowImg}*/}
                                          {/*  link={`/media/details/${type}/${data.data.slug}`}*/}
                                          {/*/>*/}
                                      </div>
                                  </div>
                              )
                          })}

                      </Swiper>
                    ) : null}
                    {/*<div className="NewsEvents-slider__bottom">*/}
                    {/*    <Button margin={'-50px 0 0 0'} link={'/media'} text={'explore'}/>*/}
                    {/*</div>*/}
                </Container>
            </StyledNewsEvents>
        )
    }
};


const StyledNewsEvents = styled.div`
   background-color: #636E7F ;
   position: relative;
   .news-events-slider{
       &__single{
       
           &__content{
               h4{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                line-height: 21px;
                margin: 0 0 20px 0;
                color: rgba(9,32,59,0.50);
              }
              p{
                font-size: 20px;
                font-weight: bold;
                line-height: 30px;
                margin-bottom: 40px;
              }
              h2{
                width: 80%;
              } 
               .line {
                   margin-top: 4px;
                   border-top: 1px solid rgba(255,255,255,0.50);
               }
           }
           
            &__img{
                padding-top: 67.57%;
                position: relative;
                overflow: hidden;
                margin-top: 40px;
                z-index: 3;
                a{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  z-index: 3;
                }
                img{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  transform: scale(1.01);
                  top: 0;
                  left: 0;
                  transition:all 2s ease;
                  
                }
                &:hover{
                  img{
                    transform: scale(1.05);
                    filter:contrast(140%);
                  }
                }
           }
       }

     &__bottom {

       margin-top: -50px;
     }
   }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    margin-left: -50px;
    margin-right: -50px;
    padding-right: 50px;
    padding-left: 50px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #636E7F;
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #636E7F;
      right: 0;
      top: 0;
      z-index: 2;
    }

  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }


  @media(max-width: 1024px){
    .swiper-button-next {
      position: absolute;
      right: 12px;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: 0;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }
  
  @media (max-width: 767px) {
    .sub-title:nth-of-type(1) {
      margin-bottom: 58px;
    }

    .title {
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }
  }
`;

function mapStateToProps(state) {
    return {
        newsEvents: state.homeReducer.newsEvents
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeNewsData: (params) => dispatch(fetchHomeNewsData(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsEvents);

