import React from 'react';
import {PageAnimation} from "../../../components/PageAnimation";
import {motion} from "framer-motion";
import styled from "styled-components";
import {Table} from 'react-bootstrap';
import SubTitle from "../../../components/SubTitle";
import ReactHtmlParser from "react-html-parser";
import Button from "../../../components/Button";
import Text from "../../../components/Text";

let ChemicalComposition = props => {
    let { posts } = props;

    let infoTable = posts ? posts.find(item => item.data.slug === 'corporate-information') : null

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <StyledDivision>
                <div className="division-content__wrap" >
                    <div className="division-content__wrap__at-a-glance">

                        <div className="table-three">
                            <Table responsive hover variant="dark">
                                <thead>
                                <tr>
                                    <th>Name of Elements</th>
                                    <th colSpan="3">Grade-wise percent constituents</th>
                                </tr>
                                <tr>
                                    <th>.</th>
                                    <th>GPH TMT 500W</th>
                                    <th>GPH G60-400</th>
                                    <th>GPH G40-300W</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Carbon(C)</td>
                                    <td>0.15-0.25</td>
                                    <td>0.20-0.33</td>
                                    <td>0.15-0.25</td>
                                </tr>
                                <tr>
                                    <td>Manganese (Mn)</td>
                                    <td>0.75-0.90</td>
                                    <td>1.00-1.65</td>
                                    <td>0.75-0.90</td>
                                </tr>
                                <tr>
                                    <td>Silicon (Si)</td>
                                    <td>0.25 Max</td>
                                    <td>0.25 Max</td>
                                    <td>0.25 Max</td>
                                </tr>
                                <tr>
                                    <td>Sulfur(S)</td>
                                    <td>0.05Max</td>
                                    <td>0.05 Max</td>
                                    <td>0.05 Max</td>
                                </tr>
                                <tr>
                                    <td>Phosphorus (P)</td>
                                    <td>0.04 Max</td>
                                    <td>0.05 Max</td>
                                    <td>0.04 Max</td>
                                </tr>
                                <tr>
                                    <td>Copper(Cu)</td>
                                    <td>0.20 Max</td>
                                    <td>0.25 Max</td>
                                    <td>0.20 Max</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className="table-three">
                            <Table responsive hover variant="dark">
                                <thead>
                                <tr>
                                    <th>AISI-SAE Number</th>
                                    <th>UNS Number</th>
                                    <th colSpan="4">Composition</th>
                                </tr>
                                <tr>
                                    <th style={{backgroundColor: '#262E39'}} colSpan="2"></th>
                                    <th>C</th>
                                    <th>Mn</th>
                                    <th>P(max)**</th>
                                    <th>S(max)**</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className="table-three">
                            <Table responsive hover variant="dark">
                                <thead>
                                <tr>
                                    <th>AISI-SAE Number</th>
                                    <th>UNS Number</th>
                                    <th colSpan="4">Composition</th>
                                </tr>
                                <tr>
                                    <th style={{backgroundColor: '#262E39'}} colSpan="2"></th>
                                    <th>C</th>
                                    <th>Mn</th>
                                    <th>P(max)**</th>
                                    <th>S(max)**</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                <tr>
                                    <td>1522</td>
                                    <td>G15220</td>
                                    <td>0.18–0.24</td>
                                    <td>1.10–1.40</td>
                                    <td>0.040</td>
                                    <td>0.050</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </StyledDivision>
        </motion.div>
    )
};

const StyledDivision = styled.div`
  background-color: #262E39;
  padding: 10%;
  .division-content__wrap{
    position: relative;
    z-index: 2;
    .button-explore {
      position: absolute;
      right: 100px;
      padding-top: 25px;
    }
    &__at-a-glance{
      position: relative;
      .table-dark {
        background-color: #262E39;
      }
      .table-two {
        table{
          thead {
            border-color: #00000000;
            tr{
              background-color: #00000060 !important;
              th{
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1px solid #00000000 !important;
                border-right: 1rem solid #262E39;
                &:nth-last-of-type(1) {
                  border-right: 0px solid #262E39;
                }
              }
            }
          }
          tr{
            border-color:#FAFAFA;
            &:nth-of-type(even) {
              background-color: #2B333D;
            }
            &:nth-of-type(odd) {
              background-color: #262E39;
            }
            &:nth-last-of-type(1){
              border-bottom: 1px solid #7d7d7d;
            }
            td{
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1px solid #00000000 !important;
              border-right: 1rem solid #262E39;
              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }
            }
          }
        }
      }
      
      .table-three {
        table{
          thead {
            border-color: #00000000;
            tr{
              background-color: #00000060 !important;
              th{
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #262E39 !important;
                border-right: 1rem solid #262E39;
                &:nth-last-of-type(1) {
                  border-right: 0px solid #262E39;
                }
              }
            }
          }
          tr{
            border-color:#FAFAFA;
            &:nth-of-type(odd) {
              background-color: #2B333D;
            }
            &:nth-of-type(even) {
              background-color: #262E39;
            }
            &:nth-last-of-type(1){
              border-bottom: 1px solid #7d7d7d;
            }
            td{
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #262E39 !important;
              border-right: 1rem solid #262E39;
              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }
            }
          }
        }
      }

      .table-four {
        width: 50%;
        table{
          thead {
            border-color: #00000000;
            tr{
              background-color: #00000060 !important;
              th{
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #262E39 !important;
                border-right: 1rem solid #262E39;
                &:nth-last-of-type(1) {
                  border-right: 0px solid #262E39;
                }
              }
            }
          }
          tr{
            border-color:#FAFAFA;
            &:nth-of-type(odd) {
              background-color: #2B333D;
            }
            &:nth-of-type(even) {
              background-color: #262E39;
            }
            &:nth-last-of-type(1){
              border-bottom: 1px solid #7d7d7d;
            }
            td{
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #262E39 !important;
              border-right: 1rem solid #262E39;
              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 991px) {
    .division-content__wrap {
      margin-bottom: 70px;
    }
  }
  
  @media(max-width: 480px) {
    padding: 10%;
  }
  @media(max-width: 365px) {
    padding: 6%;
  }
`

export default ChemicalComposition;
