import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {DIVISION} from "../../constants/division";

function* fetchDivisionPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: DIVISION.FETCH_DIVISION_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: DIVISION.FETCH_DIVISION_PAGE.FAILURE
    })
  }
}

function* fetchDivisionListData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: DIVISION.FETCH_DIVISION_LIST.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: DIVISION.FETCH_DIVISION_LIST.FAILURE
    })
  }
}

function* fetchDivisionListChildData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: DIVISION.FETCH_DIVISION_LIST_CHILD.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: DIVISION.FETCH_DIVISION_LIST_CHILD.FAILURE
    })
  }
}

function* fetchDivisionListChildPage(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    )

    yield put({
      type: DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.FAILURE
    })
  }
}

export default function* divisionSagas() {
  yield takeLatest(DIVISION.FETCH_DIVISION_PAGE.MAIN, fetchDivisionPageData)
  yield takeLatest(DIVISION.FETCH_DIVISION_LIST.MAIN, fetchDivisionListData)
  yield takeLatest(DIVISION.FETCH_DIVISION_LIST_CHILD.MAIN, fetchDivisionListChildData)
  yield takeLatest(DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.MAIN, fetchDivisionListChildPage)
}
