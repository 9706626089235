import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import Text from './Text';
import {connect} from 'react-redux';
import $ from 'jquery';
import MissionImage from '../localization/images/mission.png'
import VisionImage from '../localization/images/vision.png'

import {baseUrl} from "./BaseUrl";
import ReactHtmlParser from "react-html-parser";

const AllValues = props => {
    let { posts, image } = props

    let postData = posts ? posts.find(post => post.data.slug === 'values' || post.data.slug === 'gps-quantum') : null
    let postMain = postData ? postData.data ? postData.data : null : null

    let postImage = postData ?
      postData.images ?
        postData.images[0] ?
           baseUrl + postData.images[0].path + postData.images[0].img_name : ''
        : ''
      : ''

    useEffect(() => {
        let getOffset = $('.container').offset().left + 30;
        if ($(window).width() > 769) {
            $('.services-single:nth-of-type(even) .services-single__right').css({'padding-left': getOffset})
            $('.services-single:nth-of-type(odd) .services-single__right').css({'padding-right': getOffset})
        }
    },[])


    return (
        <StyledServices>
            <Container fluid className='p-0'>
                <Row className="services-single">
                    <Col md={6}>
                        <div className="services-single__left">
                            <img src={postImage} alt=""/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="services-single__right">
                            <SubTitle
                                text={postMain ? postMain.title : ''}
                                margin={'0 0 60px 0'}
                                color={postMain ? postMain.slug === 'gps-quantum' ? '#bc101d' : '#fff' : null}
                                fontSize={postMain ? postMain.slug === 'gps-quantum' ? 42 : 56 : null}
                                lineHeight={42}
                            />
                            <Text
                                color={'#000'}
                                fontWeight={'300'}
                                fontSize={16}
                                line={15}
                                margin={'0 0 32px 0'}
                                lineHeight={24}
                            >
                                <p>{postMain ? ReactHtmlParser(postMain.description) : ''}</p>
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledServices>
    )
};

const StyledServices = styled.div`

  .p-0 {
    position: relative;
    top: -100px;
  }

  .services-single {
    padding: 7%;
    background-color: #C1C1C1;
    //&:nth-of-type(odd){
    //     flex-direction: row-reverse;
    //   }
    .col-md-6 {
      padding: 0;
    }

    .services-single__left {
      padding-top: 125%;
      position: relative;
      border-right: 2px solid #B9B9B9;
      // &:after{
      // content: '';
      // height: 100%;
      // width: 100%;
      // left: 0;
      // top: 0;
      // background-color: rgba(0,0,0,0.5);
      // position: absolute;
      //}
      img {
        height: 100%;
        width: 90%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .services-single__right {
      padding-top: 40px;
      padding-left: 56px;
      padding-right: 0 !important;
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    .services-single__right {
      padding-top: 10px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .sub-title {
        margin-bottom: 20px;
      }
    }

    .col-md-6 {
      &:nth-of-type(even) .services-single__right {
        padding-left: 30px !important;
      }

      &:nth-of-type(odd) .services-single__right {
        padding-right: 30px !important;
      }
    }

  }

  @media (max-width: 768px) {
    .p-0 {
      position: relative;
      top: 0px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .services-single {
      .col-md-6:nth-last-of-type(1) {
        padding: 0 15px;
      }

      &:nth-of-type(even) {
        display: flex;
        //flex-direction: column-reverse;
      }

      .services-single__left {
        padding-top: 130%;
        position: relative;
        border-right: 0px solid #B9B9B9;

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          padding-right: 40px;
          object-fit: cover;
        }
      }

      .col-md-6 .services-single__right {
        padding-left: 0px !important;
        padding-right: 0px !important;

        .sub-title {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .p-0 {
      position: relative;
      top: 0px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .services-single {
      padding: 15px 15px 50px;
      .col-md-6:nth-last-of-type(1) {
        padding: 0px !important;
      }

      &:nth-of-type(even) {
        display: flex;
        //flex-direction: column-reverse;
      }

      .services-single__left {
        padding-top: 125%;
        position: relative;
        border-right: 0px solid #B9B9B9;

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
          padding-right: 0;

        }
      }

      .col-md-6 .services-single__right {
        padding-left: 0px !important;
        padding-right: 0px !important;

        .sub-title {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export default AllValues;
