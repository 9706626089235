import React from 'react';
import {PageAnimation} from "../../../components/PageAnimation";
import {motion} from "framer-motion";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import Text from "../../../components/Text";
import {baseUrl} from "../../../components/BaseUrl";

let QuantumElectric = props => {
    let { posts } = props;

    let electricPosts = posts ? posts.filter(item => item.data.slug === 'why-qeaf') : []

    let imagePath = 'https://res.cloudinary.com/app-alo-social/image/upload/v1617782608/the_leading_steel_manufacturer_2x_ef5yjp.jpg'

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <StyledQuantumElectric>
                <Container className="pt-110 pb-110">
                    <div className="division-content__wrap">
                        <div className="division-content__wrap__at-a-glance">
                            <SubTitle text={'কোউয়ান্টাম ইলেকট্রিক আর্ক ফার্নেস প্রযুক্তি কেন বিশ্ব সেরা'} fontSize={48}
                                      lineHeight={54} margin={'0px 0 64px 0'} color={'#fff'}/>
                            <Row>
                                {electricPosts ? electricPosts.map(post => {
                                    let postData = post.data
                                    let postImage = post.images ? baseUrl + post.images[0].path + post.images[0].img_name : ''
                                    return (
                                        <Col md={6}>
                                            <div className="div-grid">
                                                <Text color={'#A8A8A8'} text={postData.description} line={6}/>
                                                <img src={postImage}/>
                                            </div>
                                        </Col>
                                    )
                                }) : null}
                            </Row>

                        </div>
                    </div>
                </Container>
            </StyledQuantumElectric>
        </motion.div>
    )
};

const StyledQuantumElectric = styled.div`
  background-color: #2E3744;

  .division-content__wrap {
    position: relative;
    color: #fff;
    z-index: 2;

    .button-explore {
      position: absolute;
      right: 100px;
      padding-top: 25px;
    }

    &__at-a-glance {
      .div-grid {
        display: flex;
        background-color: #28303C;
        border-bottom: 1.8rem solid #2E3744;
        border-right: 1.5rem solid #2E3744;
        &:nth-last-of-type(1) {
          border-right: 0rem solid #2E3744;
        }

        .texts {
          padding: 40px;
          width: 60%;
          height: 100%;
          color: #A8A8A8;
        }
        img{
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          width: 40%;
          height: 100%;
          object-fit: cover;
          padding-bottom: 1.8rem;
          padding-right: 1rem;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .division-content__wrap__at-a-glance .div-grid {
      display: flex;
      flex-direction: column-reverse;
    }
    .division-content__wrap__at-a-glance .div-grid .texts {
      padding: 40px;
      width: 100%;
      height: 100%;
      color: rgb(168, 168, 168);
    }

    .division-content__wrap__at-a-glance .div-grid {
      img {
        right: 0px;
        top: 0px;
        bottom: 0px;
        position: relative;
        width: 100%;
        height: 294px;
        object-fit: cover;
        padding-bottom: 1.8rem;
        padding-right: 0;
      }
    }
  }

  @media (max-width: 991px) {
    .division-content__wrap {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 767px) {

    .pb-110, .pb-150 {
      padding-bottom: 9px;
    }

    .pt-110, .pt-150 {
      padding-top: 9px;
    }
  }
`

export default QuantumElectric;
