import React, {Component} from 'react';
import styled from 'styled-components';
import {Form, Col, Row, Container} from "react-bootstrap";
import AboutImg from '../../../assets/images/dynamic/about-detail.jpg';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import {hover,darkGreen} from "../../../globalStyleVars";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';


class JobDescription extends Component {

    state = {
        name: '',
        contact_no: '',
        email: '',
        address: '',
        cv: '',
        message: '',
        position: ''
    }

    handleState = e => {
        if(e.target.name !== 'cv') {
            this.setState({ [e.target.name]: e.target.value})
        } else {
            this.setState({[e.target.name]: e.target.files[0]})
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let formField = {
            form_id: 'career-form'
        }
        let len = this.props.formFields.length

        for(let i = 0; i < len; i++) {
            formField[this.props.formFields[i]["field_key"]] = ""
        }

        formField["name"] = this.state.name
        formField["contact_no"] = this.state.contact_no
        formField["email"] = this.state.email
        formField["cv"] = this.state.cv
        // formField["message"] = this.state.message
        formField["address"] = this.state.address

        let bodyFormData = new FormData()

        for(let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        this.props.submitForm(bodyFormData)
        if(!this.state.formSubmitLoading) {
            this.setState({
                name: '',
                contact_no: '',
                email: '',
                address: '',
                cv: '',
                message: ''
            })
        }
    }

    componentDidMount() {
        let param = {
            [ApiParams.form_slug]: 'career-form'
        }
        this.props.getFormData(param)
    }

    render() {
        let { footerOffset, formFields, submitForm,formSubmitErr } = this.props

        return (
            <StyledContactForm offset={footerOffset}>
                <Container className="pt-110 pb-110">
                    <Message
                        title={formSubmitErr ? 'Error' : 'Form Submitted'}
                    />
                    <div className="contact-form">
                        <SubTitle
                            text={'Apply now'}
                            margin={'0 0 90px 0'}
                            fontSize={42}
                            textAlign={'left'}
                            color={'#ffffff'}
                        />
                        <div className="contact-form__form">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder="Type your first name here"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleState}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder="Type your last name here"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleState}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label>Email ID</Form.Label>
                                            <Form.Control
                                                type='email'
                                                placeholder="Type your email ID"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleState}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Contact number</Form.Label>
                                            <Form.Control
                                                type='number'
                                                placeholder="Type your contact number here"
                                                name="contact_no"
                                                value={this.state.contact_no}
                                                onChange={this.handleState}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col md={6} className='value-added'>
                                            <Form.Label>Position</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Job Position"
                                                name="position"
                                                value={this.state.position}
                                                onChange={this.handleState}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Cover Letter</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder='Enter your message here'
                                                name="message"
                                                value={this.state.message}
                                                onChange={this.handleState}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label>Upload CV</Form.Label>
                                            <Form.File
                                                className="input-file"
                                                name="cv"
                                                onChange={this.handleState}
                                            />
                                            {this.state.cv.name ? <p>{this.state.cv.name}</p> : ''}
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col md={6}>
                                            <Button
                                                text={'submit'}
                                                onSubmit={this.handleSubmit}
                                                fontSize={'16'}
                                                color={'#FFF'}
                                                letterSpacing={'0'}
                                                width={'100%'}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </Container>
            </StyledContactForm>
        )
    }
};

const StyledContactForm = styled.div`
  background-color: #2E3744;
  border-top: 1px solid #7b7b7b;
  a {
    color: ${hover};

    &:hover {
      color: ${darkGreen};
    }

  }

  .contact-form {
    background-color: #2E3744;

    &__form {
      form {
        label {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 10px;
          color: white;
        }

        .form-group {
          margin-bottom: 30px;

          &:nth-last-of-type(1) {
            margin-bottom: 0px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .form-control {
          background-color: #2E3744;
          height: 50px;
          line-height: 50px;
          border-radius: 0;
          border-color: #D9D9D9;
          padding-left: 20px;

          &:focus {
            border-color: ${hover};
            color: white;
          }

          &::placeholder {
            font-size: 16px;
            font-weight: 300;
            color: white;
            line-height: 21px;
          }
        }

        textarea {
          min-height: 165px;
          max-height: 150px;
          max-width: 100%;
          min-width: 100%;
          line-height: unset !important;
          padding-top: 14px;
          padding-left: 20px;
        }

        .input-file {
          height: 50px;
          //opacity: 0;
          input {
            position: relative;
            height: 50px;
            &:after {
              content: 'Upload CV/Drag your CV';
              position: absolute;
              height: 50px;
              width: 100%;
              top: 0;
              background: #2E3744;
              color: #fff;
              border: 1px solid #D9D9D9;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .form-control-file {
          cursor: pointer;
          opacity: 1;
          color: #fff;
        }

        .dc-btn {
          margin-top: 35px;
          height: 50px;

          a {
            background-color: transparent;
            text-transform: uppercase;

            &:after {
              background-color: ${hover};
            }
          }

          img {
            display: none;
          }
        }
      }
    }
  }
  
  .col-md-6 {
    height: 88px;
  }

  @media (max-width: 1000px) {
    .contact-form {
      padding: 100px 30px 100px 30px;
    }

  }

  @media (max-width: 767px) {
    //background-color:#FFF;
    padding-bottom: 0 !important;
    .container {
      padding-left: 0;
      padding-right: 0;

      .col-md-4 {
        padding-left: 30px;
        padding-right: 30px;
      }
      .col-md-6 {
        height: auto;
        padding-bottom: 8px;
      }
    }

    .contact-form {
      padding: 60px 15px;
      margin-top: 0;

      .form-group {
        margin-bottom: 20px !important;

        &:nth-last-of-type(1) {
          margin-bottom: 20px !important;
        }
      }
    }

    .contact-form__form form .dc-btn {
      margin-top: 32px;
    }

  }

`;

let mapStateToProps = state => {
    return {
        footerOffset: state.footerReducer.footerOffset,
        formFields: state.formReducer.formFields,
        formSubmitErr: state.formReducer.formSubmitErr,
        formSubmitLoading: state.formReducer.formSubmitLoading
    }
};

let mapDispatchToProps = dispatch => {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        submitForm: body => dispatch(postFormSubmit(body))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobDescription));
