import { combineReducers } from 'redux'
import homeReducer from './home'
import divisionReducer from "./division";
import footerReducer from "./footer";
import aboutReducer from "./about";
import serviceReducer from "./services";
import careerReducer from "./career";
import mediaReducer from "./media";
import formReducer from './form'
import contactReducer from './contact'
import menuReducer from "./menu";
import commonReducer from "./common";
import publicationReducer from "./event-publication";
import quantumReducer from "./quantum";

export default combineReducers({
  homeReducer,
  divisionReducer,
  footerReducer,
  aboutReducer,
  serviceReducer,
  careerReducer,
  mediaReducer,
  formReducer,
  contactReducer,
  menuReducer,
  commonReducer,
  publicationReducer,
  quantumReducer
})
