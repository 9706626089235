import React, {useLayoutEffect, useEffect} from 'react';
import InnerTextBanner from '../../components/InnerTextBanner';
import DivisitonAbout from './components/About';
import MachineSlider from './components/MachineSlider';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Resources from "./components/Resources";
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchDivisionListChildPage} from '../../redux/actions/division'
import {fetchFormData, postFormSubmit} from "../../redux/actions/form";
import Menu from '../../components/Menu';
import logoBlack from '../../assets/images/logo-black.svg';
import hamburderBlack from '../../assets/images/hamburger-black.svg';
import {ApiParams} from "../../constants/apiParams";
import ClientSlider from './components/OurClient';
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";


const DivisionDetail = (props) => {
    let {slug} = props.match.params;

    useEffect(() => {
        let params = {
            [ApiParams.page_slug]: slug
        }
        props.getDivisionListChildPage(params)

        let formParams = {
            [ApiParams.form_slug]: 'contact-form'
        }
        props.getFormData(formParams)
    }, [slug]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let {divListChildPage, formFields, postFormData} = props

    let title = divListChildPage ? divListChildPage.page_data ? divListChildPage.page_data.title : '' : '';
    let subTitle = divListChildPage ? divListChildPage.page_data ? divListChildPage.page_data.sub_title : '' : '';
    let link = divListChildPage ? divListChildPage.page_data ? divListChildPage.page_data.short_desc : '' : '';

    let posts = divListChildPage ?
        divListChildPage.posts ?
            divListChildPage.posts.count ? divListChildPage.posts.list : []
            : [] : [];

    return (
        <div>
            <Menu img={hamburderBlack} logo={logoBlack} color={'#000'}/>
            <InnerTextBanner
                title={title}
                subTitle={subTitle}
                link={link}
                linkText={link}
            />
            {divListChildPage ? (
                <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                    <DivisitonAbout posts={posts}/>
                    <MachineSlider posts={posts}/>
                    <ClientSlider/>
                    <Gallery posts={posts}/>
                    <Contact posts={posts}/>
                    <Resources/>
                </motion.div>
            ) : ''}
        </div>
    )
};

let mapStateToProps = state => {
    return {
        divListChildPage: state.divisionReducer.divListChildPage
    }
}

let mapDispatchToProps = dispatch => {
    return {
        getDivisionListChildPage: params => dispatch(fetchDivisionListChildPage(params)),
        getFormData: params => dispatch(fetchFormData(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DivisionDetail));
