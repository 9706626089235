import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Link from '../../../components/Link';
import {hover, darkGreen} from '../../../globalStyleVars';

// img
import rightArrow from '../../../assets/images/btn-arrow-white.svg';

const Resources = () => {
  return (
    <StyledResources className="pt-110 pb-110">
      <Container>
        <Row>
          <Col md={6}>
            <SubTitle text={'Resources'} color={'#FFF'} margin={'0 0 80px 0'}/>
            <Title text={'Explore Resources'} margin={'0 0 15px 0'} color={'#FFF'}/>
            <Link textTransform={'initial'} fontSize={'18'} letterSpace={'0'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'} img={rightArrow} />

          </Col>
        </Row>
      </Container>
    </StyledResources>
  )
};

const StyledResources = styled.div`
    background-color:${hover} ;
    a{
    line-height: 28px;
      &:hover{
        color: #FFF;
        letter-spacing: 0;
      }
    }
   
   @media(max-width:768px){
    .col-md-6{
      min-width: 80%;
    }
   }
   
   @media(max-width:767px){
    .col-md-6{
      min-width: 100%;
    }
   
   }
    
`;
export default Resources;
