export const PUBLICATION = {
  FETCH_EVENT_PUBLICATIONS: {
    MAIN: 'FETCH_EVENT_PUBLICATIONS',
    SUCCESS: 'FETCH_EVENT_PUBLICATIONS_SUCCESS',
    FAILURE: 'FETCH_EVENT_PUBLICATIONS_FAILURE'
  },
  FETCH_EVENT_PUBLICATIONS_PAGE: {
    MAIN: 'FETCH_EVENT_PUBLICATIONS_PAGE',
    SUCCESS: 'FETCH_EVENT_PUBLICATIONS_PAGE_SUCCESS',
    FAILURE: 'FETCH_EVENT_PUBLICATIONS_PAGE_FAILURE'
  }
}
