import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import {baseUrl} from '../../../components/BaseUrl';
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import $ from "jquery";

const DivisionDetailAbout = ({child, divListChildPage, posts,footerOffset}) => {

    let divImage = divListChildPage ?
      divListChildPage.images ?
        divListChildPage.images.list[0] ?
          baseUrl + divListChildPage.images.list[0].path + divListChildPage.images.list[0].img_name : ''
        : '' : '';

    let postData = posts ? posts.map(post => post.data) : []

    let aboutTitle = postData ? postData.find(post => post.slug === 'about-title') : ''
    let aboutBody = postData ? postData.find(post => post.slug === 'about-body') : ''

    let tableData = postData ? postData.find(post => post.slug === 'at-a-glance') : null;

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        $('.division-content__wrap').css({'margin-left': getOffset, 'width': `calc(100% - ${getOffset}})`})

    }, []);


    return (
        <StyledDivisionAbout offset={footerOffset}>
            <div className="division-content">
                <div className="division-content__img">
                    <img src={divImage} alt=""/>
                </div>

                <div className="division-content__wrap" >
                    <div className="division-content__wrap__about">
                        <SubTitle margin={'0 0 40px 0'} text={'about us'}/>
                        <Title
                            fontSize={'48'} lineHeight={'58'} fontWeight={'500'} margin={'0 0 40px 0'}
                            text={aboutTitle ? ReactHtmlParser(aboutTitle.description) : ''}/>
                        <Text margin={'0 0 20px 0'} fontWeight={'400'} lineHeight={'27'} fontSize={'18'}>
                            {aboutBody ? ReactHtmlParser(aboutBody.description) : ''}
                        </Text>

                        <Button margin={'60px 0 0 0'} text={'Read More'}/>
                    </div>

                    <div className="division-content__wrap__at-a-glance">
                        <SubTitle text={'At a Glance'} margin={'0 0 70px 0'}/>

                        <Table>
                            {tableData ?
                              tableData.description ? ReactHtmlParser(tableData.description) : null
                            : null}
                        </Table>
                    </div>


                </div>

            </div>

        </StyledDivisionAbout>
    )
};

const StyledDivisionAbout = styled.div`
  .division-content{
    background-color: #f9f9f9;
    &__img{
      height: 600px;
      position: relative;
      img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
       }
    }
  }
  .division-content__wrap{
     background: #FFF;
     width: calc(100% - ${props=>props.offset + 15}px);
     margin-left: ${props=>props.offset + 15 || 200}px;
     padding: 100px;
     margin-top: -200px;
     position: relative;
     z-index: 2;
    &__about{
       
    }
    
    &__at-a-glance{
      padding-top: 100px;
      margin-top: 100px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        height: 1px;
        left: -100px;
        right: -100px;
        background-color: #DFE6E5;
        top: 0;
      }
      
      table{
        tr{
        border-color:#DFE6E5;
        &:nth-last-of-type(1){
          border-bottom:1px solid #DFE6E5;
        }
          td{
          padding-left: 0;
          padding-top: 25px;
          padding-bottom: 25px;
          border-color:#DFE6E5;
            &:nth-of-type(1){
              font-size: 20px;
              font-weight: 400;
              line-height: 27px;
              color: rgba(0,0,0,0.5);
            }
            &:nth-of-type(2){
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  
  @media(max-width:1080px){
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
       .division-content__wrap{
       overflow: hidden;
        width: calc(100% - 15px);
        margin-left: 15px;
      }
  }
 
  @media(max-width:992px){
      .division-content__wrap{
        margin-top: -100px;
        padding: 80px 35px;
      }
       .division-content__img {
            height: 500px;
            position: relative;
        }
  }
 
  
  @media(max-width:767px){
       
      .division-content__wrap{
        padding: 80px 20px;
      }
      .division-content__img {
        height: 500px;
        position: relative;
      }
      p{
        font-size: 16px;
        line-height: 24px;
      }
      .division-content__wrap__at-a-glance{
        margin-top: 60px;
        padding-top: 60px;
        table tr td{
         padding-top: 15px;
         padding-bottom: 15px;
         font-size: 16px !important;
         line-height: 21px !important;
        }
      }
      
  }
`;

let mapStateToProps = state => {
    return {
        divListChildPage: state.divisionReducer.divListChildPage,
        footerOffset: state.footerReducer.footerOffset
    }
};

export default connect(mapStateToProps, null)(DivisionDetailAbout);
