export const MENU = {
  SCROLL_TO_VIEW:{
    MAIN: 'SCROLL_TO_VIEW',
    SUCCESS: 'SCROLL_TO_VIEW_SUCCESS',
    FAILURE: 'SCROLL_TO_VIEW_FAILURE'
  },
  FETCH_SUSTAINABILITY_MENU: {
    MAIN: 'FETCH_SUSTAINABILITY_MENU',
    SUCCESS: 'FETCH_SUSTAINABILITY_MENU_SUCCESS',
    FAILURE: 'FETCH_SUSTAINABILITY_MENU_FAILURE'
  },
  FETCH_PRODUCTS_MENU: {
    MAIN: 'FETCH_PRODUCTS_MENU',
    SUCCESS: 'FETCH_PRODUCTS_MENU_SUCCESS',
    FAILURE: 'FETCH_PRODUCTS_MENU_FAILURE'
  },
  FETCH_INVESTORS_MENU: {
    MAIN: 'FETCH_INVESTORS_MENU',
    SUCCESS: 'FETCH_INVESTORS_MENU_SUCCESS',
    FAILURE: 'FETCH_INVESTORS_MENU_FAILURE'
  }
}
