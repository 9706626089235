import React, { Component } from 'react';
import InnerBanner from '../../components/InnerBanner';
import AboutUs from './components/AboutUs';
import MissionVision from './components/MissingVision';
import History from './components/History';
import CeoMessage from './components/CeoMessage';
import Achievements from './components/Achievements';
import Menu from '../../components/Menu';
import {ApiParams} from "../../constants/apiParams";
import {fetchAboutMain, fetchAboutHistory} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Values from "./components/Values";
import CorporateInformation from "./components/CorporateInformation";
import ValueImage from '../../localization/images/core-values@2x.png'
import Timeline from './components/Timeline';

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    let mainParam = {
      [ApiParams.page_type]: 'about-us-main'
    }
    let history = {
      [ApiParams.page_type]: 'about-us-history'
    }
    this.props.getAboutMain(mainParam);
    this.props.getAboutHistory(history);

    if(this.props.sectionId) {
      let elem = document.getElementById(this.props.sectionId)
      if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
    }
  }

  render() {
    let { aboutMain } = this.props
    let { aboutHistory } = this.props

    let bannerTitle = aboutMain ?
      aboutMain.page_data ?
        aboutMain.page_data.sub_title ? aboutMain.page_data.sub_title : ''
        : ''
      : ''
    let bannerSub = aboutMain ?
      aboutMain.page_data ?
        aboutMain.page_data.short_desc ? aboutMain.page_data.short_desc : ''
        : ''
      : ''
    let bannerImagePath = aboutMain ?
      aboutMain.images ?
        aboutMain.images.list ?
          aboutMain.images.list[0] ?
            aboutMain.images.list[0].path + aboutMain.images.list[0].img_name : null
        : null
      : null
      : null;

    let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

    let posts = aboutMain ?
      aboutMain.posts ?
        aboutMain.posts.list ?
          aboutMain.posts.list.length ? aboutMain.posts.list : []
          : []
        : []
      : [];

    let valueBackground = aboutMain ?
      aboutMain.images ?
        aboutMain.images.list ?
          aboutMain.images.list.find(item => item.short_title === "values")
          : null
        : null
      : null;

    let valueBackgroundPath = valueBackground ? valueBackground.path + valueBackground.img_name : null

    let valueBackgroundImage = valueBackgroundPath ? baseUrl + valueBackgroundPath : ''

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <Menu />
        <InnerBanner
          subTitle={'about us'}
          image={banner ? banner : ''}
        />
        <AboutUs posts={posts} />
        <Values posts={posts} image={ValueImage} background={valueBackgroundImage} />
        <MissionVision posts={posts} />
        {/*<History historyMain={aboutHistory} />*/}
        <Timeline />
        <CeoMessage posts={posts} />
        <Achievements posts={posts} />
        <CorporateInformation posts={posts} />
      </motion.div>
    )
  }
};

function mapStateToProps(state) {
  return {
    aboutMain: state.aboutReducer.aboutMain,
    aboutHistory: state.aboutReducer.aboutHistory,
    sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAboutMain: params => dispatch(fetchAboutMain(params)),
    getAboutHistory: params => dispatch(fetchAboutHistory(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
