import React,{useEffect} from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import {Container} from "react-bootstrap";
import $ from 'jquery';

const VerticalLine = ({footerOffset})=>{

    useEffect(()=>{
        let getOffset = $('.container').offset().left + 15;
        if($(window).width() > 768){
            // $('.vertical-line').each(function () {
            //     $(this).find('.line').eq(0).css({'left': getOffset+15})
            // })

            $('.vertical-line').each(function () {
                // $(this).find('.line').eq(0).css({'left': getOffset+15});
                var getTwo = ($('.container').width() / 4) + getOffset ;
                $(this).find('.line:nth-of-type(1)').css('left', getOffset);
                $(this).find('.line:nth-of-type(2)').css('left', getTwo );
                $(this).find('.line:nth-of-type(3)').css('right', '50%');
                $(this).find('.line:nth-of-type(4)').css('right', getTwo );
                $(this).find('.line:nth-of-type(5)').css('right', getOffset);
            })



        }
    });

    return(
        <StyledVerticalLine className='vertical-line' offset={footerOffset}>
            <div className="line" />
            <div className="line"/>
            <div className="line"/>
            <div className="line"/>
            <div className="line"/>
        </StyledVerticalLine>
    )
};

const StyledVerticalLine = styled.div`
  .container{
    opacity: 0;
  }
  .line{
    position: absolute;
    width: 1px;
    background-color:rgba(164,164,164,0.20);
    top: 0px;
    bottom: 0px;
    z-index: 2;
    &:nth-of-type(1){
      left:${props=> props.offset + 15 || 120}px;
    }
    &:nth-of-type(2){
      left: calc(25% + 10px + ${props=> props.offset / 2}px);
    }
    &:nth-of-type(3){
      right: 50%;
    }
    &:nth-of-type(4){
      right: calc(25% + 10px + ${props=> props.offset / 2}px);
    }
    &:nth-of-type(5){
      right: ${props=> props.offset + 15 || 120}px;
    }
    @media(max-width:767px){
        &:nth-of-type(2){
          display: none;
        }
        &:nth-of-type(4){
          display: none;
        }
    }
  }
  
`;

let mapStateToProps = (state)=>{
    return{
        footerOffset: state.footerReducer.footerOffset
    }
};


export default connect(mapStateToProps)(VerticalLine) ;