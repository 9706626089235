import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {QUANTUM} from "../../constants/quantum";

function* fetchQuantumPage(actions) {
  try {
    let result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: QUANTUM.FETCH_QUANTUM_PAGE.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err);
    yield put({
      type: QUANTUM.FETCH_QUANTUM_PAGE.FAILURE
    })
  }
}

function* fetchQuantumTableOne(actions) {
  try {
    let result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    )

    yield put({
      type: QUANTUM.FETCH_TABLE_SECTION_ONE.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err);
    yield put({
      type: QUANTUM.FETCH_TABLE_SECTION_ONE.FAILURE
    })
  }
}

function* fetchQuantumTableTwo(actions) {
  try {
    let result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    )

    yield put({
      type: QUANTUM.FETCH_TABLE_SECTION_TWO.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err);
    yield put({
      type: QUANTUM.FETCH_TABLE_SECTION_TWO.FAILURE
    })
  }
}

export default function* quantumSaga() {
  yield takeLatest(QUANTUM.FETCH_QUANTUM_PAGE.MAIN, fetchQuantumPage);
  yield takeLatest(QUANTUM.FETCH_TABLE_SECTION_ONE.MAIN, fetchQuantumTableOne);
  yield takeLatest(QUANTUM.FETCH_TABLE_SECTION_TWO.MAIN, fetchQuantumTableTwo);
}
