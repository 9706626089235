import {COMMON} from "../../constants/common";

export function fetchCommonPageData(params) {
  return {
    type: COMMON.FETCH_COMMON_PAGE.MAIN,
    params
  }
}

export function fetchSustainablityPageData(params) {
  return {
    type: COMMON.FETCH_SUSTAINABILITY_INNER.MAIN,
    params
  }
}

export function fetchProductPageData(params) {
  return {
    type: COMMON.FETCH_PRODUCTS_INNER.MAIN,
    params
  }
}

export function fetchInvestorPageData(params) {
  return {
    type: COMMON.FETCH_INVESTORS_INNER.MAIN,
    params
  }
}
