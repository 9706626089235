import React, {Component} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import {hover} from '../../../globalStyleVars';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {fetchHomeBannerSlider} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import $ from 'jquery';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img
import LeftIcon from '../../../assets/images/navLeft.svg'
import RightIcon from '../../../assets/images/navRight.svg'

function RightArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className="go-right" onClick={onClick}><img src={RightIcon} height="51" width="51" alt=""/></li>
    );
}

function LeftArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className="go-left" onClick={onClick}><img src={LeftIcon} height="51" width="51" alt=""/></li>
    );
}

class BannerSlider extends Component {
    state = {
        leftOffset: 0
    }

    componentDidMount() {
        let elem = document.getElementById("main-container")
        if (elem && elem.offsetLeft) {
            this.setState({leftOffset: elem.offsetLeft})
        }

        let params = {
            [ApiParams.page_type]: 'home-banner-slider'
        }
        this.props.getHomeBannerSliderData(params);




        $('#ScrollTo').click(function () {

                var $target = $(this.hash);
                $target = $target.length && $target ||
                    $('[name=' + this.hash.slice(1) + ']');
                if ($target.length) {
                    var targetOffset = $target.offset().top;
                    $('html,body')
                        .animate({scrollTop: targetOffset}, 1000);
                    return false;
                }

        });


    }

    render() {
        let {bannerData, footerOffset} = this.props

        const bannerArray = bannerData ? bannerData.images ? bannerData.images.list : [] : [];

        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 5500,
            autoplay: true,
            pauseOnHover: false,
            nextArrow: <RightArrow/>,
            prevArrow: <LeftArrow/>
        };

        return (
            <StyledSlide offset={this.state.leftOffset} className="banner-slider">
                {/*<StyledRadius>*/}
                {/*    <p>creating happiness Since 1984 </p>*/}
                {/*</StyledRadius>*/}
                <Slick {...settings}>
                    {bannerArray ? bannerArray.map((item) => (
                        <div key={item.id} className="banner-slider__single-item">
                            <img src={baseUrl + item.path + item.img_name} alt=""/>
                            <Container id="main-container" className="banner-slider__single-item__content">
                                {/*<div className="banner-slider__single-item__content__inner">*/}
                                {/*    <h4 className='sub-title'>Palmal Group of Industries</h4>*/}
                                {/*    <h2>{item.short_title}</h2>*/}
                                {/*    <p>{item.short_desc_2}</p>*/}
                                {/*    <Button link={'/'} margin={'40px 0 0 0'} text={'explore'} color={'#FFF'}*/}
                                {/*            img={arrowImg}/>*/}
                                {/*</div>*/}
                            </Container>
                        </div>
                    )) : ''}
                </Slick>
                {/*<StyledGoDown>*/}

                {/*    <p><a id='ScrollTo' href="#godown"> about us</a></p>*/}
                {/*    <img src={goDown} alt=""/>*/}
                {/*</StyledGoDown>*/}
            </StyledSlide>
        )
    }
}

const StyledSlide = styled.div`
  &.banner-slider {
    position: relative;

    .banner-slider__single-item {
      padding-top: 51.61%;
      position: relative;
      z-index: 2;
      //&:before{
      //    content: '';
      //    position: absolute;
      //    height: 100%;
      //    width: 100%;
      //    left: 0;
      //    top: 0;
      //    bottom: 0;
      //    background-color: rgba(0,0,0,0.5);
      //    z-index: 1;
      //}
      > img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &__content {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 70px;
        z-index: 25;

        &__inner {
                    width: 60%;
                    .sub-title{
                        color: ${hover};
                        transform: translateY(35px);
                        transition:all .8s cubic-bezier(0.16, 1, 0.3, 1);
                        opacity: 0;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 10px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    h2{
                        font-size: 54px;
                        font-weight: bold;
                        margin: 0 0 30px 0;
                        line-height: 60px;
                        color: #FFF;
                        transform: translateY(35px);
                        transition:all .8s cubic-bezier(0.16, 1, 0.3, 1);
                        opacity: 0;
                    }

                    p{  
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400 ;
                        color: #FFF;
                        transform: translateY(35px);
                        //transition-delay:.3s;
                        transition:all .8s cubic-bezier(0.16, 1, 0.3, 1);
                        opacity: 0;
                        margin: 0;
                    }
        }

        .dc-btn {
          transform: translateY(35px);
          opacity: 0;
          transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 8%;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;

      li {
        button {
          &:before {
            color: #fff;
            font-size: 8px;
            opacity: 1;
          }
        }
      }

      li.slick-active button:before {
        color: #fff;
        opacity: 1;
        border: 1px solid #fff;
        border-radius: 10px;
      }
    }

    .slick-current {
      opacity: 1 !important;
      transition-delay: 0s !important;
      //transition: opacity 100ms ease 0s, visibility 100ms ease 0s !important;
    }

    .slick-active, .slick-current {
      h2, p, .sub-title, .dc-btn {
        transform: none;
        opacity: 1;
      }
            .sub-title{
                transition-delay: .4s;
            }
            h2{
                transition-delay: .7s;
            }

      p {
        transition-delay: 1s;
      }

      .dc-btn {
        cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 1.3s;
      }
    }

    .go-left {
      display: none;
      position: absolute;
      z-index: 2;
      right:${props => props.offset + 15 || '100'}px;
      top: 0;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      display: none;
      position: absolute;
      z-index: 2;
      right: ${props => props.offset + 15 || '100'}px;;
            top: 0;
            bottom: -128px;
            height: fit-content;
            margin: auto;
            cursor: pointer;
        }


        @media(max-width:767px){
            .banner-slider__single-item__content{
                top: 0;
                bottom: 0;
                height: fit-content;
                &__inner{
                    width: 95% !important;
                }
            }
            h2{
                font-size: 34px  !important;
                line-height: 40px !important;
            }

            .sub-title{
                margin-bottom: 20px;
                font-size: 16px;
            }
            .go-right{
                top: auto;
                bottom: 65px;
                right: 15px;
            }
            .go-left{
                top: auto;
                bottom: 130px;
                right: 15px;
            }
            .dc-btn{
                margin-top: 40px;
            }
        }
    }

    @media(min-width:1430px){
        .banner-slider__single-item__content{
            top: 100px;
            height: fit-content;
            margin: auto;
        }
    }
`;

const StyledRadius = styled.div`
    position: absolute;
    z-index: 5;
    left: 15px;
    color: #FFF;
    top: 50%;
    margin: 0 auto;
    transform: rotate( -90deg) translateX(-50%);
    height: 26px;
    width: fit-content;
    transform-origin:left center;
    p{
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    @media(max-width:767px){
        display: none;
    }
`;

const StyledGoDown = styled.div`
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 50px;
    
    
    p{
        color: ${hover};
        font-size: 12px;
        line-height: 24px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 20px;
        position: relative;
        white-space: nowrap;
        transform: rotate(-269deg);
        transform-origin: left bottom;
        bottom: 90px;
        left: 14px;
        a{
        font-size: 12px;
        line-height: 24px;
        font-weight: 300;
        color: ${hover};
        text-transform: uppercase;
      }
    }
`;


function mapStateToProps(state) {
    return {
        message: state.homeReducer.message,
        bannerData: state.homeReducer.bannerData,
        footerOffset: state.footerReducer.footerOffset
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeBannerSliderData: (params) => dispatch(fetchHomeBannerSlider(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSlider);
