import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";
import Text from "./Text";
import {Link} from 'react-router-dom'

const subText = ({link, text, fontSize, fontWeight, color, letterSpacing, textTransform, lineHeight, margin, hoverColor, borderRight, onClick}) => {
    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledSubText
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    textTransform={textTransform}
                    margin={margin}
                    onClick={onClick}
                    hoverColor={hoverColor}
                    borderRight={borderRight}
                    className={`${isVisible ? 'anim-active' : ''} fade-up sub-title`}
                >
                    {link ?
                      <Link to={`${link}`} style={{ color: color}}>
                          {ReactHtmlParser(text) }
                      </Link> : ReactHtmlParser(text)
                    }
                </StyledSubText>
            }
        </VisibilitySensor>
    )
};


const StyledSubText = styled.h3`
    font-size: ${props => props.fontSize || 14}px;
    font-weight:${props => props.fontWeight || 500};
    margin:${props => props.margin || '0'}; ;
    line-height:${props => props.lineHeight || 24}px;
    color:${props => props.color || `${hover}`};
    text-transform:${props => props.textTransform || 'uppercase'};
    letter-spacing:${props => props.letterSpacing || 0}px;
    border-right: ${props => props.borderRight || '1px solid #000'};
    padding-right: 24px;
    padding-left: 24px;
    &:nth-of-type(1) {
     padding-left: 0px;
    }
    &:nth-last-of-type(1) {
      border-right: 0px solid #000;
      padding-right: 0;
    }
    &:hover {
      color: ${props => props.hoverColor || '#E8434F'};
      cursor: pointer;
    }
    @media (max-width:767px){
      font-size: 14px;
      margin-bottom: 30px;
    }
`;

export default subText;
