import React, {Component} from 'react';
// components
import Slider from './components/BannerSlider'
import AboutUs from './components/AboutUs'
import BusinessDivision from './components/BusinessDevision'
import ClientSlider from './components/ProductsSlider'
import Achievement from './components/AchievementsSlider'
import Certification from './components/Certifications'
import NewsEvents from './components/NewsEvents'
import Sustainability from './components/Sustainability'
import HomeMap from './components/HomeMap'
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Quantum from "./components/Quantum";
import WhyGPHIspat from "./components/WhyGPHIspat";
import Timeline from './components/Timeline';

class HomePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <Menu/>
                <Slider/>
                <Quantum/>
                <WhyGPHIspat/>
                {/*<AboutUs/>*/}
                {/*<BusinessDivision/>*/}
                <ClientSlider/>
                {/*<Achievement/>*/}
                <Timeline/>
                <NewsEvents/>
                {/*<Sustainability/>*/}
                {/*<HomeMap/>*/}
                {/*<Map />*/}
            </motion.div>
        );
    }
};

export default HomePage;



