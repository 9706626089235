import {MENU} from "../../constants/menu";

let initialState = {
  sectionId: null,
  productMenu: [],
  productMenuLoading: false,
  productMenuErr: '',
  sustainMenu: [],
  sustainMenuLoading: false,
  sustainMenuErr: '',
  investorMenu: [],
  investorMenuLoading: false,
  investorMenuErr: ''
}

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case MENU.SCROLL_TO_VIEW.MAIN:
      return {
        ...state,
        sectionId: null
      }
    case MENU.SCROLL_TO_VIEW.SUCCESS:
      return {
        ...state,
        sectionId: action.value
      }

    case MENU.SCROLL_TO_VIEW.FAILURE:
      return {
        ...state,
        sectionId: null
      }

    case MENU.FETCH_PRODUCTS_MENU.MAIN:
      return {
        ...state,
        productMenu: [],
        productMenuLoading: true,
        productMenuErr: ''
      }

    case MENU.FETCH_PRODUCTS_MENU.SUCCESS:
      return {
        ...state,
        productMenu: action.result,
        productMenuLoading: false,
        productMenuErr: ''
      }

    case MENU.FETCH_PRODUCTS_MENU.FAILURE:
      return {
        ...state,
        productMenu: [],
        productMenuLoading: false,
        productMenuErr: 'Error from product menu loading'
      }

    case MENU.FETCH_SUSTAINABILITY_MENU.MAIN:
      return {
        ...state,
        sustainMenu: [],
        sustainMenuLoading: true,
        sustainMenuErr: ''
      }

    case MENU.FETCH_SUSTAINABILITY_MENU.SUCCESS:
      return {
        ...state,
        sustainMenu: action.result,
        sustainMenuLoading: false,
        sustainMenuErr: ''
      }

    case MENU.FETCH_SUSTAINABILITY_MENU.FAILURE:
      return {
        ...state,
        sustainMenu: [],
        sustainMenuLoading: false,
        sustainMenuErr: 'Error from sustainability menu fetching'
      }

    case MENU.FETCH_INVESTORS_MENU.MAIN:
      return {
        ...state,
        investorMenu: [],
        investorMenuLoading: true,
        investorMenuErr: ''
      }
    default:
      return state
  }
}
