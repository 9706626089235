import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Jobs from './components/Jobs';
import Menu from "../../components/Menu";
import {fetchCareerPageData} from "../../redux/actions/career";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import WhyGPHIspat from "./components/WhyGPHIspatC";
import CareerSlider from "./components/SliderCareer";
import GalleryCareer from "./components/galleryCareer";
import EmployeeFacility from "./components/employeeFacility";
import Description from "./components/Description";
import JobDescription from "./components/JobDescription";
import styled from "styled-components";
import SubTitle from "../../components/SubTitle";
import {Table} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

class Career extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let param = {
          [ApiParams.page_type]: 'career'
        }
        this.props.getCareerPageData(param)
    };
    render() {
      let { careerPage } = this.props
      let careerData = careerPage ? careerPage.page_data : null
      let careerPageSub = careerData ? careerData.title : ''
      let careerPageTitle = careerData ? careerData.sub_title : ''
      let careerPageText = careerData ? careerData.short_desc : ''

      let careerPageImg = careerPage ?
        careerPage.images ?
          careerPage.images.list ? careerPage.images.list[0] : null
          : null
        : null

      let careerBanner = careerPageImg ? baseUrl + careerPageImg.path + careerPageImg.img_name : ''

      let posts = careerPage ?
        careerPage.posts ?
          careerPage.posts.list ? careerPage.posts.list : []
          : []
        : []

      let tablePost = posts ? posts.find(item => item.data.slug === 'available-job') : null

      return(
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
          <Menu />
          <InnerBanner
            title={careerPageTitle}
            subTitle={careerPageSub}
            text={careerPageText}
            image={careerBanner}
          />
          <WhyGPHIspat posts={posts} />
          <CareerSlider posts={posts} />
          <GalleryCareer posts={posts} />
          <EmployeeFacility posts={posts} />
          <Description posts={posts} />
          <StyledDivision>
            <div className="division-content__wrap" >
              <div className="division-content__wrap__at-a-glance">
                <SubTitle
                  text={'Available job'}
                  margin={'0 0 70px 0'}
                  color='#fff'
                  fontSize={42}
                  lineHeight={30}
                  textAlign='center'
                />
                <Table hover variant="dark">
                  {tablePost ?
                    tablePost.data ? ReactHtmlParser(tablePost.data.description) : ''
                  : ''
                  }
                </Table>
              </div>
            </div>
          </StyledDivision>
          <JobDescription />
        </motion.div>
      )
    }
};

function mapStateToProps(state) {
  return {
    careerPage: state.careerReducer.careerPage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCareerPageData: params => dispatch(fetchCareerPageData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Career);

const StyledDivision = styled.div`
  background-color: #2E3744;
  padding: 10% 20%;
  .division-content__wrap{
    position: relative;
    z-index: 2;
    &__at-a-glance{
      position: relative;
      .table-dark {
        background-color: #2E3744;
      }
      table{
        tr{
          border-color:#FAFAFA;
          &:nth-of-type(odd) {
            background-color: #2B333D;
          }
          &:nth-of-type(even) {
            background-color: #2E3744;
          }
          &:nth-last-of-type(1){
            border-bottom: 0 solid #7d7d7d;
          }
          td{
            padding-left: 25px;
            padding-top: 25px;
            padding-bottom: 25px;
            font-size: 17px;
            font-weight: 100;
            line-height: 20px;
            color: #FAFAFA;
            
            img {
              padding: 5px 5px 8px 5px;
              width: 24px;
            }
          }
        }
      }
    }
  }
  @media(max-width: 480px) {
    padding: 10%;
  }
  @media(max-width: 365px) {
    padding: 6%;
  }
`
