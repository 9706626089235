import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import Title from '../../../components/Title';
import Slider from "react-slick";

// img
import image from '../../../assets/images/dynamic/timeline.png';
import navLeft from '../../../assets/images/navLeftWhite.svg';
import navRight from '../../../assets/images/navRightWhite.svg';
import $ from "jquery";

function RightArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className="go-right" onClick={onClick}><img src={navRight} height="51" width="51" alt=""/></div>
    );
}

function LeftArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className="go-left" onClick={onClick}><img src={navLeft} height="51" width="51" alt=""/></div>
    );
}

export default class Timeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });

        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 768) {
            $('.history-slider .swiper-container').css({'padding-left': getOffset});
            setTimeout(function () {
                $('.timeline').css({'padding-left': getOffset, 'padding-right': getOffset})
            }, 500)
        }
    }

    render() {

        const settings = {
            slidesToShow: 8,
            pauseOnHover: false,
            swipeToSlide:true,
            focusOnSelect:true,
            nextArrow: <RightArrow/>,
            prevArrow: <LeftArrow/>,
            responsive: [

                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow:3,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const settingsNd = {

        };


        return (
            <StyledTimeline className="pt-110 pb-110">
                <img src={image} alt=""/>
                <Container>
                    <Title textTransform={'uppercase'} margin={'0 0 80px 0'} color={'#FFF'} text={'Timeline'}/>
                </Container>

                <Container fluid className='timeline'>
                    <Row>
                        <Col md={12}>
                            <ul className='timeline__year'>
                                <Slider {...settings} asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)}>
                                    <li>1952</li>
                                    <li>1970</li>
                                    <li>1984</li>
                                    <li>1996</li>
                                    <li>2006</li>
                                    <li>2008</li>
                                    <li>2009</li>
                                    <li>2010</li>
                                    <li>2011</li>
                                    <li>2012</li>
                                    <li>2013</li>
                                    <li>2014</li>
                                    <li>2015</li>
                                    <li>2016</li>
                                    <li>2017</li>
                                    <li>2018</li>
                                    <li>2019</li>
                                    <li>2020</li>
                                </Slider>
                            </ul>
                        </Col>


                        <Col md={12}>
                            <div className="timeline__content">
                                <Slider {...settingsNd}
                                    asNavFor={this.state.nav2}
                                    ref={slider => (this.slider1 = slider)}
                                >
                                    <div className="timeline__content__single">
                                        <div className="timeline__content__single__inner">
                                            <p>
                                                In 1952, Akberali, along with four businessmen- Mr. Akberali Alibhai
                                                Africawala,
                                                Mr.
                                                Taherali Africawala, Mr. Abdul Hussain Africawala, and Mr. Rajabali
                                                Africawala,
                                                set
                                                up the first steel re-rolling mill of the then East Bengal in Nasirabad,
                                                Chittagong.
                                            </p>
                                            <p>
                                                BSRM started its journey with four manual rolling mills. The rest is
                                                history.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline__content__single">
                                        <div className="timeline__content__single__inner">
                                            <p>
                                                In 1952, Akberali, along with four businessmen- Mr. Akberali Alibhai
                                                Africawala,
                                                Mr.
                                                Taherali Africawala, Mr. Abdul Hussain Africawala, and Mr. Rajabali
                                                Africawala,
                                                set
                                                up the first steel re-rolling mill of the then East Bengal in Nasirabad,
                                                Chittagong.
                                            </p>
                                            <p>
                                                BSRM started its journey with four manual rolling mills. The rest is
                                                history.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline__content__single">
                                        <div className="timeline__content__single__inner">
                                            <p>
                                                In 1952, Akberali, along with four businessmen- Mr. Akberali Alibhai
                                                Africawala,
                                                Mr.
                                                Taherali Africawala, Mr. Abdul Hussain Africawala, and Mr. Rajabali
                                                Africawala,
                                                set
                                                up the first steel re-rolling mill of the then East Bengal in Nasirabad,
                                                Chittagong.
                                            </p>
                                            <p>
                                                BSRM started its journey with four manual rolling mills. The rest is
                                                history.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline__content__single">
                                        <div className="timeline__content__single__inner">
                                            <p>
                                                In 1952, Akberali, along with four businessmen- Mr. Akberali Alibhai
                                                Africawala,
                                                Mr.
                                                Taherali Africawala, Mr. Abdul Hussain Africawala, and Mr. Rajabali
                                                Africawala,
                                                set
                                                up the first steel re-rolling mill of the then East Bengal in Nasirabad,
                                                Chittagong.
                                            </p>
                                            <p>
                                                BSRM started its journey with four manual rolling mills. The rest is
                                                history.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline__content__single">
                                        <div className="timeline__content__single__inner">
                                            <p>
                                                In 1952, Akberali, along with four businessmen- Mr. Akberali Alibhai
                                                Africawala,
                                                Mr.
                                                Taherali Africawala, Mr. Abdul Hussain Africawala, and Mr. Rajabali
                                                Africawala,
                                                set
                                                up the first steel re-rolling mill of the then East Bengal in Nasirabad,
                                                Chittagong.
                                            </p>
                                            <p>
                                                BSRM started its journey with four manual rolling mills. The rest is
                                                history.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>

                    </Row>
                </Container>

            </StyledTimeline>
        )
    }
};


const StyledTimeline = styled.div`
  position: relative;
  &:after{
    content: '';
    position: absolute;
    background-color: rgba(38,46,57,0.90);
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
  }
  >img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  .container,.container-fluid{
    z-index: 2;
    position: relative;
  }
  

    .timeline{
        &__year{
        //display:flex;
        margin-bottom: 45px;
        position: relative;
        //padding-top: 30px;
        &:after{
          content: '';
          position: absolute;
          top: 0;
          height: 1px;
          width: 100%;
          background-color: rgba(247,242,228,0.3);;
        }
          li{
            font-size: 18px;
            line-height: 30px;
            color:rgba(247,242,228,0.4);
            //padding-right: 25px;
            //margin-right: 25px;
            position: relative;
            cursor: pointer;
            padding-top: 40px;
            &:before{
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 16px solid rgba(255,255,255,0.30);
                top: 0px;
                left: 0;
                right: 25px;
                margin: auto;
                 opacity: 0;
            }
            &:after{
                 content: '';
                position: absolute;
                height: 3px;
                width: 3px;
                background-color: #fff;
                bottom: 0;
                margin: auto;
                top: 40px;
                left: 35px;
                right: 0;
            }
          }
        }
        
        &__content{
          &__single{
              &__inner{
              width: 40%;
                  p{
                      color: rgba(255,255,255,0.70);
                      font-size: 16px;
                      line-height: 24px;
                      margin-bottom: 15px;
                      &:nth-last-of-type(1){
                        margin-bottom: 0;
                      }
                    }
              }
            
          }
          .slick-arrow{
            display: none !important;
          }
        }
  }
  
  .slick-current{
    li{
    font-size: 50px;
    color: #ffffff;
        &:before{
          opacity: 1 !important;
        }
        &:after{
          left: auto;
          right: 20px;
        }
    }
  }
  
  .go-left{
    position: absolute !important;
    top: -25px;
    left: -52px;
    cursor: pointer;
    &:after{
      display: none;
    }
  }  
  .go-right{
    position: absolute !important;
    top: -25px;
    right: -52px;
    cursor: pointer;
    &:after{
      display: none;
    }
  }  
    
  
  @media(max-width:768px){
     .timeline__year li:before{
        right: 70px;
     }
      
    .timeline__content__single__inner{
      width: 80%;
    }
  }
  @media(max-width:767px){
    .timeline__year li{
      padding-top: 27px;
      &:before{
        right: auto;
        left: 20px;
      }
      &:after{
        top: 28px;
      }
    }
    .slick-current li{
      font-size: 30px;
    }
    
     .timeline__content__single__inner{
      width: 100%;
    }
   
  }
  
  
  

`;


