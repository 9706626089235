import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../globalStyleVars";
import Swiper from 'react-id-swiper';
import SubTitle from './SubTitle';
import SubText from './SubText';

import Button from './Button';
import Title from './Title';

// img
import RightIcon from "../assets/images/navRight.svg";
import LeftIcon from "../assets/images/navLeft.svg";
import aw1 from "../assets/images/dynamic/blur.jpg";
import bg from "../assets/images/achievements.jpg";
import {baseUrl} from "../components/BaseUrl";

import {fetchCommonPageData} from "../redux/actions/common";
import {connect} from 'react-redux'
import {ApiParams} from "../constants/apiParams";

class ParallelPages extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if(this.props.page) {
      let params = {
        [ApiParams.page_type]: this.props.page
      }

      this.props.getCommonPageData(params)
    }
  }
  render() {
    let { backgroundColor, titleText, titleColor, color, hoverColor, page, commonPage } = this.props

    let pages = commonPage ?
      commonPage.posts ?
        commonPage.posts.list.map(item => item.data)
        : []
      : []

    let len = pages.length

    page = page === 'publications' ? 'event-publication' : page

    let imagePath = 'https://res.cloudinary.com/app-alo-social/image/upload/v1617782608/the_leading_steel_manufacturer_2x_ef5yjp.jpg'

    const params = {
      slidesPerView: 3,
      spaceBetween: 0,
      speed: 1500,
      freeMode: false,
      breakpoints: {
        769: {
          slidesPerView: 6,
          spaceBetween: 30,
          freeMode: true,
        },
      }
    }

    return (
      <StyledParallelPages className="pt-110 pb-110" backgroundColor={backgroundColor} hoverColor={hoverColor}>
        <Container className="title-here">
          <div className="borders">
            <SubTitle
              text={titleText}
              fontSize={54}
              margin={'32px 0 21px 0'}
              color={titleColor}
            />
          </div>
         <div style={{ justifyContent: 'center', display: 'flex'}}>
           {len && len === pages.length ? (
               <Swiper {...params}>
                 {pages.map(item => (
                     // <h3 className="sub-text-view">{item.title}</h3>
                     <SubText
                         text={item.title}
                         fontSize={24}
                         color={color}
                         letterSpacing={1}
                         hoverColor={hoverColor}
                         borderRight={'1px solid #adadad'}
                         link={
                           item["Extra description one"] ?
                               `/${item["Extra description one"]}/${item.slug}` :
                               `/${page}/${item.slug}`
                         }
                     />
                     // <img src={imagePath} alt=""/>
                 ))}
               </Swiper>
           ) : null}
         </div>
        </Container>
      </StyledParallelPages>
    )
  }
};


const StyledParallelPages = styled.div`
    // background-image: url(${bg});
  // background-position: center;
  // background-repeat: no-repeat;
  // -webkit-background-size: cover;
  // background-size: cover;
  background-color: ${props => props.backgroundColor || '#2E3744'};

  .title-here {
    .borders {
      border-top: 1px solid #B9B9B9;
      border-bottom: 1px solid #B9B9B9;
      margin: 10px 0 24px 0;
      text-align: center;
    }

    .sub-text-view {
      color: #69788D;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      display: inline-block;
      text-transform: uppercase;
      cursor: pointer;
      padding-right: 24px;
      padding-left: 24px;
      border-right: 1px solid #adadad;
      &:hover {
        color: #fff;
      }
      &:nth-last-of-type(1) {
        border-right: 0;
      }
  }

    .title {
      width: 60%;
    }
  }

  @media (max-width: 991px) {
    .title-here .sub-text-view {
      color: #69788D;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      padding-right: 24px;
      padding-left: 24px;
      border-right: 1px solid #adadad;
      &:hover {
        color: #fff;
      }
      &:nth-last-of-type(1) {
        border-right: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .title-here .sub-text-view {
      color: #69788D;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      padding-right: 24px;
      padding-left: 24px;
      border-right: 1px solid #adadad;
      &:hover {
        color: #fff;
      }
      &:nth-last-of-type(1) {
        border-right: 0;
      }
    }
    .borders .sub-title {
      margin: 16px 0 10px 0 !important;
    }

    .title-here .sub-title {
      margin-bottom: 0px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }
  }
`;

function mapStateToProps(state) {
  return {
    commonPage: state.commonReducer.commonPage,
    sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCommonPageData: params => dispatch(fetchCommonPageData(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParallelPages);
