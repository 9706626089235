import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchSustainablityPageData} from "../../redux/actions/common";
import StyledGrid from "../../components/StyledGridQuantam";
import {withRouter} from 'react-router-dom'
import styled from "styled-components";
import {Container} from 'react-bootstrap';
import {BACKGROUND_IMAGE} from '../../config'
import PhotoGallery from "../../components/PhotoGallery";
import ParallelPages from "../../components/ParallelPages";
import EventPublication from "../../components/EventPublication";
import SubText from '../../components/SubText'

class Sustainability extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if(this.props.match.params) {
      let params = {
        [ApiParams.page_slug]: this.props.match.params.page_slug
      }

      this.props.getSustainabilityPageData(params)
    }
  }

  render() {
    let { sustainabilityPage } = this.props;
    let pageData = sustainabilityPage ? sustainabilityPage.page_data : null
    let pageSub = pageData ? pageData.title : ''

    let bannerImagePath = sustainabilityPage ?
      sustainabilityPage.images ?
        sustainabilityPage.images.list ?
          sustainabilityPage.images.list[0] ?
            sustainabilityPage.images.list[0].path + sustainabilityPage.images.list[0].img_name : null
          : null
        : null
      : null

    let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

    let posts = sustainabilityPage ? sustainabilityPage.posts ? sustainabilityPage.posts.list : [] : []

    let mediaImages = pageData ?
      pageData.template === 'images' ?
          sustainabilityPage.images.list.filter(item => item.sort_order !== 0)
        : [] : []

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <Menu/>
        <InnerBanner
          subTitle={pageSub}
          image={banner}
        />
        {pageData ?
          pageData.template === 'images' ?
            (
              <PhotoGallery mediaImages={mediaImages} />
            ) : (
            <StyledMidSection>

              <Container>
                <h3 className="container-h3">TWO TYPES OF PRODUCTS ARE MANUFACTURED AT GPH ISPAT LTD.</h3>
                <StyledGrid
                  background={BACKGROUND_IMAGE}
                  data={posts}
                  fontSize={42}
                  lineHeight={30}
                />
              </Container>
            </StyledMidSection>
          )
          : null}
          <EventPublication/>
        <ParallelPages
          backgroundColor={'#2E3744'}
          titleColor={'#fff'}
          titleText={'sustainability'}
          color={'#69788D'}
          hoverColor={'#fff'}
          page={'sustainability'}
        />
      </motion.div>
    )
  }
};

const StyledMidSection = styled.div`
  position: relative;
  background-color: #2E3744;

  .container-h3 {
    color: #fff;
    font-size: 17px;
    font-weight: normal;
    position: absolute;
    top: 6%;
  }
  
  .texts {
    color: #F7F2E4;
  }
  
  @media (max-width: 767px) {
    .container-h3 {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      top: 2.5%;
    }
  }

  @media (max-width: 450px) {
    .container-h3 {
     display: none;
    }
  }

 @keyframes bouncing{
      0%{
        transform: translateY(0px);
      }
      100%{
        transform: translateY(15px);
      }
    }

`;


function mapStateToProps(state) {
  return {
    sustainabilityPage: state.commonReducer.sustainabilityInner,
    sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSustainabilityPageData: params => dispatch(fetchSustainablityPageData(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sustainability));
