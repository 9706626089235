import {CAREER} from "../../constants/career";

export function fetchCareerPageData(params) {
  return {
    type: CAREER.FETCH_CAREER_PAGE.MAIN,
    params
  }
}

export function fetchCareerListData(params) {
  return {
    type: CAREER.FETCH_CAREER_LIST.MAIN,
    params
  }
}
