import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import Text from './Text';
import {connect} from 'react-redux';
import $ from 'jquery';
import MissionImage from '../localization/images/mission.png'
import VisionImage from '../localization/images/vision.png'
import Modal from "./Modal";

import {baseUrl} from "./BaseUrl";
import ReactHtmlParser from "react-html-parser";
import Button from "./Button";

const AllValues = props => {
    let { posts, image } = props

    let [openModal, setOpenModal] = useState(false)
    let [title, setTitle] = useState('')
    let [description, setDescription] = useState('')

    let postData = posts ? posts.find(post => post.data.slug === 'values' || post.data.slug === 'gps-quantum') : null
    let postMain = postData ? postData.data ? postData.data : null : null

    let postImage = postData ?
        postData.images ?
            postData.images[0] ?
                baseUrl + postData.images[0].path + postData.images[0].img_name : ''
            : ''
        : ''

    let handleModal = () => {
        setOpenModal(!openModal)
    }

    let handlePost = (title, description) => {
        setTitle(title)
        setDescription(description)
        handleModal()
    }

    useEffect(() => {
        let getOffset = $('.container').offset().left + 30;
        if ($(window).width() > 769) {
            $('.services-single:nth-of-type(even) .services-single__right').css({'padding-left': getOffset})
            $('.services-single:nth-of-type(odd) .services-single__right').css({'padding-right': getOffset})
        }
    },[])


    return (
        <StyledServices>
            <Container fluid className='p-0'>
                <Modal
                    show={openModal}
                    handleClose={handleModal}
                    post={description}
                    title={title}
                />
                <Row className="services-single">
                    <Col md={6}>
                        <div className="services-single__left">
                            <img src={postImage} alt=""/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="services-single__right">
                            <SubTitle
                                text={postMain ? postMain.title : ''}
                                margin={'0 0 60px 0'}
                                color={postMain ? postMain.slug === 'gps-quantum' ? '#bc101d' : '#fff' : null}
                                fontSize={postMain ? postMain.slug === 'gps-quantum' ? 42 : 56 : null}
                                lineHeight={42}
                            />
                            <Text
                                color={'#333333'}
                                fontWeight={'300'}
                                fontSize={18}
                                line={8}
                                margin={'0 0 32px 0'}
                                lineHeight={28}
                            >
                                <p>{postMain ? ReactHtmlParser(postMain.description) : ''}</p>
                            </Text>

                            <div onClick={() => handlePost(postMain ? postMain.title : '', postMain.description ? postMain.description : '')}>
                               <Button text={'Read more'} color={'#373D46'} border={'1px solid #333333'}/>
                           </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledServices>
    )
};

const StyledServices = styled.div`
   
  .p-0{
    position: relative;
    top: -100px;
  }
    .services-single{
      padding: 3%;
      background-color: #C1C1C1;
     //&:nth-of-type(odd){
     //     flex-direction: row-reverse;
     //   }
      .col-md-6{
        padding: 0;
      }
      .services-single__left{
        padding-top: 87.85%;
        position: relative;
        border-right: 2px solid #B9B9B9;
       // &:after{
       // content: '';
       // height: 100%;
       // width: 100%;
       // left: 0;
       // top: 0;
       // background-color: rgba(0,0,0,0.5);
       // position: absolute;
       //}
        img{
          height: 100%;
          width: 75%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .services-single__right{
        padding-top: 40px;
        padding-left: 85px;
        height: 100%;
      }
    }
    @media(max-width:991px){
      .services-single__right{
        padding-top: 10px !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title{
          margin-bottom: 20px;
          font-size: 25px;
        }
      }
      .col-md-6{
         &:nth-of-type(even) .services-single__right{
          padding-left: 30px !important;
        }
         &:nth-of-type(odd) .services-single__right{
          padding-right: 30px !important;
        }
      }
   
    }
  
    @media(max-width:768px){
      .p-0{
        position: relative;
        top: 0px;
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .services-single{
      padding-bottom: 50px;
       .col-md-6:nth-last-of-type(1){
        padding:0 15px;
      }
      &:nth-of-type(even){
        display: flex;
        //flex-direction: column-reverse;
      }

        .services-single__left{
          padding-top: 87.85%;
          position: relative;
          border-right: 0px solid #B9B9B9;
          img{
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }
        }
         .col-md-6 .services-single__right{
            padding-left: 0px !important;
            padding-right: 0px !important;
            
            .sub-title{
               margin-top:10px;
               margin-bottom: 10px;
            }
          }
      }
    }

  @media(max-width:767px){
    .p-0{
      position: relative;
      top: 0px;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .services-single{
      padding-bottom: 50px;
      .col-md-6:nth-last-of-type(1){
        padding:0px !important;
      }
      &:nth-of-type(even){
        display: flex;
        //flex-direction: column-reverse;
      }

      .services-single__left{
        padding-top: 87.85%;
        position: relative;
        border-right: 0px solid #B9B9B9;
        img{
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .col-md-6 .services-single__right{
        padding-left: 0px !important;
        padding-right: 0px !important;

        .sub-title{
          margin-top:10px;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export default AllValues;
