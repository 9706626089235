import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import {Container, Row, Col} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";

import $ from "jquery";
import AddressTab from "./AddressTab";
import {motion} from "framer-motion";

import pin from '../../../assets/images/pin.svg'
import phone from '../../../assets/images/telephone66.svg'
import telephone from '../../../assets/images/telephone.svg'
import telephone33 from '../../../assets/images/Telephone33.svg'

const Address = ({ posts, image, background }) => {
  let postData = posts ? posts.find(post => post.data.slug === 'head-office') : null

  let [activeTab, setActive] = useState(postData)

  let postImage = activeTab ?
    activeTab.images ?
      activeTab.images[0] ?
        baseUrl + activeTab.images[0].path + activeTab.images[0].img_name : ''
      : ''
    : postData ?
      postData.images ?
        postData.images[0] ?
          baseUrl + postData.images[0].path + postData.images[0].img_name : ''
        : ''
      : ''

  let postMain = activeTab ?
    activeTab.data :
      postData ?
        postData.data ? postData.data : null
        : null

  useEffect(()=>{
    let getOffset = $('.container').offset().left + 15;
    if($(window).width() > 768){
      $('.mission-vision-banner-thumb').css({'padding-left': getOffset+170});
      $('.mission-vision-banner-thumb >img').css({'left': getOffset+70})
    }
  });

  return (
    <StyleValues background={background}>
        <AddressTab
          backgroundColor={'#00000000'}
          titleText={'contact'}
          titleColor={'#fafafa'}
          color={'#fafafa'}
          address={posts}
          setActive={setActive}
        />
      <Container id="values">
        <div className="div-address">
          <StyledServices>
            <Container fluid className='p-0'>
              <Row className="services-single">
                <Col md={6}>
                  <div className="services-single__left">
                    <img src={postImage} alt=""/>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="services-single__right">
                    <SubTitle
                        text={postMain ? postMain.title : ''}
                        margin={'0 0 60px 0'}
                        color='#111111'
                        fontSize={42}
                        fontWeight={'normal'}
                        lineHeight={40}
                        textTransform={'normal'}
                    />
                    <div className="text-line">
                      <img src={pin}/>
                      <div style={{width: '100%'}}>
                        <Text
                            color={'#000'}
                            fontWeight={'300'}
                            fontSize={14}
                            line={6}
                            margin={'0 0 32px 0'}
                            lineHeight={18}
                        >
                          <p>{postMain ? ReactHtmlParser(postMain.description) : ''}</p>
                        </Text>
                        <div style={{ borderBottom: '2px solid #B9B9B9', marginBottom: 30}} />
                      </div>
                    </div>

                    <div className="text-line">
                      <img src={phone}/>
                      <div style={{width: '100%'}}>
                        <Text
                            color={'#000'}
                            fontWeight={'300'}
                            fontSize={14}
                            line={6}
                            margin={'0 0 32px 0'}
                            lineHeight={18}
                        >
                          <p>{postMain ? ReactHtmlParser(postMain["Extra description one"]) : ''}</p>
                        </Text>
                        <div style={{ borderBottom: '2px solid #B9B9B9', marginBottom: 30}} />
                      </div>
                    </div>

                    <div className="text-line">
                      <img src={telephone}/>
                      <div style={{width: '100%'}}>
                        <Text
                            color={'#000'}
                            fontWeight={'300'}
                            fontSize={14}
                            line={6}
                            margin={'0 0 32px 0'}
                            lineHeight={18}
                        >
                          <p>{postMain ? ReactHtmlParser(postMain["Extra description two"]) : ''}</p>
                        </Text>
                        <div style={{ borderBottom: '2px solid #B9B9B9', marginBottom: 30}} />
                      </div>
                    </div>

                    <div className="text-line">
                      <img src={telephone33}/>
                      <div style={{width: '100%'}}>
                        <Text
                            color={'#000'}
                            fontWeight={'300'}
                            fontSize={14}
                            line={6}
                            margin={'0 0 32px 0'}
                            lineHeight={18}
                        >
                          <p>{postMain ? ReactHtmlParser(postMain["Extra description three"]) : ''}</p>
                        </Text>
                        <div style={{ borderBottom: '2px solid #B9B9B9', marginBottom: 30}} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </StyledServices>
        </div>
      </Container>
    </StyleValues>
  )
};

const StyleValues = styled.div`
  background-image: url(${props => props.background ||  ''}); 
  
  .div-address {
    padding: 0 0 10% 0;
  }
  
  .mission-vision-banner-thumb{
    padding-left: 170px;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    //@media(min-width: 992px){
    //  max-width: 960px;
    //}
    //@media(min-width: 1200px){
    //  max-width: 1140px;
    //}
    //@media  (min-width: 1440px) {
    //    width: 1366px;
    //}
    > img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 70px;
    }

    &__img {
      padding-top: 54.64%;
      position: relative;
      overflow: hidden;

      > img {
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }

  }

  .mission-vision-content {
    margin-top: 100px;
    padding-left: 170px;

    ul {
      margin-top: 30px;

      li {
        margin-bottom: 20px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }

  }

  @media (max-width: 991px) {
    .mission-vision-banner-thumb {
      width: 90%;
      padding-left: 0;
      margin: 0 auto;

      > img {
        display: none;
      }
    }

    .mission-vision-content {
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    .div-address {
      padding: 0;
    }
    .mission-vision-banner-thumb {
      width: calc(100% - 30px);
    }

    .mission-vision-content {
      margin-top: 60px;

      ul li {
        margin-bottom: 15px;
      }
    }

    .sub-title {
      margin-top: 60px;
    }

    p {
      font-weight: 300;
    }
  }

  .go-down {
    animation: bouncing .800s ease-in-out infinite alternate;
  }

  @keyframes bouncing {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(15px);
    }
  }

`;

const StyledServices = styled.div`
    .services-single{
      padding: 7%;
      background-color: #C1C1C1;
     //&:nth-of-type(odd){
     //     flex-direction: row-reverse;
     //   }
      .col-md-6{
        padding: 0;
      }
      .services-single__left{
        padding-top: 91%;
        position: relative;
        border-right: 2px solid #B9B9B9;
        height: 100%;
        img{
          height: 100%;
          width: 80%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      
      .services-single__right{
        padding-top: 40px;
        padding-left: 85px;
        height: 100%;
        
        .text-line {
          display: flex;
          img {
            padding-bottom: 64px;
            margin-right: 16px;
            width: 24px;
          }
        }
      }
    }
    @media(max-width:991px){
      .services-single{
        .services-single__left{
          padding-top: 87.85%;
          position: relative;
          border-right: 2px solid #B9B9B9;
          height: 100%;
          img{
            height: 100%;
            width: 90%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }
        }
        
        .services-single__right{
          padding-top: 10px !important;
          display: flex;
          justify-content: center;
          flex-direction: column;
          .sub-title{
            margin-bottom: 20px;
          }
          .text-line {
            display: flex;
            img {
              padding-bottom: 64px;
              margin-right: 16px;
              width: 18px;
            }
          }
        }
      }
     
      .col-md-6{
         &:nth-of-type(even) .services-single__right{
          padding-left: 30px !important;
        }
         &:nth-of-type(odd) .services-single__right{
          padding-right: 30px !important;
        }
      }

    }
    @media(max-width:767px){
      .services-single{
        padding: 15px 15px 50px;
       .col-md-6:nth-last-of-type(1){
        padding:0;
      }
      &:nth-of-type(even){
        display: flex;
        //flex-direction: column-reverse;
      }
         .col-md-6 .services-single__right{
            padding-left: 0px !important;
            padding-right: 0px !important;

            .sub-title{
               margin-top:10px;
               margin-bottom: 10px;
            }
          }
        .services-single__left{
          border-right: 0px solid #B9B9B9;
          img{
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }
        }
      }
    }
`;

export default Address;
