import {COMMON} from "../../constants/common";

let initialState = {
  commonPage: [],
  commonPageLoading: false,
  commonPageErr: '',
  sustainabilityInner: [],
  sustainabilityInnerLoading: false,
  sustainabilityInnerError: '',
  productsInner: [],
  productsInnerLoading: false,
  productsInnerErr: '',
  investorInner: [],
  investorInnerLoading: false,
  investorInnerErr: ''
}

export default function commonReducer(state = initialState, action) {
  switch(action.type) {
    case COMMON.FETCH_COMMON_PAGE.MAIN:
      return {
        ...state,
        commonPage: [],
        commonPageLoading: true,
        commonPageErr: ''
      }

    case COMMON.FETCH_COMMON_PAGE.SUCCESS:
      return {
        ...state,
        commonPage: action.result,
        commonPageLoading: false,
        commonPageErr: ''
      }

    case COMMON.FETCH_COMMON_PAGE.FAILURE:
      return {
        ...state,
        commonPage: [],
        commonPageLoading: false,
        commonPageErr: 'Error from page fetching'
      }

    case COMMON.FETCH_SUSTAINABILITY_INNER.MAIN:
      return {
        ...state,
        sustainabilityInner: [],
        sustainabilityInnerLoading: true,
        sustainabilityInnerError: ''
      }

    case COMMON.FETCH_SUSTAINABILITY_INNER.SUCCESS:
      return {
        ...state,
        sustainabilityInner: action.result,
        sustainabilityInnerLoading: false,
        sustainabilityInnerError: ''
      }

    case COMMON.FETCH_SUSTAINABILITY_INNER.FAILURE:
      return {
        ...state,
        sustainabilityInner: [],
        sustainabilityInnerLoading: false,
        sustainabilityInnerError: 'Error from page loading'
      }


    case COMMON.FETCH_PRODUCTS_INNER.MAIN:
      return {
        ...state,
        productsInner: [],
        productsInnerLoading: true,
        productsInnerErr: ''
      }

    case COMMON.FETCH_PRODUCTS_INNER.SUCCESS:
      return {
        ...state,
        productsInner: action.result,
        productsInnerLoading: false,
        productsInnerErr: ''
      }

    case COMMON.FETCH_PRODUCTS_INNER.FAILURE:
      return {
        ...state,
        productsInner: [],
        productsInnerLoading: false,
        productsInnerErr: 'Error from page loading'
      }

    case COMMON.FETCH_INVESTORS_INNER.MAIN:
      return {
        ...state,
        investorInner: [],
        investorInnerLoading: true,
        productsInnerErr: ''
      }

    case COMMON.FETCH_INVESTORS_INNER.SUCCESS:
      return {
        ...state,
        investorInner: action.result,
        investorInnerLoading: false,
        productsInnerErr: ''
      }

    case COMMON.FETCH_INVESTORS_INNER.FAILURE:
      return {
        ...state,
        investorInner: [],
        investorInnerLoading: false,
        investorInnerErr: 'Error from page loading'
      }
    default:
      return state
  }
}
