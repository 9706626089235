import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Tab from './components/TabPanel';
import {connect} from 'react-redux';
import {fetchDivisionPageData} from '../../redux/actions/division'
import BannerImage from '../../assets/images/dynamic/inner-banner.jpg'
import {ApiParams} from "../../constants/apiParams";
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";


class Division extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let params = {
            [ApiParams.page_type]: 'division'
        };

        this.props.getDivisionPageData(params)

        if(this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId)
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }


    render() {
        let {divPageData, footerOffset} = this.props

        let page_id = divPageData ? divPageData.page_data ? divPageData.page_data.id : null : null
        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <Menu/>
                <InnerBanner
                    title={'Featured Divisions'}
                    subTitle={'division'}
                    text={'Palmal Group of Industries, one of the promising RMG manufacturing business organizations in Bangladesh'}
                    image={BannerImage}
                />
                {page_id ? (
                    <Tab page_id={page_id}/>
                ) : null}
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        divPageData: state.divisionReducer.divPageData,
        footerOffset: state.footerReducer.footerOffset,
        sectionId: state.menuReducer.sectionId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDivisionPageData: params => dispatch(fetchDivisionPageData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Division);
