import React, { Component } from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from '../../components/Menu';
import {ApiParams} from "../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import ProductSpecification from "../productSpecification/components/ProductSpecification";
import EventPublication from "../../components/EventPublication";
import {fetchProductPageData} from "../../redux/actions/common";
import {withRouter} from 'react-router-dom'
import ParallelPages from "../../components/ParallelPages";

class Specification extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.props.match.params) {
            let params = {
                [ApiParams.page_slug]: this.props.match.params.page_slug
            }

            this.props.getProductPageData(params)
        }

        if(this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId)
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }

    render() {
        let { productPage } = this.props

        let bannerTitle = productPage ?
          productPage.page_data ?
            productPage.page_data.sub_title ? productPage.page_data.sub_title : ''
                : ''
            : ''
        let bannerSub = productPage ?
          productPage.page_data ?
            productPage.page_data.short_desc ? productPage.page_data.short_desc : ''
                : ''
            : ''
        let bannerImagePath = productPage ?
          productPage.images ?
            productPage.images.list ?
              productPage.images.list[0] ?
                productPage.images.list[0].path + productPage.images.list[0].img_name : null
                    : null
                : null
            : null;

        let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

        let posts = productPage ?
          productPage.posts ?
            productPage.posts.list ?
              productPage.posts.list.length ? productPage.posts.list : []
                    : []
                : []
            : [];


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <Menu />
                <InnerBanner
                    subTitle={bannerTitle}
                    image={banner ? banner : ''}
                />
                <ProductSpecification posts={posts}/>
                <EventPublication/>
                <ParallelPages
                  backgroundColor={'#2E3744'}
                  titleColor={'#fff'}
                  titleText={'parallel pages'}
                  color={'#69788D'}
                  hoverColor={'#fff'}
                  page={'products'}
                />
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        productPage: state.commonReducer.productsInner,
        sectionId: state.menuReducer.sectionId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProductPageData: params => dispatch(fetchProductPageData(params)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Specification));
