import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../../components/Title';
import Links from '../../../components/Link';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import InfoWindowEx from "./InfoWindowEx";
import mapStyle from '../../../components/MapStyle';
import {MAP_API_KEY} from '../../../config/index'

// img
import leftBg from '../../../assets/images/sustain-bg.svg';
import rightArrow from '../../../assets/images/btn-arrow-black.svg';
import pointer from '../../../assets/images/pointer.svg';
import ReactHtmlParser from 'react-html-parser';

const MapData = [{
    'id':1,
    'lat':23.79275609274159,
    'lng':90.42427235434118,
    'info': '<div class="map-info"><h4>Palmal group</h4> <p>Confidence Center 6A, Kha-9, Progoti Sharani, Shahjadpur, Gulshan, Building No: 1, Dhaka :, ঢাকা, 1212</p></div>'
}];

export class HomeMap extends Component{
    constructor(props) {
        super(props)
        this.state = {
            currLatt: 0,
            currLng: 0,
            showHelpLink: false,
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},
            windowWidth: 0,
            windowHeight: 0,
            markerIcon: [
                pointer,
            ],
            selectedMarkers: '',
            clickedID: false,
            connectionId: ''
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                clickedID: false
            });
        }
    };

    render() {
        let zoom = 14
        let center = {'lat':23.79275609274159,'lng':90.42427235434118};

        return (
          <StyledMap>
              <Container fluid>
                  <Row className='row-eq-height'>
                      <Col sm={12} className="map-left p-0">
                          <Map
                            google={this.props.google}
                            zoom={zoom}
                            initialCenter={center}
                            onClick={this.onClose}
                            mapTypeControl={false}
                            styles={mapStyle}
                            fullscreenControl={false}
                          >
                              {MapData.map((data, idx) => {
                                  let pos = {'lat': data.lat, 'lng': data.lng}
                                  return (
                                    <Marker
                                      key={idx}
                                      position={pos}
                                      title={ReactHtmlParser(data.info)}
                                      formatted_address={data.id}
                                      name={ReactHtmlParser(data.info)}
                                      icon={{
                                          url: `${pointer}`
                                      }}
                                      onClick={this.onMarkerClick}
                                    />
                                  )
                              })}
                              <InfoWindowEx
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}
                                onClick={this.onClose}
                              >
                                  <div className="info-window">
                                      <div>{this.state.selectedPlace.name}</div>
                                  </div>
                              </InfoWindowEx>
                          </Map>
                      </Col>


                  </Row>
              </Container>
          </StyledMap>
        )
    }

}

const StyledMap = styled.div`
  padding-bottom: 200px;
  background-color: #F3F3F3;
     .map-left{
        position: relative;
        height: 600px;
       >div{
          height: 100% !important;
          position: absolute;
          width: 100%;
        }
     }
     .map-right{
        background-color:#D6D6D6;
        position: relative;
        padding-left: 110px;
      >img{
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      &__content{
        padding-right: 55px;
        position: relative;
        z-index: 2;
        margin-bottom: 40px;
        padding-bottom: 20px;
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
        &:after{
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          background-color: rgba(255,255,255,0.50);
          right: 55px;
          left: 0;
        }
        h4{
          font-size: 20px;
          font-weight: bold;
          margin: 0;
          text-transform: capitalize;
          line-height: 30px;
        }
        a:hover{
          letter-spacing: 0;
        }
        
      }
     }
     .map-left{
      position: relative;
        >img{
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
        }
     }
     
     @media(max-width:768px){
      .map-left{
        min-width: 100%;
        max-height: 400px;
        height: 400px;
      }
      .map-right{
        min-width: 100%;
        padding-left: 15px;
        &__content{
          padding-right: 0;
          &:after{
            right: 0;
          }
        }
        .title{
          margin-bottom: 40px;
        }
      }
     }
     
     @media(max-width:767px){
      padding-bottom: 0;
     }
`;

export default GoogleApiWrapper({
    apiKey: MAP_API_KEY
})(HomeMap);
