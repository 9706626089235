import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import {baseUrl} from '../../../components/BaseUrl';
import { fetchHomeCertificationData } from "../../../redux/actions/home";
import { ApiParams } from "../../../constants/apiParams";
import { connect } from 'react-redux'

class Certifications extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-certifications'
        };
        this.props.getHomeCertificationData(params)
    }

    render() {
        let { homeCertifications } = this.props;
        const certificationList = homeCertifications ?
          homeCertifications.images ? homeCertifications.images.list : []
        : [];


        let len = certificationList.length;
        let sliderParams = {
            slidesPerView: 3,
            spaceBetween: 20,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            breakpoints:{
                769: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                },
            }
        };

        return (
          <StyledCertification className="pt-110 pb-110">
              <Container>
                  <Row>
                      <Col sm={12}>
                          <SubTitle margin={'0 0 40px 0'} color={'#58585A'} text={'Certifications'}></SubTitle>
                          {len && len === certificationList.length ? (
                            <Swiper  {...sliderParams}>
                                {certificationList.map(data=>(
                                  <div key={data.id} className="client-single">
                                      <div className="client-single__inner">
                                          <img src={baseUrl + data.path + data.img_name} alt=""/>
                                      </div>
                                  </div>
                                ))}
                            </Swiper>
                          ) : null}
                      </Col>
                  </Row>
              </Container>
          </StyledCertification>
        )
    }
};

const StyledCertification = styled.div`
 
    .client-single{
      &__inner{
        padding-top: 100%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          bottom:0;
          object-fit: contain;
          max-width:80%;
        }
      }
    }
    
    @media(min-width:769px){
      .swiper-pagination{
        display: none !important;
      }
    }
    
     @media(max-width:768px){
     .swiper-pagination{
        display: block !important;
      }
      .swiper-container{
        padding-bottom: 45px;
      }
      .swiper-pagination{
        bottom: 0px;
        .swiper-pagination-bullet{
            height: 5px;
            width: 5px;
            background-color: #000;
            position: relative;
            margin-right: 20px;
            opacity: 1;
          &:after{
            content: '';
            height: 17px;
            width: 17px;
            border-radius: 50%;
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            left: -6px;
            top: -6px;
            opacity: 0;
            z-index: -1;
          }
          &.swiper-pagination-bullet-active{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      .sub-title{
        text-align: center;
       
      }
      
    }
`;

function mapStateToProps(state) {
    return {
        homeCertifications: state.homeReducer.homeCertifications
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeCertificationData: (params) => dispatch(fetchHomeCertificationData(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Certifications);
