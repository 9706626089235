import React, {Component, useEffect, useLayoutEffect} from 'react';
import InnerTextBanner from '../../components/InnerTextBanner';
import Description from './components/Description';
import Menu from "../../components/Menu";
import logoBlack from '../../assets/images/logo-black.svg';
import hamburderBlack from '../../assets/images/hamburger-black.svg';
import {connect} from 'react-redux'
import {fetchMediaDetailsData} from "../../redux/actions/media";
import {ApiParams} from "../../constants/apiParams";
import {withRouter} from 'react-router-dom'
import {baseUrl} from "../../components/BaseUrl";
import InnerBanner from "../../components/InnerBanner";
import {motion} from "framer-motion";
import EventPublication from "../../components/EventPublication";
import ParallelPages from "../../components/ParallelPages";

class NewsDetail extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let params = {
      [ApiParams.page_slug]: this.props.match.params.slug
    }
    this.props.getMediaDetailsData(params)
  }
  render() {
    let { mediaPageDetails } = this.props;
    let posts = mediaPageDetails ? mediaPageDetails.posts ? mediaPageDetails.posts.list : [] : []

    let activePost = posts ? posts.find(post => post.data.slug === this.props.match.params.post_slug) : null

    let postData = activePost ? activePost.data : null
    let postImage = activePost ?
      activePost.images ?
        activePost.images[0] ?  baseUrl + activePost.images[0].path + activePost.images[0].img_name : ''
        :''
      : ''

    return(
      <div>
        <Menu/>
        {postData ? (
          <>
            {/*<InnerTextBanner*/}
            {/*  title={postData.title}*/}
            {/*  subTitle={'News'}*/}
            {/*  link={'#'}*/}
            {/*  linkText={'08 Feb 2020'}*/}
            {/*/>*/}
            <InnerBanner
                subTitle={'News'}
                image={postImage}
            />
            <Description aboutImg={''} postData={postData} />
          </>
        ) : null}
        <EventPublication/>
        <ParallelPages
            backgroundColor={'#2E3744'}
            titleColor={'#fff'}
            titleText={'parallel pages'}
            color={'#69788D'}
            hoverColor={'#fff'}
            page={'products'}
        />
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    mediaPageDetails: state.mediaReducer.mediaPageDetails
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMediaDetailsData: params => dispatch(fetchMediaDetailsData(params))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsDetail));
