import {all} from 'redux-saga/effects'
import homeSagas from './home'
import divisionSagas from "./division";
import footerSagas from "./footer";
import aboutSagas from "./about";
import serviceSaga from "./services";
import careerSaga from "./career";
import mediaSaga from "./media";
import formSaga from './form'
import contactSaga from './contact'
import menuSaga from "./menu";
import commonSaga from "./common";
import publicationSaga from "./event-publication";
import quantumSaga from "./quantum";

function* rootSaga() {
  yield all([
    homeSagas(),
    divisionSagas(),
    footerSagas(),
    aboutSagas(),
    serviceSaga(),
    careerSaga(),
    mediaSaga(),
    formSaga(),
    contactSaga(),
    menuSaga(),
    commonSaga(),
    publicationSaga(),
    quantumSaga()
  ])
}

export default rootSaga
