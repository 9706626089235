import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Filter from './components/Filter';
import AllServices from './components/Services';
import Menu from "../../components/Menu";
import BannerImg from "../../assets/images/dynamic/inner-banner.jpg";
import {connect} from 'react-redux'
import {fetchServicesPageData} from "../../redux/actions/services";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.page_type]: 'services'
        }

        if (!this.props.servicePage.length) {
            this.props.getServicePageData(params)
        }
    }

    render() {
        let {servicePage} = this.props
        let pageData = servicePage ? servicePage.page_data : null
        let pageTitle = pageData ? pageData.short_desc : ''
        let pageSub = pageData ? pageData.sub_title : ''
        let pageText = pageData ? pageData.description : ''

        let bannerImagePath = servicePage ?
            servicePage.images ?
                servicePage.images.list ?
                    servicePage.images.list[0] ?
                        servicePage.images.list[0].path + servicePage.images.list[0].img_name : null
                    : null
                : null
            : null

        let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

        let serviceList = servicePage ? servicePage.posts ? servicePage.posts.list : [] : []

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <Menu/>
                <InnerBanner
                    title={pageTitle}
                    subTitle={pageSub}
                    text={pageText}
                    image={banner}
                />
                {pageData ?
                    <>
                        <Filter services={serviceList}/>
                        <AllServices services={serviceList}/>
                    </> : null}
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        servicePage: state.serviceReducer.servicePage,
        sectionId: state.menuReducer.sectionId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getServicePageData: params => dispatch(fetchServicesPageData(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
