import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import {hover} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";

import "swiper/swiper.scss";

import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";

import {baseUrl} from "../../../components/BaseUrl";
import $ from "jquery";
import HistorySlider from "./HistorySlider";

const History = ({historyMain}) => {
  let historyData = historyMain ? historyMain.page_data ? historyMain.page_data : null : null
  let historyTitle = historyData ? historyData.sub_title : ''

  let historyPostData = historyMain ?
    historyMain.posts ? historyMain.posts.list : []
    : []

  let [active, setActive] = useState(0)

  let handleSlider = id => {
    setActive(id)
  }

  let len = historyPostData.length

  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 1500,
    freeMode: false,
    breakpoints: {
      769: {
        slidesPerView: 5,
        spaceBetween: 30,
        freeMode: true,
      },
    }
  };

  useEffect(() => {
    let getOffset = $('.container').offset().left + 15;
    if ($(window).width() > 768) {
      $('.history-slider .swiper-container').css({'padding-left': getOffset });
    }
  });

  let deskImage = historyMain ?
    historyMain.images ?
      historyMain.images.list ?
       baseUrl + historyMain.images.list[0].path + historyMain.images.list[0].img_name : ''
      : ''
    : ''

  return (
    <StyledHistory deskImage={deskImage} className="pt-110 pb-110">
      <Container className='history-title'>
        <Title
          lineHeight={'50'}
          margin={'0 0 40px 0'}
          text={historyTitle}
          color={'#fff'}
        />
        <Text>
          <p>
            {historyData ? ReactHtmlParser(historyData.short_desc) : ''}
          </p>
        </Text>
      </Container>

      <Container fluid className="history-slider">
        {len && len === historyPostData.length ? (
          <Swiper {...params}>
            {historyPostData.map((history, key) => {
              let historyData = history.data
              return (
                <div
                  key={history.data.id}
                  className="history-slider__single"
                  onClick={() => handleSlider(key)}
                >
                  <div className="history-slider__single__content">
                    <SubTitle
                      text={historyData.title}
                      fontWeight={'bold'}
                      letterSpacing={'0'}
                      lineHeight={'30'}
                      color={'#fff'}
                      fontSize={'30'}
                      margin={'90px 0 40px 0'}
                    />
                  </div>
                </div>
              )
            })}
          </Swiper>
        ) : ''}
        <Container className="history-body">
          <Row>
            <Col md={6}>
              <Text margin={'40px 0 0 0'} color={'#ffffff'}>
                <p>
                  {ReactHtmlParser(historyPostData[active] ? historyPostData[active].data.description : '')}
                </p>
              </Text>
            </Col>
          </Row>
        </Container>
      </Container>
    </StyledHistory>
  )
};


const StyledHistory = styled.div`
  position: relative;
  background-image: url(${props => props.deskImage || ''});

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
  }
  
  .history-title {
    position: relative;
    z-index: 1;
  }
  
  .history-body {
    position: relative;
    z-index: 1;
  }
  
  .history-slider{
    &__single{
      &__content{
        cursor: pointer;
        a{
          h2{
            transition:color .4s ease;
          }
          &:hover{
            h2{
              color: ${hover} !important;
            }

          }
        }
      }

      &__img{
        padding-top: 67.57%;
        position: relative;
        overflow: hidden;
        margin-top: 40px;
        a{
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }
        img{
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition:all 2s ease;

        }
        &:hover{
          img{
            transform: scale(1.05);
            filter:contrast(140%);
          }
        }
      }
    }

    &__bottom{
      margin-top: -50px;
    }
  }
  
  .swiper-button-next{
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: auto;
    left: auto;
    &:focus{
      outline:none;
    }
  }
  .swiper-button-prev{
    position: absolute;
    right: 60px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: auto;
    left: auto;
    background-image: url(${LeftIcon});
    background-size: contain;
    &:focus{
      outline:none;
    }
  }


  @media(max-width:991px){
    .history-title{
      padding-left: 15px;
    }
  }


  @media(max-width:767px){
    .history-slider__single__content{
      .sub-title{
        margin-top: 30px;
      }
    }
    .swiper-container{
      padding-left: 15px;
      padding-right: 15px;
      .title{
        font-size: 20px;
        margin-bottom: 0px;
      }
      .history-slider__single__img{
        margin-top: 15px;
      }
      p{
        margin-top: 20px;
        font-weight: 300;
      }
    }
  }
`;

export default History;

