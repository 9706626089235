import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import AboutImg from '../../../assets/images/dynamic/about-detail.jpg';

import imgs from '../../../assets/images/dynamic/acheivement.jpg'
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";
import EventPublication from "../../../components/EventPublication";
import ParallelPages from "../../../components/ParallelPages";
import {motion} from "framer-motion";

const Description = ({footerOffset, aboutImg, postData}) => {
    return (
        <StyledDescription offset={footerOffset}>
            <Container>
                <div className="description-content">
                    {/*<div className="description__img">*/}
                    {/*    <img src={aboutImg} alt=""/>*/}
                    {/*</div>*/}

                    <div className="description__wrap pt-110 pb-110">
                        <div className="description__wrap__about">
                            {ReactHtmlParser(postData.description)}
                            {/*<p>We specialized on Polo shirts, T-shirts, Sweat Shirt, Jacket, Basic Shirt, Trousers,*/}
                            {/*    Boxer, panty etc. The factories are designed with fully modern machineries to meet the*/}
                            {/*    world market demand. We strictly maintain safety, health, hygienic systems and all other*/}
                            {/*    labor issues as per ILO & BGMEA and our factory is 100% socially compliant. The mission*/}
                            {/*    of the company committed and skilled team of production and fully pledged commercial,*/}
                            {/*    marketing, merchandising, sourcing and office management. To provide quality garments at*/}
                            {/*    a reasonable cost within definite time for the satisfaction for our customer through*/}
                            {/*    professional excellence by skilled management.</p>*/}

                            {/*<p>Azmeri Composite Knit Ltd has expanded its capacity, developed a skilled management team,*/}
                            {/*    workforce, and integrated sophisticated technologies in order to become globally*/}
                            {/*    competitive.</p>*/}

                            {/*<img src={imgs} alt=""/>*/}

                            {/*<p>We specialized on Polo shirts, T-shirts, Sweat Shirt, Jacket, Basic Shirt, Trousers, Boxer,*/}
                            {/*    panty etc. The factories are designed with fully modern machineries to meet the world market*/}
                            {/*    demand. We strictly maintain safety, health, hygienic systems and all other labor issues as*/}
                            {/*    per ILO & BGMEA and our factory is 100% socially compliant. The mission of the company*/}
                            {/*    committed and skilled team of production and fully pledged commercial, marketing,*/}
                            {/*    merchandising, sourcing and office management. To provide quality garments at a reasonable*/}
                            {/*    cost within definite time for the satisfaction for our customer through professional*/}
                            {/*    excellence by skilled management.</p>*/}

                            {/*<p>Azmeri Composite Knit Ltd has expanded its capacity, developed a skilled management team,*/}
                            {/*    workforce, and integrated sophisticated technologies in order to become globally*/}
                            {/*    competitive.</p>*/}

                            {/*<p>Moreover, having endless efforts to ensure internationally accepted employment practice, our*/}
                            {/*    clients recognize us as a partner to protect their value system and images among final*/}
                            {/*    customers.</p>*/}
                        </div>


                    </div>
                </div>
            </Container>
        </StyledDescription>
    )
};

const StyledDescription = styled.div`
  background: #2E3744;
  .description-content{
    background-color: #f9f9f9;
    .description__img{
      height: 600px;
      position: relative;
      img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
       }
    }
  }
  .description__wrap{
     background: #2E3744;
     //width: calc(100% - ${props=> props.offset +15}px);
     //margin-left: ${props=> props.offset +15 || 200}px;
    //padding: 100px 0 100px 0;
     //margin-top: -200px;
     position: relative;
    color: #fff;
     z-index: 2;
    img {
      width: 100%;
      object-fit: contain;
      position: relative;
      height: auto;
      margin-bottom: 40px;
      margin-top: 20px;
    }
    p{
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 20px;
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
    }
  }
  
  @media(max-width:1080px){
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
       .description__wrap{
       overflow: hidden;
       // width: calc(100% - 15px);
       // margin-left: 15px;
      }
  }
 
  
  @media(max-width:767px){
    
    .container {
      padding-right: 0;
      padding-left: 0;
    }
       
      .description__wrap{
        padding: 80px 15px;
        img{
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
      .description__img {
        height: 500px;
        position: relative;
      }
      p{
        font-size: 16px;
        line-height: 24px;
      }
      .description__wrap__at-a-glance{
        margin-top: 60px;
        padding-top: 60px;
        table tr td{
         padding-top: 15px;
         padding-bottom: 15px;
         font-size: 16px !important;
         line-height: 21px !important;
        }
      }
      
  }
 
`;

let mapStateToProps = state => {
    return {
        footerOffset: state.footerReducer.footerOffset
    }
};

export default connect(mapStateToProps) (Description);
