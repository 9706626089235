import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import {connect} from 'react-redux';
import $ from 'jquery';


import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";

const AllServices = props => {
  let { services, sectionId } = props
  useEffect(() => {
    if(sectionId) {
      let elem = document.getElementById(sectionId)
      if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
      let getOffset = $('.container').offset().left + 30;
      if ($(window).width() > 769) {
        $('.services-single:nth-of-type(even) .services-single__right').css({'padding-left': getOffset})
        $('.services-single:nth-of-type(odd) .services-single__right').css({'padding-right': getOffset})
      }
    }
  },[sectionId])

  return (
    <StyledServices>
      <Container fluid className='p-0'>
        {services.length ? services.map((service) => {
          let serviceData = service.data
          let serviceImage = service.images ? service.images[0] : null

          let imagePath = serviceImage ? baseUrl + serviceImage.path + serviceImage.img_name : ''

          return (
            <Row id={serviceData.slug} className="services-single">
              <Col md={6}>
                <div className="services-single__left">
                  <img src={imagePath} alt=""/>
                </div>
              </Col>

              <Col md={6}>
                <div className="services-single__right">
                  <SubTitle text={serviceData.title} margin={'0 0 40px 0'}/>
                  <Text>
                    <p>{ReactHtmlParser(serviceData.description)}</p>
                  </Text>
                </div>
              </Col>
            </Row>
          )
        }) : null}
      </Container>
    </StyledServices>
  )
};

const StyledServices = styled.div`
    .services-single{
    background-color: #F9F9F9;
     &:nth-of-type(even){
          flex-direction: row-reverse;
        }
      .col-md-6{
        padding: 0;
      }
      .services-single__left{
        padding-top: 87.85%;
        position: relative;
        &:after{
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.5);
        position: absolute;
       }
        img{
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .services-single__right{
        padding-top: 100px;
        padding-left: 85px;
        padding-right: 85px;
        height: 100%;
      }
    }
    @media(max-width:991px){
      .services-single__right{
        padding-top: 10px !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title{
          margin-bottom: 20px;
        }
      }
      .col-md-6{
         &:nth-of-type(even) .services-single__right{
          padding-left: 30px !important;
        }
         &:nth-of-type(odd) .services-single__right{
          padding-right: 30px !important;
        }
      }
   
    }
    @media(max-width:767px){
      .services-single{
      padding-bottom: 50px;
       .col-md-6:nth-last-of-type(1){
        padding:0 15px;
      }
      &:nth-of-type(even){
        display: flex;
        //flex-direction: column-reverse;
      }
         .col-md-6 .services-single__right{
            padding-left: 15px !important;
            padding-right: 15px !important;
            
            .sub-title{
               margin-top:10px;
               margin-bottom: 10px;
            }
          }
      }
    }
`;

function mapStateToProps(state) {
  return {
    sectionId: state.menuReducer.sectionId
  }
}

export default connect(mapStateToProps)(AllServices);
