import {PUBLICATION} from "../../constants/event-publication";

export function fetchEventPublications(params) {
  return {
    type: PUBLICATION.FETCH_EVENT_PUBLICATIONS.MAIN,
    params
  }
}

export function fetchEventPublicationPage(params) {
  return {
    type: PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.MAIN,
    params
  }
}
