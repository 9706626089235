import {QUANTUM} from "../../constants/quantum";

let initialState = {
  quantumPage: [],
  quantumPageLoading: false,
  quantumPageErr: '',
  tablePageOne: [],
  tablePageOneLoading: false,
  tablePageOneErr: '',
  tablePageTwo: [],
  tablePageTwoLoading: false,
  tablePageTwoErr: ''
}

export default function quantumReducer(state=initialState, action) {
  switch(action.type) {
    case QUANTUM.FETCH_QUANTUM_PAGE.MAIN:
      return {
        ...state,
        quantumPage: [],
        quantumPageLoading: true,
        quantumPageErr: ''
      }

    case QUANTUM.FETCH_QUANTUM_PAGE.SUCCESS:
      return {
        ...state,
        quantumPage: action.result,
        quantumPageLoading: false,
        quantumPageErr: ''
      }

    case QUANTUM.FETCH_QUANTUM_PAGE.FAILURE:
      return {
        ...state,
        quantumPage: [],
        quantumPageLoading: false,
        quantumPageErr: 'Error from quantum page data'
      }

    case QUANTUM.FETCH_TABLE_SECTION_ONE.MAIN:
      return {
        ...state,
        tablePageOne: [],
        tablePageOneLoading: true,
        tablePageOneErr: ''
      }

    case QUANTUM.FETCH_TABLE_SECTION_ONE.SUCCESS:
      return {
        ...state,
        tablePageOne: action.result,
        tablePageOneLoading: false,
        tablePageOneErr: ''
      }

    case QUANTUM.FETCH_TABLE_SECTION_ONE.FAILURE:
      return {
        ...state,
        tablePageOne: [],
        tablePageOneLoading: false,
        tablePageOneErr: 'Error from Table one loading'
      }

    case QUANTUM.FETCH_TABLE_SECTION_TWO.MAIN:
      return {
        ...state,
        tablePageTwo: [],
        tablePageTwoLoading: true,
        tablePageTwoErr: ''
      }


    case QUANTUM.FETCH_TABLE_SECTION_TWO.SUCCESS:
      return {
        ...state,
        tablePageTwo: action.result,
        tablePageTwoLoading: false,
        tablePageTwoErr: ''
      }

    case QUANTUM.FETCH_TABLE_SECTION_TWO.FAILURE:
      return {
        ...state,
        tablePageTwo: [],
        tablePageTwoLoading: false,
        tablePageTwoErr: 'Error from Table Page loading'
      }

      default:
        return state
  }
}
