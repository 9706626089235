import React, {useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import ReactHtmlParser from "react-html-parser";
import Button from '../../../components/Button'
import Modal from "../../../components/Modal";

const AboutUs = ({ posts, sectionId }) => {

    let [openModal, setOpenModal] = useState(false)
    let [title, setTitle] = useState('')
    let [description, setDescription] = useState('')

    let postData = posts ? posts.find(post => post.data.slug === 'about-section') : null
    let postMain = postData ? postData.data ? postData.data : null : null

    let handleModal = () => {
        setOpenModal(!openModal)
    }

    let handlePost = (title, description) => {
        setTitle(title)
        setDescription(description)
        handleModal()
    }

    return (
        <StyledAbout  id="who-we-are" className="pt-110 pb-110">
            <Container>
                <Modal
                    show={openModal}
                    handleClose={handleModal}
                    post={description}
                    title={title}
                />
                <Row>
                    <Col md={5}>
                        <Title
                            color={'#FFF'}
                            fontSize={'56'}
                            fontWeight={'500'}
                            margin={'0 0 12px 0'}
                            lineHeight={'58'}
                            text={postMain ? postMain.title : ''}
                        />
                        <SubTitle
                            color={'#FFF'}
                            letterSpacing={'0'}
                            fontSize={'16'}
                            fontWeight={'300'}
                            text={postMain ? postMain["Extra description one"] : ''}
                            textTransform={'lowercase'}
                            margin={'0 0 40px 0'}
                        />
                        <Text
                            color={'#FFF'}
                            fontWeight={'300'}
                            fontSize={'18'}
                            line={9}
                            margin={'0 0 32px 0'}
                        >
                            <p>{postMain ? ReactHtmlParser(postMain.description) : ''}</p>
                        </Text>

                        <div onClick={() => handlePost(postMain ? postMain.title : '', postMain ? postMain.description : '')}>
                            <Button text={'Explore'} color={'#fff'} fontSize={20}/>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <SubTitle
                                color={'#FFF'}
                                letterSpacing={'0'}
                                fontSize={'16'}
                                fontWeight={'300'}
                                fontWeight={'normal'}
                                text={''}
                                textTransform={'lowercase'}
                                margin={'0 0 40px 0'}
                            />
                        </div>
                    </Col>
                    <Col md={6} className="d-flex">
                        <StyledBlock>
                            <div className="box">
                                <div className="content">
                                    <h1>G</h1>
                                    <p>GOD FEARING</p>
                                </div>
                            </div>
                        </StyledBlock>

                        <StyledBlock>
                            <div className="box">
                                <div className="content">
                                    <h1>P</h1>
                                    <p>PLAIN LIVING</p>
                                </div>
                            </div>
                        </StyledBlock>

                        <StyledBlock>
                            <div className="box">
                                <div className="content">
                                    <h1>H</h1>
                                    <p>HIGH THINKING</p>
                                </div>
                            </div>
                        </StyledBlock>
                    </Col>
                </Row>
            </Container>

        </StyledAbout>

    )
};

const StyledBlock = styled.div`
  color: white;
  width: calc(50% - 25px);
  margin-bottom: 50px;
  &:nth-last-of-type(1) {
    margin: 0 auto;
  }

  .box {
    padding-top: 100%;
    border: 2px solid #fff;
    position: relative;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    font-size: 54px;
    font-weight: normal;
    color: white;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 48px;
      font-weight: normal;
      color: white;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: white;
    }
  }
`

const StyledAbout = styled.div`
  .d-flex {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  @media (max-width: 991px) {
    &.pb-110 {
      padding-bottom: 160px;
    }

    .col-md-7 {
      min-width: 100%;
    }

    .title {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }
  }
`;



export default AboutUs;
