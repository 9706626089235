import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import SubText from '../../../components/SubText';
import Links from '../../../components/Link';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import Slider from "react-slick";
// img
import navLeft from '../../../assets/images/navLeftBlack.svg';
import navRight from '../../../assets/images/navRightBlack.svg';
import aw1 from "../../../assets/images/dynamic/blur.jpg";
import bg from "../../../assets/images/achievements.jpg";
import {baseUrl} from "../../../components/BaseUrl";

const Achievements = ({posts}) => {
    let [active, setActive] = useState(0)
    let directorsPost = posts ? posts.filter(post => post.data.slug === 'directors') : []

    let handleActiveTab = value => {
        setActive(value)
    }

    const settings = {
        className: "slider variable-width tabSlider",
        dots: false,
        infinite: false,
        centerMode: false,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        variableWidth: true,
        arrows: true
    };

    let len = directorsPost[active] ?
        directorsPost[active].images ? directorsPost[active].images.length : 0
        : 0

    return (
        <StyledAchievements className="pt-110 pb-110">
            <Container className="title-here">
                <div className="borders">
                    <SubTitle text={'Board of Directors'} fontSize={54} margin={'32px 0 21px 0'} color={'#474747'}/>
                </div>
                {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
                <Slider {...settings}>
                        {directorsPost.length ? directorsPost.map((item, key) => {
                            return (
                                    <SubText
                                        text={item.data.title}
                                        fontSize={24}
                                        lineHeight={24}
                                        margin={'0 0 48px 0'}
                                        color={key === active ? '#E8434F' : '#7B7B7B'}
                                        letterSpacing={1}
                                        onClick={() => handleActiveTab(key)}
                                    />
                            )
                        }) : ''}
                </Slider>

                {/*</div>*/}
            </Container>

            <Container className="achievement-slider">
                {len ?
                    directorsPost[active].images ?
                        directorsPost[active].images.length === len ? (
                            <Row>
                                {directorsPost[active].images.map(image => {
                                    let imagePath = baseUrl + image.path + image.img_name
                                    return (
                                        <Col md={4}>
                                            <div key={image.id} className="achievement-slider__single">
                                                <div className="achievement-slider__single__img">
                                                    <img src={imagePath} alt=""/>
                                                </div>
                                                <div className="text-name">
                                                    <h4>{image.short_title}</h4>
                                                    <p>{image.short_desc}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        ) : '' : '' : ''}
            </Container>
        </StyledAchievements>

    )
};


const StyledAchievements = styled.div`
    // background-image: url(${bg});
  // background-position: center;
  // background-repeat: no-repeat;
  // -webkit-background-size: cover;
  // background-size: cover;
  background-color: #fff;

  .title-here {
    .borders {
      border-top: 1px solid #B9B9B9;
      border-bottom: 1px solid #B9B9B9;
      margin: 0 0 24px 0;
      text-align: center;
    }

    .title {
      width: 60%;
    }
  }
  
  .tabSlider{
    .slick-arrow.slick-prev{
      top: 0;
      transform: translate(0, 0);
      
      &:before{
        color: #000;
      }
      &.slick-disabled{
        
      }
    }
    .slick-arrow.slick-next{
      top: 0;
      transform: translate(0, 0);

      &:before{
        color: #000;
      }
      &.slick-disabled{

      }
    }
    
    .slick-list{
      .slick-slide{
        padding-right: 30px;
        padding-left: 30px;
        border-right: 2px solid #7B7B7B;
        .sub-title{
          margin-bottom: 0;
        }
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }

  .achievement-slider {
    margin-top: 48px;
    .col-md-4 {
      margin-bottom: 10px;
    }

    .text-name {
      background-color: #00000090;
      position: absolute;
      left: 48px;
      bottom: 32px;
      text-align: left;
      width: calc(100% - 64px);
    }

    &__single {
      margin-bottom: 8px;

      h4 {
        margin-top: 8px;
        margin-left: 16px;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        color: #fff;
        margin-bottom: 0;
      }

      p {
        font-size: 12px;
        margin-left: 16px;
        font-weight: 300;
        line-height: 21px;
        color: #FFFFFF;
        margin-bottom: 8px;
      }

      &__img {
        padding-top: 111.11%;
        position: relative;
        overflow: hidden;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  @media (min-width: 768px) {
    .swiper-pagination-bullet {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .swiper-wrapper .sub-title {
      margin-bottom: 10px;
    }

    .borders .sub-title {
      margin: 16px 0 10px 0 !important;
    }

    .title-here .sub-title {
      margin-bottom: 60px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }

    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
  }

  .swiper-pagination {
    bottom: 0px;

    .swiper-pagination-bullet {
      height: 5px;
      width: 5px;
      background-color: #7B7B7B;
      position: relative;
      margin-right: 20px;
      opacity: 1;

      &:after {
        content: '';
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background-color: #E2E2E2;
        position: absolute;
        left: -6px;
        top: -6px;
        opacity: 0;
        z-index: -1;
      }

      &.swiper-pagination-bullet-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;


export default Achievements;
