import React, {useState} from 'react';
import {PageAnimation} from "../../../components/PageAnimation";
import {motion} from "framer-motion";
import styled from "styled-components";
import {Container, Table} from 'react-bootstrap';
import SubTitle from "../../../components/SubTitle";
import ReactHtmlParser from "react-html-parser";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import mask from "../../../assets/images/Mask Group 17.svg"

let CorporateInfo = props => {
    let { posts } = props;

    let [openModal, setOpenModal] = useState(false)
    let [title, setTitle] = useState('')
    let [description, setDescription] = useState('')

    let infoTable = posts ? posts.find(item => item.data.slug === 'corporate-information') : null

    let handleModal = () => {
        setOpenModal(!openModal)
    }

    let handlePost = (title, description) => {
        setTitle(title)
        setDescription(description)
        handleModal()
    }

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        {infoTable ? (
          <StyledDivision>
              <Modal
                  show={openModal}
                  handleClose={handleModal}
                  post={description}
                  title={title}
              />
              <img src={mask}/>
           <Container className="pt-110 pb-110">
               <div className="division-content__wrap" >
                   <div className="division-content__wrap__at-a-glance">
                       <SubTitle
                           text={infoTable.data.title}
                           margin={'0 0 70px 0'}
                           color='#fff'
                           fontSize={42}
                           lineHeight={30}
                       />
                       <Table responsive hover variant="dark">
                           {
                               infoTable.data.description ? ReactHtmlParser(infoTable.data.description)
                                   : null}
                       </Table>
                   </div>
                   <div className="button-explore" onClick={() => handlePost(infoTable ? infoTable.data.title : '', infoTable ? infoTable.data.description : '')}>
                       <Button text={'Explore'} fontSize={20}/>
                   </div>
               </div>
           </Container>
          </StyledDivision>
        ) : null}
      </motion.div>
    )
};

const StyledDivision = styled.div`
  background-color: #262E39;

  img {
    position: absolute;
    right: 0;
  }

  .division-content__wrap {
    position: relative;
    z-index: 2;

    .button-explore {
      position: absolute;
      right: 0px;
      padding-top: 25px;
    }

    &__at-a-glance {
      position: relative;
      //&:before{
      //  content: '';
      //  position: absolute;
      //  height: 1px;
      //  left: -100px;
      //  right: -100px;
      //  background-color: #DFE6E5;
      //  top: 0;
      //}
      .table-dark {
        background-color: rgba(38, 46, 57, 0);
      }

      table {
        tr {
          border-color: #FAFAFA;

          &:nth-of-type(odd) {
            background-color: rgba(171, 171, 171, 0.13);
          }

          &:nth-of-type(even) {
            background-color: rgba(38, 46, 57, 0.13);
          }

          &:nth-last-of-type(1) {
            border-bottom: 1px solid #7d7d7d;
          }

          td {
            padding-left: 25px;
            padding-top: 25px;
            padding-bottom: 25px;
            font-size: 17px;
            font-weight: 100;
            line-height: 20px;
            color: #FAFAFA;
            border-top: 1px solid #262E39 !important;
            border-bottom: 1px solid #262E39 !important;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .division-content__wrap {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 767px) {
    .pt-110, .pt-150 {
      padding-top: 60px;
    }
    img {
      display: none;
    }
  }
`

export default CorporateInfo;
