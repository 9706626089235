import React from 'react';
import {PageAnimation} from "../../../components/PageAnimation";
import {motion} from "framer-motion";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {Container} from "react-bootstrap";

let ProductSpecification = props => {
    let { posts } = props;

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <StyledDivision>
             <Container className="pt-110 pb-110">
                 <div className="division-content__wrap" >
                     <div className="division-content__wrap__at-a-glance">
                         {posts.length ? posts.map(item => (
                             <div key={item.data.id} className={`${item.data.slug}`}>
                                 {ReactHtmlParser(item.data.description)}
                             </div>
                         )) : ''}
                         {/*<div className="table-two">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <thead>*/}
                         {/*    <tr>*/}
                         {/*      <th>Bar nominal diameter</th>*/}
                         {/*      <th>Nominal weight</th>*/}
                         {/*      <th>Cross-sectional area</th>*/}
                         {/*      <th>App. Lengths per</th>*/}
                         {/*      <th>App. Lengths per</th>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <th>Mm</th>*/}
                         {/*      <th>Kg/m</th>*/}
                         {/*      <th>mm2</th>*/}
                         {/*      <th>M.Ton / Metre</th>*/}
                         {/*      <th>M. Ton / Feet</th>*/}
                         {/*    </tr>*/}
                         {/*    </thead>*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>08</td>*/}
                         {/*      <td>0.395</td>*/}
                         {/*      <td>50.3</td>*/}
                         {/*      <td>2,532</td>*/}
                         {/*      <td>8,307</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>10</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>12</td>*/}
                         {/*      <td>0.395</td>*/}
                         {/*      <td>50.3</td>*/}
                         {/*      <td>2,532</td>*/}
                         {/*      <td>8,307</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>16</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>18</td>*/}
                         {/*      <td>0.395</td>*/}
                         {/*      <td>50.3</td>*/}
                         {/*      <td>2,532</td>*/}
                         {/*      <td>8,307</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>20</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>22</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>25</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>28</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>32</td>*/}
                         {/*      <td>0.616</td>*/}
                         {/*      <td>78.5</td>*/}
                         {/*      <td>1,621</td>*/}
                         {/*      <td>5,318</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}


                         {/*<div className="table-three">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <thead>*/}
                         {/*    <tr>*/}
                         {/*      <th>Bar Size</th>*/}
                         {/*      <th colSpan="3">P in Diameter for 1800 Bend Test</th>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <th>Mm</th>*/}
                         {/*      <th>GPH G 60-400</th>*/}
                         {/*      <th>GPH TMT 500W</th>*/}
                         {/*      <th>GPH G 40-300W</th>*/}
                         {/*    </tr>*/}
                         {/*    </thead>*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>8, 10,12, 14, 16</td>*/}
                         {/*      <td>3.5d</td>*/}
                         {/*      <td>3d</td>*/}
                         {/*      <td>3.5d</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>18, 20, 22,25</td>*/}
                         {/*      <td>5d</td>*/}
                         {/*      <td>4d</td>*/}
                         {/*      <td>5d</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>28, 32</td>*/}
                         {/*      <td>7d</td>*/}
                         {/*      <td>6d</td>*/}
                         {/*      <td>Not specified</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}


                         {/*<div className="table-four">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <thead>*/}
                         {/*    <tr>*/}
                         {/*      <th>All Size</th>*/}
                         {/*      <th>+Not specified</th>*/}
                         {/*    </tr>*/}
                         {/*    </thead>*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>.</td>*/}
                         {/*      <td>-6.0%</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}

                         {/*<div className="table-three">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <thead>*/}
                         {/*    <tr>*/}
                         {/*      <th>Name of Elements</th>*/}
                         {/*      <th colSpan="3">Grade-wise percent constituents</th>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <th>.</th>*/}
                         {/*      <th>GPH TMT 500W</th>*/}
                         {/*      <th>GPH G60-400</th>*/}
                         {/*      <th>GPH G40-300W</th>*/}
                         {/*    </tr>*/}
                         {/*    </thead>*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>Carbon(C)</td>*/}
                         {/*      <td>0.15-0.25</td>*/}
                         {/*      <td>0.20-0.33</td>*/}
                         {/*      <td>0.15-0.25</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Manganese (Mn)</td>*/}
                         {/*      <td>0.75-0.90</td>*/}
                         {/*      <td>1.00-1.65</td>*/}
                         {/*      <td>0.75-0.90</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Silicon (Si)</td>*/}
                         {/*      <td>0.25 Max</td>*/}
                         {/*      <td>0.25 Max</td>*/}
                         {/*      <td>0.25 Max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Sulfur(S)</td>*/}
                         {/*      <td>0.05Max</td>*/}
                         {/*      <td>0.05 Max</td>*/}
                         {/*      <td>0.05 Max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Phosphorus (P)</td>*/}
                         {/*      <td>0.04 Max</td>*/}
                         {/*      <td>0.05 Max</td>*/}
                         {/*      <td>0.04 Max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Copper(Cu)</td>*/}
                         {/*      <td>0.20 Max</td>*/}
                         {/*      <td>0.25 Max</td>*/}
                         {/*      <td>0.20 Max</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}

                         {/*<div className="table-three">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <thead>*/}
                         {/*    <tr>*/}
                         {/*      <th>AISI-SAE Number</th>*/}
                         {/*      <th>UNS Number</th>*/}
                         {/*      <th colSpan="4">Composition</th>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <th style={{backgroundColor: '#262E39'}} colSpan="2"></th>*/}
                         {/*      <th>C</th>*/}
                         {/*      <th>Mn</th>*/}
                         {/*      <th>P(max)**</th>*/}
                         {/*      <th>S(max)**</th>*/}
                         {/*    </tr>*/}
                         {/*    </thead>*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}

                         {/*<div className="table-three">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <thead>*/}
                         {/*    <tr>*/}
                         {/*      <th>AISI-SAE Number</th>*/}
                         {/*      <th>UNS Number</th>*/}
                         {/*      <th colSpan="4">Composition</th>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <th style={{backgroundColor: '#262E39'}} colSpan="2"></th>*/}
                         {/*      <th>C</th>*/}
                         {/*      <th>Mn</th>*/}
                         {/*      <th>P(max)**</th>*/}
                         {/*      <th>S(max)**</th>*/}
                         {/*    </tr>*/}
                         {/*    </thead>*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>1522</td>*/}
                         {/*      <td>G15220</td>*/}
                         {/*      <td>0.18–0.24</td>*/}
                         {/*      <td>1.10–1.40</td>*/}
                         {/*      <td>0.040</td>*/}
                         {/*      <td>0.050</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}

                         {/*<div className="table-five">*/}
                         {/*  <Table responsive hover variant="dark">*/}
                         {/*    <tbody>*/}
                         {/*    <tr>*/}
                         {/*      <td>Carbon Steels</td>*/}
                         {/*      <td>10XX</td>*/}
                         {/*      <td>Plain carbon, Mn 1.00% max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td style={{backgroundColor: '#00000000'}}></td>*/}
                         {/*      <td>10XX</td>*/}
                         {/*      <td>Plain carbon, Mn 1.00% max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Carbon Steels</td>*/}
                         {/*      <td>10XX</td>*/}
                         {/*      <td>Plain carbon, Mn 1.00% max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td style={{backgroundColor: '#00000000'}}></td>*/}
                         {/*      <td>10XX</td>*/}
                         {/*      <td>Plain carbon, Mn 1.00% max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td>Carbon Steels</td>*/}
                         {/*      <td>10XX</td>*/}
                         {/*      <td>Plain carbon, Mn 1.00% max</td>*/}
                         {/*    </tr>*/}
                         {/*    <tr>*/}
                         {/*      <td style={{backgroundColor: '#00000000'}}></td>*/}
                         {/*      <td>10XX</td>*/}
                         {/*      <td>Plain carbon, Mn 1.00% max</td>*/}
                         {/*    </tr>*/}
                         {/*    </tbody>*/}
                         {/*  </Table>*/}
                         {/*</div>*/}


                         {/*<div className="table-three">*/}
                         {/*    <Table responsive hover variant="dark">*/}
                         {/*        <thead>*/}
                         {/*        <tr>*/}
                         {/*            <th>Grades of Steel</th>*/}
                         {/*            <th>Yield Strength (min)</th>*/}
                         {/*            <th>Tensile Strength (min)</th>*/}
                         {/*            <th>Percent Elongation (min)</th>*/}
                         {/*            <th>UTS/YS</th>*/}
                         {/*        </tr>*/}
                         {/*        </thead>*/}
                         {/*        <tbody>*/}
                         {/*        <tr>*/}
                         {/*            <td>GPH TMT 500W</td>*/}
                         {/*            <td>72,500 psi</td>*/}
                         {/*            <td>mm2</td>*/}
                         {/*            <td>14%</td>*/}
                         {/*            <td>1.15</td>*/}
                         {/*        </tr>*/}
                         {/*        <tr>*/}
                         {/*            <td></td>*/}
                         {/*            <td>500 MPa</td>*/}
                         {/*            <td>575 MPa</td>*/}
                         {/*            <td></td>*/}
                         {/*            <td></td>*/}
                         {/*        </tr>*/}
                         {/*        <tr>*/}
                         {/*            <td>GPH G 60-400</td>*/}
                         {/*            <td>60,175 psi</td>*/}
                         {/*            <td>575 MPa</td>*/}
                         {/*            <td>14%</td>*/}
                         {/*            <td>1.15</td>*/}
                         {/*        </tr>*/}
                         {/*        <tr>*/}
                         {/*            <td></td>*/}
                         {/*            <td>415 MPa</td>*/}
                         {/*            <td>90,045 psi</td>*/}
                         {/*            <td></td>*/}
                         {/*            <td></td>*/}
                         {/*        </tr>*/}
                         {/*        <tr>*/}
                         {/*            <td>GPH G 40-300W</td>*/}
                         {/*            <td>43,500 psi</td>*/}
                         {/*            <td>60,175 psi</td>*/}
                         {/*            <td>16%</td>*/}
                         {/*            <td>1.15</td>*/}
                         {/*        </tr>*/}
                         {/*        <tr>*/}
                         {/*            <td></td>*/}
                         {/*            <td>300 MPa</td>*/}
                         {/*            <td>415 MPa</td>*/}
                         {/*            <td></td>*/}
                         {/*            <td></td>*/}
                         {/*        </tr>*/}
                         {/*        </tbody>*/}
                         {/*    </Table>*/}
                         {/*</div>*/}
                     </div>
                 </div>
             </Container>
            </StyledDivision>
        </motion.div>
    )
};

const StyledDivision = styled.div`
  background-color: #262E39;
  border-bottom: 1px solid rgba(95, 95, 95, 0.28);

  .division-content__wrap {
    position: relative;
    color: #fff;
    z-index: 2;

    .button-explore {
      position: absolute;
      right: 100px;
      padding-top: 25px;
    }

    &__at-a-glance {
      position: relative;

      ul {
        li {
          padding-top: 18px;
          padding-bottom: 24px;
          border-bottom: 1px solid #707070;
        }
      }

      .table-dark {
        background-color: #262E39;
      }

      .table-two {
        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;

              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1px solid #00000000 !important;
                border-right: 1rem solid #262E39;

                &:nth-last-of-type(1) {
                  border-right: 0px solid #262E39;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(even) {
              background-color: #2B333D;
            }

            &:nth-of-type(odd) {
              background-color: #262E39;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1px solid #00000000 !important;
              border-right: 1rem solid #262E39;

              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }
            }
          }
        }
      }

      .table-three {
        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;

              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #262E39 !important;
                border-right: 1rem solid #262E39;

                &:nth-last-of-type(1) {
                  border-right: 0px solid #262E39;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #2B333D;
            }

            &:nth-of-type(even) {
              background-color: #262E39;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #262E39 !important;
              border-right: 1rem solid #262E39;

              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }
            }
          }
        }
      }

      .table-four {
        width: 50%;

        table {
          thead {
            border-color: #00000000;

            tr {
              background-color: #00000060 !important;

              th {
                font-size: 17px;
                font-weight: 100;
                line-height: 20px;
                color: #FAFAFA;
                padding-left: 25px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #00000000 !important;
                border-bottom: 1rem solid #262E39 !important;
                border-right: 1rem solid #262E39;

                &:nth-last-of-type(1) {
                  border-right: 0px solid #262E39;
                }
              }
            }
          }

          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #2B333D;
            }

            &:nth-of-type(even) {
              background-color: #262E39;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #262E39 !important;
              border-right: 1rem solid #262E39;

              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }
            }
          }
        }
      }

      .table-five {
        table {
          tr {
            border-color: #FAFAFA;

            &:nth-of-type(odd) {
              background-color: #2B333D;
            }

            &:nth-of-type(even) {
              background-color: #262E39;
            }

            &:nth-last-of-type(1) {
              border-bottom: 1px solid #7d7d7d;
            }

            td {
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #00000000 !important;
              border-bottom: 1rem solid #262E39 !important;
              border-right: 1rem solid #262E39;

              &:nth-last-of-type(1) {
                border-right: 0px solid #262E39;
              }

              &:nth-of-type(1) {
                background-color: #00000060;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .division-content__wrap {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 480px) {
    padding: 10%;
  }
  @media (max-width: 365px) {
    padding: 6%;
  }
`

export default ProductSpecification;
