import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import BannerImg from "../../assets/images/dynamic/inner-banner.jpg";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchSustainablityPageData, fetchProductPageData} from "../../redux/actions/common";
import StyledGrid from "../../components/StyledGrid";
import {withRouter} from 'react-router-dom'
import styled from "styled-components";
import {Container} from 'react-bootstrap';
import { BACKGROUND_IMAGE } from '../../config'
import ParallelPages from "../../components/ParallelPages";
import EventPublication from "../../components/EventPublication";
import ProductSpecification from "../productSpecification/components/ProductSpecification";
import ChemicalComposition from "../productSpecification/components/ChemicalComposition";

class ProductsInner extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if(this.props.match.params) {
      let params = {
        [ApiParams.page_slug]: this.props.match.params.page_slug
      }

      this.props.getProductPageData(params)
    }
  }

  render() {
    let { productPage } = this.props;
    let pageData = productPage ? productPage.page_data : null
    let pageSub = pageData ? pageData.title : ''

    let bannerImagePath = productPage ?
      productPage.images ?
        productPage.images.list ?
          productPage.images.list[0] ?
            productPage.images.list[0].path + productPage.images.list[0].img_name : null
          : null
        : null
      : null

    let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

    let posts = productPage ? productPage.posts ? productPage.posts.list : [] : []

    // let mediaImages = pageData ?
    //   pageData.template === 'images' ?
    //     sustainabilityPage.images.list.filter(item => item.sort_order !== 0)
    //     : [] : []

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <Menu/>
        <InnerBanner
          subTitle={pageSub}
          image={banner}
        />
        {pageData ?
          pageData.short_desc === 'table' ?
          null : (
              <StyledMidSection>
                <Container>
                  <StyledGrid
                    background={BACKGROUND_IMAGE}
                    data={posts}
                    fontSize={42}
                    lineHeight={30}
                    page={'products'}
                  />
                </Container>
              </StyledMidSection>
            )
          : null}
        <EventPublication/>
        <ParallelPages
          backgroundColor={'#2E3744'}
          titleColor={'#fff'}
          titleText={'products'}
          color={'#69788D'}
          hoverColor={'#fff'}
          page={'products'}
        />
      </motion.div>
    )
  }
};

const StyledMidSection = styled.div`
  position: relative;
  background-color: #2E3744;

 @keyframes bouncing{
      0%{
        transform: translateY(0px);
      }
      100%{
        transform: translateY(15px);
      }
    }

`;


function mapStateToProps(state) {
  return {
    productPage: state.commonReducer.productsInner,
    sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProductPageData: params => dispatch(fetchProductPageData(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsInner));
