export const COMMON = {
  FETCH_COMMON_PAGE: {
    MAIN: 'FETCH_COMMON_PAGE',
    SUCCESS: 'FETCH_COMMON_PAGE_SUCCESS',
    FAILURE: 'FETCH_COMMON_PAGE_FAILURE'
  },
  FETCH_SUSTAINABILITY_INNER: {
    MAIN: 'FETCH_SUSTAINABILITY_INNER',
    SUCCESS: 'FETCH_SUSTAINABILITY_INNER_SUCCESS',
    FAILURE: 'FETCH_SUSTAINABILITY_INNER_FAILURE'
  },
  FETCH_PRODUCTS_INNER: {
    MAIN: 'FETCH_PRODUCTS_INNER',
    SUCCESS: 'FETCH_PRODUCTS_INNER_SUCCESS',
    FAILURE: 'FETCH_PRODUCTS_INNER_FAILURE'
  },
  FETCH_INVESTORS_INNER: {
    MAIN: 'FETCH_INVESTORS_INNER',
    SUCCESS: 'FETCH_INVESTORS_INNER_SUCCESS',
    FAILURE: 'FETCH_INVESTORS_INNER_FAILURE'
  }
};
