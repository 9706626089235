import React, {Component} from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import styled from "styled-components";
import {Container} from "react-bootstrap";
import {baseUrl} from "../../../components/BaseUrl";

const photos = [
  { src: 'https://source.unsplash.com/2ShvY8Lf6l0/800x599', width: 200, height: 400 },
  { src: 'https://source.unsplash.com/Dm-qxdynoEc/800x799', width: 570, height: 400 },
  { src: 'https://source.unsplash.com/qDkso9nvCg0/600x799', width: 200, height: 200 },
  { src: 'https://source.unsplash.com/iecJiKe_RNg/600x799', width: 200, height: 200 },
];

class GalleryCareer extends Component {
  constructor(props) {
    super(props);
    this.state = { currentImage: 0, lightboxIsOpen: false };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    let { posts } = this.props;
    let galleryData = posts ? posts.find(item => item.data.slug === 'gallery') : null

    let galleryImages = galleryData ? galleryData.images : [];

    let photoGallery = galleryImages ? galleryImages.map((image) => {
      let imgObj = {
        src: baseUrl + image.path + image.img_name,
        width: parseInt(image.short_desc),
        height: parseInt(image.short_desc_2)
      }

      return imgObj
    }) : []

    return (
      <StyledGallery>
        <Container className="pt-110 pb-110">
          {photoGallery.length ? (
            <>
              <Gallery
                photos={photoGallery}
                onClick={this.openLightbox}
              />
              {this.state.lightboxIsOpen ? (
                <Lightbox
                  images={photoGallery}
                  onClose={this.closeLightbox}
                  onClickPrev={this.gotoPrevious}
                  onClickNext={this.gotoNext}
                  currentImage={this.state.currentImage}
                  isOpen={this.state.lightboxIsOpen}
                />
              ) : null}
            </>
          ) : ''}

        </Container>

      </StyledGallery>
    )
  }
}

export default GalleryCareer

const StyledGallery = styled.div`
  background-color: #2E3744;
  border-bottom: 1px solid rgba(255, 255, 255, 0.29);

  img {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    object-fit: cover;
  }

  @media (max-width: 991px) {
    img {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  @media (max-width: 767px) {
    .pb-110, .pb-150 {
      padding-top: 42px;
      padding-bottom: 32px;
    }
  }

`
