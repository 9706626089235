import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import {Container, Row, Col} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import Lines from '../../../components/VerticalLine';
import AllValues from "../../../components/StyledQuantam";

// img
import MissionImg from '../../../assets/images/dynamic/mission.jpg';
import goDown from '../../../assets/images/goDown.svg';
import $ from "jquery";

const GPSQuantam = ({ posts, image, background }) => {
    useEffect(()=>{
        let getOffset = $('.container').offset().left + 15;
        if($(window).width() > 768){
            $('.mission-vision-banner-thumb').css({'padding-left': getOffset+170});
            $('.mission-vision-banner-thumb >img').css({'left': getOffset+70})
        }
    });

    return (
        <StyleGPSQuantam background={background}>
            <Container id="values">
                <AllValues posts={posts} image={image}/>
            </Container>
        </StyleGPSQuantam>
    )
};

const StyleGPSQuantam = styled.div`
  position: relative;
  background-image: url(${props => props.background ||  ''});
  .mission-vision-banner-thumb{
    padding-left: 170px;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    //@media(min-width: 992px){
    //  max-width: 960px;
    //}
    //@media(min-width: 1200px){
    //  max-width: 1140px;
    //}
    //@media  (min-width: 1440px) {
    //    width: 1366px;
    //}
    > img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 70px;
    }

    &__img {
      padding-top: 54.64%;
      position: relative;
      overflow: hidden;

      > img {
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }

  }

  .mission-vision-content {
    margin-top: 100px;
    padding-left: 170px;

    ul {
      margin-top: 30px;

      li {
        margin-bottom: 20px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }

  }

  @media (max-width: 991px) {
    .mission-vision-banner-thumb {
      width: 90%;
      padding-left: 0;
      margin: 0 auto;

      > img {
        display: none;
      }
    }

    .mission-vision-content {
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    .mission-vision-banner-thumb {
      width: calc(100% - 30px);
    }

    .mission-vision-content {
      margin-top: 60px;

      ul li {
        margin-bottom: 15px;
      }
    }

    .sub-title {
      margin-top: 60px;
    }

    p {
      font-weight: 300;
    }
  }

  .go-down {
    animation: bouncing .800s ease-in-out infinite alternate;
  }

  @keyframes bouncing {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(15px);
    }
  }

`;

export default GPSQuantam;
