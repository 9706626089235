import  {DIVISION} from "../../constants/division";

export function fetchDivisionPageData(params) {
  return {
    type: DIVISION.FETCH_DIVISION_PAGE.MAIN,
    params
  }
}

export function fetchDivisionListData(params) {
  return {
    type: DIVISION.FETCH_DIVISION_LIST.MAIN,
    params
  }
}

export function fetchDivisionListChildData(params) {
  return {
    type: DIVISION.FETCH_DIVISION_LIST_CHILD.MAIN,
    params
  }
}

export function fetchDivisionListChildPage(params) {
  return {
    type: DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.MAIN,
    params
  }
}
