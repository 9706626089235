import React, {Component } from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Links from '../../../components/Link';
import Line from '../../../components/VerticalLine';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import { fetchHomeBusinessDivisionData } from "../../../redux/actions/home";
import { ApiParams } from "../../../constants/apiParams";
import $ from 'jquery';

// img
import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";

class BusinessDivision extends Component {
    state = {
        leftOffset: 0
    };

    componentDidMount() {
        let elem = document.getElementById("main-container");
        if(elem && elem.offsetLeft) {
            this.setState({offsetLeft: elem.offsetLeft})
        }
        let params = {
            [ApiParams.page_type]: 'home-business-division'
        };
        this.props.getHomeBusinessDivisionData(params);


        let getOffset = $('.container').offset().left;
       setTimeout(function () {
           if($('.business__single').length < 10){
               $('.swiper-pagination').prepend('<span class="total-0">0</span><span class="current-0">0</span>');
           }
           if($(window).width() > 769){
               $('.swiper-pagination').css('right',getOffset+15)
           }
       },500);









    };

    render() {
        let { businessData } = this.props

        let businessList = businessData ? businessData.images ? businessData.images.list : [] : []

        let len = businessList.length

        const params = {
            // effect: "fade",
            fadeEffect: {crossFade: true},
            speed: 1500,
            autoplay: {
                delay: 5500,
                disableOnInteraction: true,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        };
        return (
          <StyledDivision className="business-division" offset={this.props.footerOffset}>
              <Line />
              <Container className="business-title">
                  <SubTitle text={'Business divisions'} />
              </Container>
              {len && len === businessList.length ? (
                <Swiper {...params}>
                    {businessList.map(data=>(
                      <div key={data.id} className="business__single pt-110 pb-110">
                          <img className="business__single__img" src={baseUrl + data.path + data.img_name} alt=""/>
                          <Container id="main-container">
                              <Row>
                                  <Col sm={6}>
                                      <div className="business__single__content">
                                          <Title color={'#FFF'} margin={'0 0 30px 0'} text={data.short_title} />
                                          <Text color={'#FFF'}><p>{data.short_desc}</p></Text>
                                          <Links link={'/'} margin={'121px 0 0 0'} text={'view all concerns'} />
                                      </div>
                                  </Col>
                              </Row>
                          </Container>
                      </div>
                    ))}
                </Swiper>
              ) : null}
          </StyledDivision>
        )
    }
}

const StyledDivision = styled.div`
    position: relative;
    .business-title{
      position: absolute;
      z-index: 3;
      margin: auto;
      left: 0;
      right: 0;
      top: 110px;
    }
    
    .business__single{
      position: relative;
        &:before{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.6);
            z-index: 1;
        }

        .container{
      position: relative;
    }
      &__img{
         height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0
      }
      
      &__content{
        position: relative;
        z-index: 2;
        margin-top: 120px;
      }
      
    }

   
   .swiper-pagination{
       width: fit-content;
       position: absolute;
       top: 100px;
       right:${props=> props.offset+29 || '100'}px;;
       z-index: 5;
       color:transparent;
       flex-direction: column;
       height: fit-content;
       padding-right: 2px;
       span{
        font-size: 50px;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
        display: flex;
        &.swiper-pagination-current{
          position: relative;
          
          &:after{
            content: '';
            height: 2px;
            width: 200%;
            bottom: -6px;
            background-color: #969696;
            position: absolute;
            left: -100%;
        
          }
        }
        &.swiper-pagination-total{
          //margin-top: -31px;
          color: #969696;
          margin-top: -5px;
        }
       }
   }

  .swiper-button-next{
       position: absolute;
        right:${props=> props.offset+15 || '100'}px;
        top: 0;
        
        margin: auto;
        z-index: 3;
        bottom: -60px;
        height: 50px;
        width: 50px;
        background-image: url(${RightIcon});
        background-position: center;
        background-size: contain;
         &:focus{
          outline:none;
        }
        &:after{
          display: none;
        }
  }
  .swiper-button-prev{
      left: auto;
       position: absolute;
        right:${props=> props.offset+15 || '100'}px;;
        top: 0;
        bottom: 60px;
        margin: auto;
        z-index: 3;
       
        height: 50px;
        width: 50px;
        background-image: url(${LeftIcon});
        background-size: contain;
        background-repeat: no-repeat;
         &:after{
          display: none;
        }
        &:focus{
          outline:none;
        }
  }
  
  .swiper-pagination-fraction{
    left: auto;
  }
  .swiper-slide{
    height: auto;
  } 
  
  .total-0{
    position: absolute;
    bottom: 0;
    right: 29px;
    color: #969696 !important;
  }
  .current-0{
    position: absolute;
    right: 29px;
  }
  
  @media(max-width:768px){
    .swiper-pagination{
      top: 126px;
      right: 40px;
      
    }
    .swiper-button-next{
      right: 40px !important;
    }
    .swiper-button-prev{
      right: 40px !important;
    }
  }
  
  @media(max-width:767px){
    .swiper-pagination{
      top: 126px;
      right: 15px;
      left: auto;
      span{
        font-size: 30px;
        font-weight: 300;
        line-height: 28px;
        &:nth-last-of-type(1){
          margin-top: -9px;
        }
      }
    }
    .swiper-button-next{
      right: 15px !important;
      bottom: 75px;
      top: auto;
      
    }
    .swiper-button-prev{
      right: 15px !important;
      bottom: 140px;
      top: auto;
      left:auto ;
    }
    .business__single__content{
      margin-top: 100px;
    }
    .col-sm-6{
      min-width: 100% !important;
    }
    .business__single__content .link{
      margin-top: 100px;
    }
    .current-0,.total-0{
      right: 20px;
    }
    
  }

`;

function mapStateToProps(state) {
    return {
        businessData: state.homeReducer.businessData,
        footerOffset: state.footerReducer.footerOffset
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeBusinessDivisionData: (params) => dispatch(fetchHomeBusinessDivisionData(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDivision);
