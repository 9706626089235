import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import Swiper from 'react-id-swiper';
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import RightIcon from "../../../assets/images/navRight.svg";
import LeftIcon from "../../../assets/images/navLeft.svg";
import {connect} from "react-redux";
import Lines from '../../../components/VerticalLine';
import $ from "jquery";

const MachineSlider = (props) => {
    let {posts, footerOffset} = props;

    let machineData = posts ? posts.find(post => post.data.slug === 'machinaries') : null

    let machineImages = machineData ? machineData.images ? machineData.images : [] : []

    let len = machineImages.length

    const params = {
        slidesPerView: 1,
        speed: 1500,
        autoplay: {
            delay: 5500,
            disableOnInteraction: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    };

    useEffect(() => {
        let getOffset = $('.container').offset().left;
        if ($(window).width() > 769) {
            setTimeout(function () {
                $('.machine-slider__slider .swiper-button-next').css('left', getOffset - 20);
                $('.machine-slider__slider .swiper-button-prev').css('left', getOffset - 20);
            })

        }
    }, []);

    return (
        <StyleMachineSlider offset={footerOffset} className="machine-slider pt-110 pb-110">
            <Lines/>
            <Container fluid className="p-0">
                <Row>
                    <Col md={6} className="p-0">
                        <div className="machine-slider__slider">
                            {len && len === machineImages.length ? (
                                <Swiper  {...params}>
                                    {machineImages.map(data => {
                                        let imageSlide = baseUrl + data.path + data.img_name
                                        return (
                                            <div className="machine-slider__slider__single">
                                                <div className="machine-slider__slider__single__img">
                                                    <img src={imageSlide} alt=""/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Swiper>
                            ) : null}
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="machine-slider__outer-text">
                            <SubTitle text={'Major Machineries'} margin={'0 0 40px 0'}/>
                            <Text>
                                {machineData ? machineData.data ? ReactHtmlParser(machineData.data.description) : null : null}
                            </Text>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyleMachineSlider>
    )
};

const StyleMachineSlider = styled.div`
    background-color: #f9f9f9;
    position: relative;
    .machine-slider__slider{
    position: relative;
      &__single{
        &__img{
          padding-top: 87.85%;
          position: relative;
          &:after{
            content: '';
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,0.5);
            position: absolute;
          }
            
          img{
            position: absolute;
            height: 100%; 
            width: 100%;
            left: 0;
            top: 0;
            
          }
        }
      }
    }
  
  
    .swiper-button-next{
       position: absolute;
        right: auto !important;
        left:30px;
        bottom: 60px;
        top:0;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        background-image: url(${RightIcon});
        background-position: center;
        background-size: contain;
         &:focus{
          outline:none;
        }
    }
    .swiper-button-prev{
       position: absolute;
        right: auto !important;
        bottom: 0px;
        left:30px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        background-image: url(${LeftIcon});
        background-size: contain;
        top: 60px;
        &:focus{
          outline:none;
        }
    }
    
    .machine-slider__outer-text{
      padding-left: 85px;
      padding-right: 100px;
      padding-top: 100px;
    }
    @media(max-width:991px){
      .machine-slider__outer-text {
        padding-left: 50px;
        padding-top: 50px;
      }
    }
    @media(max-width:768px){
     &.machine-slider{
      padding-top: 0;
      .col-md-6{
        min-width: 100%;
      }
     }
     .machine-slider__outer-text{
      padding-left: 15px;
      padding-right: 15px;
      p{
        font-weight: 300;
      }
     }
    
   }
   @media(max-width:767px){
     .swiper-button-next{
      left: 100px !important;
      bottom: 20px;
      top: auto;
    }
    .swiper-button-prev{
      left: 35px !important;
      bottom: 20px;
      top: auto;
    }
   }
   
`;

let mapStateToProps = state => {
    return {
        footerOffset: state.footerReducer.footerOffset
    }
};
export default connect(mapStateToProps)(MachineSlider);


