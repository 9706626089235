import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import ReactHtmlParser from "react-html-parser";

const Description = ({ posts }) => {
  let sellingPost = posts ? posts.find(item => item.data.slug === 'key-selling') : null
  return (
    <StyledDescription>
      <Container className="pt-110 pb-110">
        <SubTitle
          text={'Key selling points'}
          fontSize={56}
        />
        <SubTitle
          text={'HR Policy'}
          fontSize={16}
          margin={'12px 0 48px 0'}
          fontWeight={200}
          textTransform={'capitalize'}
        />
        {sellingPost ?
          sellingPost.data ? ReactHtmlParser(sellingPost.data.description) : ''
          : ''
        }
      </Container>
    </StyledDescription>
  )
};

const StyledDescription = styled.div`
  background-color: #272E39;

  p {
    color: #fff;
    padding-bottom: 20px;
    padding-top: 6px;
    border-bottom: 1px solid #8b8b8b;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    
  }
`;

export default Description;
