import React from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import SubText from '../../../components/SubText';

// img

let AddressTab = props => {
    let {
      backgroundColor,
      titleText,
      titleColor,
      color,
      hoverColor,
      address,
      setActive
    } = props;

  let handleTab = data => {
    setActive(data)
  }

  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 1500,
    freeMode: false,
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,

      },
    }
  };

  return (
      <StyledParallelPages className="padding-view" backgroundColor={backgroundColor} hoverColor={hoverColor}>
        <Container className="title-here">
          <div className="borders">
            <SubTitle
                text={titleText}
                fontSize={54}
                margin={'32px 0 21px 0'}
                color={titleColor}
            />
          </div>
          <div className="sub-text-view">
          {address.length ? (
              <Swiper {...params}>
                {address.map(item => (
                    <SubText
                        onClick={() => handleTab(item)}
                        text={item.data.title}
                        fontSize={24}
                        color={color}
                        letterSpacing={1}
                        hoverColor={hoverColor}
                        borderRight={'1px solid #adadad'}
                    />
                    // <div onClick={() => handleTab(item)}>
                    //
                    // </div>
                ))}
            </Swiper>
          ) : null}
        </div>
      </Container>
    </StyledParallelPages>
  )
};


const StyledParallelPages = styled.div`
  // background-position: center;
  // background-repeat: no-repeat;
  // -webkit-background-size: cover;
  // background-size: cover;
  background-color: ${props => props.backgroundColor || '#2E3744'};
  padding-bottom: 48px;
  padding-top: 32px;
  .padding-view {
   
  }

  .title-here {
    .borders {
      border-top: 1px solid #B9B9B9;
      border-bottom: 1px solid #B9B9B9;
      margin: 10px 0 24px 0;
      text-align: center;
    }

    .sub-text-view {
      display: flex;
        justify-content: center;
    }

    .title {
      width: 60%;
    }
  }

  @media (max-width: 767px) {
    .borders .sub-title {
      margin: 16px 0 10px 0 !important;
    }

    .title-here .sub-text-view {
      margin-bottom: 0px;
      margin-top: -60px;
    }

    .title-here .sub-title {
      margin-bottom: 0px;
    }

    .title {
      font-size: 20px;
      line-height: 23px;
      height: 70px !important;
    }
  }
`;

export default AddressTab;
