import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {HOME} from '../../constants/home'

function* fetchHomeBannerSliderData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err);
    yield put({type: HOME.FETCH_HOME_BANNER_SLIDER.FAILURE, error: "something went wrong"})
  }
}

function* fetchHomeAboutUsData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_ABOUT_US.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_ABOUT_US.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeBusinessDivisionData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_BUSINESS_DIVISIONS.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_BUSINESS_DIVISIONS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeClientData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_CLIENTS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_CLIENTS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeAchievementData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_ACHIEVEMENTS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_ACHIEVEMENTS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeCertificationData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_CERTIFICATIONS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_CERTIFICATIONS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeNewsData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_NEWS_EVENTS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_NEWS_EVENTS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeSustainability(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    );
    yield put({
      type: HOME.FETCH_HOME_SUSTAINABILITY.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.FETCH_HOME_SUSTAINABILITY.FAILURE,
      error: "Something went wrong"
    })
  }
}

export default function* homeSagas() {
  yield takeLatest(HOME.FETCH_HOME_BANNER_SLIDER.MAIN, fetchHomeBannerSliderData);
  yield takeLatest(HOME.FETCH_HOME_ABOUT_US.MAIN, fetchHomeAboutUsData);
  yield takeLatest(HOME.FETCH_HOME_BUSINESS_DIVISIONS.MAIN, fetchHomeBusinessDivisionData);
  yield takeLatest(HOME.FETCH_HOME_CLIENTS.MAIN, fetchHomeClientData);
  yield takeLatest(HOME.FETCH_HOME_ACHIEVEMENTS.MAIN, fetchHomeAchievementData);
  yield takeLatest(HOME.FETCH_HOME_CERTIFICATIONS.MAIN, fetchHomeCertificationData);
  yield takeLatest(HOME.FETCH_HOME_NEWS_EVENTS.MAIN, fetchHomeNewsData);
  yield takeLatest(HOME.FETCH_HOME_SUSTAINABILITY.MAIN, fetchHomeSustainability);
}
