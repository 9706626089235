import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../components/SubTitle';
import Text from '../components/Text';
import Button from "../components/Button"
import {baseUrl} from "../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import news from '../assets/images/news.svg'
import {connect} from 'react-redux'
import {fetchEventPublications} from "../redux/actions/event-publication";
import {ApiParams} from "../constants/apiParams";
import {withRouter, Link} from 'react-router-dom'
import Modal from "./Modal";

class ReportAndMarket extends Component {
    state  = {
        openModal: false,
        title: '',
        description: ''
    }
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'publications'
        };
        this.props.getEventPublications(params);
    }

    handleModal = () => {
        this.setState({ openModal: !this.state.openModal })
    }

    handlePost = (title, description) => {
        this.setState({ title: title, description: description })
        this.handleModal()
    }

    render() {
        let deskImage = 'https://res.cloudinary.com/app-alo-social/image/upload/v1617803119/sxs72jcu_2x_mgit3u.png'

        let { publication } = this.props;
        let lists = publication ?
          publication.posts ?
            publication.posts.list
            : []
          : []

        return (
            <StyledReportAndMarket className="event-publication" deskImage={deskImage}>
                <div className="event-publication-div">
                    <Container className="flex-grid">
                        <Modal
                            show={this.state.openModal}
                            handleClose={this.handleModal}
                            post={this.state.description ? this.state.description : ''}
                            title={this.state.title ? this.state.title : ''}
                        />
                        <Row>
                            <Col md={5}>
                                <div className="event-publication__text">
                                    <SubTitle
                                        text={'Stock Market News'}
                                        fontSize={32}
                                        fontWeight={'normal'}
                                        textTransform={'normal'}
                                        color={'#fff'}
                                    />
                                </div>
                                <Container fluid>
                                    <Row>
                                        <Col md={12} >
                                            <Table responsive hover variant="dark">
                                                <tbody>
                                                <tr>
                                                    <td>Mark</td>
                                                    <td>Otto</td>
                                                </tr>
                                                <tr>
                                                    <td>Jacob</td>
                                                    <td>Thornton</td>
                                                </tr>
                                                <tr>
                                                    <td>Larry the Bird</td>
                                                    <td>Thornton</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                            <div onClick={() => this.handlePost('Stock Market News', 'add table here')}>
                                                <Button text={'View all'} fontSize={18}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col md={7}>
                                <div className="event-publication__text">
                                    <SubTitle
                                        text={'Categorized Report'}
                                        fontSize={32}
                                        fontWeight={'normal'}
                                        textTransform={'normal'}
                                        color={'#fff'}
                                    />
                                </div>
                                <Container fluid>
                                    <Row>
                                        <Col md={12} className="d-flex">
                                            {lists.length ? lists.map((list) => {
                                                let title = list.data.title
                                                let icon = list.images ? baseUrl + list.images[0].path + list.images[0].img_name : ''
                                                return (
                                                    <div className="grid">
                                                        <Link to={`/${list.data.slug}`}>
                                                            <div className="event-publication__grid">
                                                                <img src={icon} alt="events-icon"/>
                                                                <SubTitle
                                                                    text={title}
                                                                    fontSize={18}
                                                                    fontWeight={'normal'}
                                                                    textTransform={'normal'}
                                                                    color={'#fff'}
                                                                    margin={'16px 0 0 0'}
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )}) : null}
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </StyledReportAndMarket>
        )
    }
};

const StyledReportAndMarket = styled.div`
  background-image: url(${props => props.deskImage || ''});
  background-position: revert;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  .container {
    //margin-right: 48px;
    //padding-right: 16px;
    //margin-left: 48px;
    //padding-left: 16px;
  }

  .event-publication-div {
    width: 100%;
    height: 100%;
    background-color: #272e39f0;

    .table-dark {
      background-color: #00000000;
    }

    table {
      width: 80%;
      tr {
        border-color: #FAFAFA;

        &:nth-of-type(odd) {
          background-color: rgba(43, 51, 61, 0.5);
        }

        &:nth-of-type(even) {
          background-color: rgba(38, 46, 57, 0.5);
        }

        &:nth-last-of-type(1) {
          border-bottom: 0 solid #7d7d7d;
        }

        td {
          padding-left: 25px;
          padding-top: 25px;
          padding-bottom: 25px;
          font-size: 17px;
          font-weight: 100;
          line-height: 20px;
          color: #FAFAFA;

          &:nth-last-of-type(1) {
            color: #828181;
          }

          img {
            padding: 5px 5px 8px 5px;
            width: 24px;
          }
        }
      }
    }
  }

  .event-publication__text {
    padding: 100px 85px 50px 0px;
  }

  .flex-grid {
    .d-flex {
      display: flex !important;
      //justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 100px;
    }

    .grid {
      padding-right: 25px;
      padding-bottom: 25px;
      width: 25%;
    }

    .event-publication__grid {
      padding: 60px 60px 40px 60px;
      width: 100%;
      height: 100%;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
      cursor: pointer;

      img{
        width: 60%;
        height: 70%;
        object-fit: contain;
      }
    }
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-7 {
    padding-right: 0;
  }

  .col-md-12 {
    padding-right: 0;
    padding-left: 0;
  }

  .col-4 {
    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .col-8 {
    img {
      display: none;
    }
  }

  @media (max-width: 1600px) {
    .flex-grid .grid {
      padding-right: 15px;
      padding-bottom: 15px;
      width: 25%;
    }
    .flex-grid .event-publication__grid {
      padding: 32px;
      width: 100%;
      height: 148px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
    }
    .event-publication-div .sub-title {
      font-size: 13px !important;
    }
  }

  @media (max-width: 1199px) {
    .flex-grid .grid {
      padding-right: 15px;
      padding-bottom: 15px;
      width: 30%;
    }
    .flex-grid .event-publication__grid {
      padding: 32px;
      width: 100%;
      height: 148px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
    }
    .event-publication-div .sub-title {
      font-size: 13px !important;
    }
  }

  @media (max-width: 991px) {
    .flex-grid {
      padding-left: 40px;

      .event-publication__grid {
        padding: 24px;
        width: 100%;
        height: 120px;
        text-align: center;
        background: #FFFFFF0D 0% 0% no-repeat padding-box;

        img{
          width: 40%;
          height: 50%;
          object-fit: contain;
        }
      }
    }
  
    .event-publication__text {
      padding-top: 70px;
      padding-left: 24px;
      padding-right: 24px;
    }

    .col-8 {
      min-width: 100%;
      margin-left: 0;

      img {
        display: block;
        max-width: 100%;
        object-fit: contain;
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
    }

    .col-4 {
      img {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .grid {
      padding-right: 0px;
      padding-bottom: 0px;
      width: 50% !important;

      &:nth-last-of-type(1) {
        padding-bottom: 100px;
      }
    }
    .col-md-5 {
      flex: 0 0 100%;
      max-width: 100%;
      
      table {
        width: 100%;
      }
    }
    .flex-grid {
      padding-left: 16px;
    }

    .flex-grid .event-publication__grid {
      padding: 42px;
      width: 100%;
      height: 170px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;
    }

    .event-publication__text {
      padding: 80px 0px 0px;
    }
  }

  @media (max-width: 441px) {
    .d-flex {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flex-grid .event-publication__grid {
      padding: 40px;
      width: 100%;
      height: 165px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media (max-width: 395px) {
    .d-flex {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flex-grid .event-publication__grid {
      padding: 40px;
      width: 100%;
      height: 150px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media (max-width: 365px) {
    .d-flex {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flex-grid .event-publication__grid {
      padding: 40px;
      width: 100%;
      height: 130px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media (max-width: 325px) {
    .d-flex {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flex-grid .event-publication__grid {
      padding: 30px;
      width: 100%;
      height: 120px;
      text-align: center;
      background: #FFFFFF0D 0% 0% no-repeat padding-box;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

function mapStateToProps(state) {
    return {
        publication: state.publicationReducer.publicationMain
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEventPublications: params => dispatch(fetchEventPublications(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportAndMarket));
