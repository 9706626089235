import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";

const Text = ({children, text, fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin, line, textAlign}) => {
  return (
    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
      {({isVisible}) =>
        <StyledText
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          textTransform={textTransform}
          margin={margin}
          line={line}
          textAlign={textAlign}
          className={`${isVisible ? 'anim-active' : ''} fade-up texts`}
        >
          {text ? <p>{ReactHtmlParser(text)}</p> : ''}
          {children ? children : ''}
        </StyledText>
      }
    </VisibilitySensor>
  )
};

const StyledText = styled.div`
    p{
        font-size: ${props => props.fontSize || 16}px;
        font-weight:${props => props.fontWeight || '400'};
        margin:${props => props.margin || '0 0 20px 0'};
        line-height:${props => props.lineHeight || 24}px;
        color:${props => props.color || '#000000'};
        text-transform: ${props => props.textTransform || 'initial'} ;
        text-align: ${props => props.textAlign || 'left'};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${props => props.line || ''} ;
        -webkit-box-orient: vertical;
        //&:nth-last-of-type(1){
        //  margin-bottom: 0;
        //}
    }

    @media(max-width:767px){
      p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

`;

export default Text;
