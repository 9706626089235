import React, {Component} from 'react';
import styled from 'styled-components';
import AboutImg from '../../../assets/images/dynamic/about-detail.jpg';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import {fetchContactPageData} from '../../../redux/actions/contact/'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {baseUrl} from "../../../components/BaseUrl";

class BannerDescription extends Component {

    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'contact'
        };
        this.props.getContactData(params);

        let getOffset = $('.container').offset().left +15;
        if ($(window).width() > 769) {
            setTimeout(function () {
                $('.description-content .description__wrap').css({'margin-left': getOffset, 'width': `calc(100% - ${getOffset}})`})
            })
        }
    }

    render() {

        let {address} = this.props;
        const addressPageData = address ? address.page_data : [];
        const contactAddress = address ? address.posts ? address.posts.list : [] : [];
        const bannerImageData = addressPageData ?
          addressPageData.images ?
            addressPageData.images.list ?
                addressPageData.images.list.find(item => item.is_banner === 'on')
              : null
            : null
          : null

        let bannerImage = bannerImageData ? baseUrl + bannerImageData.path + bannerImageData.img_name : ''

        const len = contactAddress.length;

        return (
            <StyledBannerDescription offset={this.props.footerOffset}>
                <div className="description-content">
                    <div className="description__img">
                        <img src={bannerImage} alt=""/>
                    </div>

                    <div className="description__wrap">
                        <div className="description__wrap__about">
                            <SubTitle text={'contact'} margin={'0 0 10px 0'}/>
                            {addressPageData ?
                                <Title text={addressPageData.sub_title} margin={'0 0 100px 0'} fontSize={'54'}
                                       lineHeight={'60'}/>
                                : ''}
                        </div>

                        <div className="contact-address">
                            <Row>

                                {len && len === contactAddress.length ? (
                                    <>
                                        {contactAddress.map(data => (
                                            <Col md={4} key={data.data.id}>
                                                <div className="contact-address__single">
                                                    <h4>{data.data.title}</h4>
                                                    <p>{ReactHtmlParser(data.data.description)}</p>
                                                </div>
                                            </Col>
                                        ))}
                                    </>
                                ) : null}


                            </Row>
                        </div>
                    </div>


                </div>
            </StyledBannerDescription>
        )
    }

};

const StyledBannerDescription = styled.div`
  .description-content{
    background-color: #F3F3F3;
    padding-bottom: 150px;
    .description__img{
      height: 600px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        top: 0;
        background-color: rgba(0,0,0,0.6);
      }
      img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
       }
    }
  }
  .description__wrap{
     background: #FFF;
     width: calc(100% - ${props => props.offset + 15});
     margin-left: ${props => props.offset + 15 || 200}px;
     padding: 100px;
     margin-top: -200px;
     position: relative;
     z-index: 2;
    &__about{
      width: 60%;
    }
    .contact-address{
      &__single{
        h4{
          font-size: 18px;
          font-weight: 500;
          margin: 0 0 20px 0;
        }
        p{
          font-size: 16px;
          font-weight: 400;
        }
      }
        
    }
  }
  
  @media(max-width:1080px){
  
    .container{
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media(max-width:1080px){
       .description__wrap{
       overflow: hidden;
        width: calc(100% - 15px);
        margin-left: 15px;
      }
  }
 
  @media(max-width:992px){
      .description__wrap__about{
        width: 100%;
       }
      .description__wrap{
        margin-top: -100px;
        padding: 80px 35px;
      }
       .description__img {
            height: 500px;
            position: relative;
        }
  }
 
  
  @media(max-width:767px){
  
      .description-content{
        background-color: rgba(0,0,0,0.3);
        padding: 70px 0 30px 0;
      }
      .description__wrap{
        padding: 80px 20px;
        img{
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
      .description__img {
        height: 500px;
        position: relative;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }
      .description__wrap__at-a-glance{
        margin-top: 60px;
        padding-top: 60px;
        table tr td{
         padding-top: 15px;
         padding-bottom: 15px;
         font-size: 16px !important;
         line-height: 21px !important;
        }
      }
      
  }
`;


let mapStateToProps = state => {
    return {
        footerOffset: state.footerReducer.footerOffset,
        address: state.contactReducer.address
    }
};

function mapDispatchToProps(dispatch) {
    return {
        getContactData: (params) => dispatch(fetchContactPageData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerDescription);
