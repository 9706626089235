import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import SubTitle from '../../../components/SubTitle';
import {baseUrl} from '../../../components/BaseUrl';
import 'swiper/css/swiper.css';
import {connect} from 'react-redux';
import {fetchHomeClientData} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import VisibilitySensor from "react-visibility-sensor";

class ClientSlider extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-client'
        }
        this.props.getHomeClientData(params)
    }

    render() {
        let {homeClients} = this.props
        const clientList = homeClients ? homeClients.images ? homeClients.images.list : [] : [];
        let len = clientList.length

        const params = {
            slidesPerView: 2,
            spaceBetween: 20,
            speed: 1000,
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true
            // },
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            breakpoints: {
                767: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                769: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                },
            }

        };

        return (
            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                {({isVisible}) =>
                    <StyledClient className="pt-110 pb-110">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <SubTitle color={'#58585A'} margin={'0 0 40px 0'} text={'Our clients'}> </SubTitle>
                                    <div className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                        {
                                            len && len === clientList.length ?
                                                <Swiper  {...params} >
                                                    {clientList.map(data => (
                                                        <div key={data.id} className="client-single">
                                                            <div className="client-single__inner">
                                                                <img src={baseUrl + data.path + data.img_name} alt=""/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Swiper> : null
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </StyledClient>
                }
            </VisibilitySensor>
        )
    }
};

const StyledClient = styled.div`
    .client-single{
      &__inner{
        padding-top: 100%;
        border:1px solid #707070;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          bottom:0;
          object-fit: contain;
          max-width:80%;
        }
      }
    }
    .swiper-slide{
      margin-bottom: 10px;
    }
    
    @media(min-width:769px){
      .swiper-pagination{
        display: none !important;
      }
      
    }
    
    @media(max-width:768px){
     .swiper-pagination{
        display: block !important;
      }
      //.swiper-container{
      //  padding-bottom: 45px;
      //}
      .swiper-pagination{
        bottom: 0px;
        .swiper-pagination-bullet{
            height: 5px;
            width: 5px;
            background-color: #000;
            position: relative;
            margin-right: 20px;
            opacity: 1;
          &:after{
            content: '';
            height: 17px;
            width: 17px;
            border-radius: 50%;
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            left: -6px;
            top: -6px;
            opacity: 0;
            z-index: -1;
          }
          &.swiper-pagination-bullet-active{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      .sub-title{
        text-align: center;
      }
      
    }
`;

function mapStateToProps(state) {
    return {
        homeClients: state.homeReducer.homeClients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeClientData: (params) => dispatch(fetchHomeClientData(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSlider);
