import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import Title from './Title';
import Text from './Text';
import BannerImg from "../assets/images/dynamic/inner-banner.jpg";

const InnerTextBanner = ({title, subTitle,text,link,linkText})=>{
    return (
      <StyledTextBanner className="inner-text-banner">
          <Container>
              <Row>
                  <Col xs={12}>
                      <div className="inner-text-banner__content">
                          <SubTitle text={subTitle} fontSize={'16'} color={'#989898'} lineHeight={'21'} fontWeight={'500'} margin={'0 0 15px 0'} />
                          <Title lineHeight={'58'} text={title} color={'#000'} fontSize={'48'} margin={'0 0 15px 0'} />
                          <a target="_blank" href={'http://'+link}>{linkText}</a>
                      </div>
                  </Col>
              </Row>
          </Container>
      </StyledTextBanner>
    )
};

const StyledTextBanner = styled.div`
  height: 430px;
  background-color: #FFFFFF;
   .container{
    position: relative;
    height: 100%;
    .row{
      height: 100%;
    }
    .col-md-12{
      height: 100%;
    }
   }
    
   .inner-text-banner__content{
    position: absolute;
    bottom: 100px;
    a{
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: ${hover};
      &:hover{
        color: ${hover};
        text-decoration: underline;
      }
    }
   }
`;

export default InnerTextBanner;
