import {CONTACT} from "../../constants/contact";

const initialState = {
    address: [],
    addressMainLoading: false,
    addressMainErr:'',
    message: ''
};

export default function contactReducer(state = initialState, action) {
    switch (action.type) {
        case CONTACT.FETCH_CONTACT_PAGE.MAIN:
            return {
                ...state,
                address: [],
                addressMainLoading: true,
                addressMainErr: '',
                message: ''
            };

        case CONTACT.FETCH_CONTACT_PAGE.SUCCESS:
            return {
                ...state,
                address: action.result,
                addressMainLoading: false,
                addressMainErr: ''
            };

        case CONTACT.FETCH_CONTACT_PAGE.FAILURE:
            return {
                ...state,
                address: [],
                addressMainLoading: false,
                addressMainErr: 'Error from Server'
            };
        default:
            return state
    }
}






