import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../../components/Title';
import Links from '../../../components/Link';
import {fetchHomeSustainability} from '../../../redux/actions/home/';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from '../../../components/BaseUrl';
// img
import leftBg from '../../../assets/images/sustain-bg.svg';

class Sustainability extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-sustainability'
        };
        this.props.getHomeSustainability(params)

    }

    render() {
        let {footerOffset}= this.props
        let {sustainability} = this.props;
        const sustainabilityPageData = sustainability ? sustainability.page_data : null;
        const sustainabilityImage = sustainability ? sustainability.images ? sustainability.images.list : [] : [];
        const len = sustainabilityImage.length;

        return (
            <StyledSustainability offset={footerOffset}>
                <Container id="main-container"/>
                <Container fluid>

                    <Row>
                        <Col sm={6} className="sustainability-left pt-110 pb-110">

                            <img src={leftBg} alt=""/>
                            <Title color={'#FFF'} margin={'0 0 80px 0'} text={'Sustainability'}/>
                            <div className="sustainability-left__content">
                                {/*<h4>Green Environment Initiative</h4>*/}

                                {sustainabilityPageData ?
                                    <Links link={'/sustainability/details/'} letterSpace={'0'} fontSize={'16'} margin={'17px 0 0 0'}
                                           color={'#FFF'} text={sustainabilityPageData.short_desc}/>
                                    : ''}
                            </div>

                        </Col>

                        <Col sm={6} className="sustainability-right">
                            {len && len === sustainabilityImage.length ? (
                                <>
                                    {sustainabilityImage.map(img =>
                                        <img key={img.id} src={baseUrl + img.path + img.img_name} alt=""/>
                                    )}
                                </>
                            ) : null}


                        </Col>

                    </Row>


                </Container>
            </StyledSustainability>
        )
    }


};

const StyledSustainability = styled.div`
     .sustainability-left{
        background-color: #3D9E71;
        position: relative;
        padding-left:${props => props.offset + 15 || '100'}px;
        
      >img{
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .title{
        z-index: 2;
        position: relative;
      }
      &__content{
        padding-right: 55px;
        position: relative;
        z-index: 2;
        margin-bottom: 40px;
        padding-bottom: 20px;
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
        &:after{
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          background-color: rgba(255,255,255,0.50);
          right: 55px;
          left: 0;
        }
        h4{
          font-size: 20px;
          font-weight: bold;
          margin: 0 ;
          color: #ffffff;
          text-transform: capitalize;
          line-height: 30px;
        }
        a:hover{
          letter-spacing: 0;
        }
        
      }
     }
     .sustainability-right{
      position: relative;
        >img{
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
        }
     }
     @media(max-width:991px){
      .sustainability-left{
        padding-left: 15px;
      }
     }
     
     @media(max-width:767px){
     .title{
      margin-bottom: 40px;
     }
      .sustainability-left{
        min-width: 100%;
        &__content{
           padding-right: 0;
        }
      }
      .sustainability-right{
        min-width: 100%;
        min-height: 400px;
      }
      .sustainability-left__content:after{
        right: 0;
      }
       .row{
          flex-direction: column-reverse;
       }
     }
   
`;


function mapStateToProps(state) {
    return {
        footerOffset: state.footerReducer.footerOffset,
        sustainability: state.homeReducer.sustainability
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeSustainability: (params) => dispatch(fetchHomeSustainability(params))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Sustainability);
