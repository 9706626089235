import {PUBLICATION} from "../../constants/event-publication";

let initialState = {
  publicationMain: [],
  publicationLoading: false,
  publicationErr: '',
  publicationPage: [],
  publicationPageLoading: false,
  publicationPageErr: ''
}

export default function publicationReducer(state = initialState, action) {
  switch (action.type) {
    case PUBLICATION.FETCH_EVENT_PUBLICATIONS.MAIN:
      return {
        ...state,
        publicationMain: [],
        publicationLoading: true,
        publicationErr: ''
      }

    case PUBLICATION.FETCH_EVENT_PUBLICATIONS.SUCCESS:
      return {
        ...state,
        publicationMain: action.result,
        publicationLoading: false,
        publicationErr: ''
      }

    case PUBLICATION.FETCH_EVENT_PUBLICATIONS.FAILURE:
      return {
        ...state,
        publicationMain: [],
        publicationLoading: false,
        publicationErr: 'Error from fetching'
      }

    case PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.MAIN:
      return {
        ...state,
        publicationPage: [],
        publicationPageLoading: true,
        publicationPageErr: ''
      }

    case PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.SUCCESS:
      return {
        ...state,
        publicationPage: action.result,
        publicationPageLoading: false,
        publicationPageErr: ''
      }

    case PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.FAILURE:
      return {
        ...state,
        publicationPage: [],
        publicationPageLoading: false,
        publicationErr: 'Error from fetching'
      }

    default:
      return state
  }
}
