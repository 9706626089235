import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import SubTitle from './SubTitle';
import Text from './Text';
import {withRouter} from 'react-router-dom'
import $ from 'jquery';

import {baseUrl} from "./BaseUrl";
import ReactHtmlParser from "react-html-parser";

const AllServices = props => {
  let { data, lineHeight, fontSize, background, page } = props
  useEffect(() => {
    let getOffset = $('.container').offset().left + 30;
    // if ($(window).width() > 769) {
    //   $('.services-single:nth-of-type(even) .services-single__right').css({'padding-left': getOffset})
    //   $('.services-single:nth-of-type(odd) .services-single__right').css({'padding-right': getOffset})
    // }
  },[])

  let handleRoute = (page, slug) => {
    if(page) {
      props.history.push(`/${page}/${slug}`)
    }
  }

  return (
    <StyledServices background={background}>
      <Container fluid className='p-0'>
        {data ? data.map((post, key) => {
          let postTitle = post.data.title
          let desc = post.data.description
          let postImage = post.images ?
            post.images[0] ? baseUrl + post.images[0].path + post.images[0].img_name : '' : ''

          return (
              <Row
                  onClick={page ? page === 'about' ? null :
                      post.data["Extra description one"] ? () => handleRoute(post.data["Extra description one"], post.data.slug) :
                          () => handleRoute(page, post.data.slug)
                    : null
                  }
                key={key} className="services-single"
                style={page && page !== 'about' ? { cursor: 'pointer' } : {}}
              >
                <Col md={6}>
                  <div className="services-single__left">
                    <img src={postImage} alt=""/>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="services-single__right">
                    <SubTitle
                      text={postTitle}
                      margin={'0 0 60px 0'}
                      color='#FF6868'
                      fontSize={fontSize ? fontSize : 56}
                      lineHeight={lineHeight}
                    />
                    <Text color='#fff' fontSize={16}>
                      <p>{ReactHtmlParser(desc)}</p>
                    </Text>
                  </div>
                </Col>
              </Row>
          )
        }) : null}
      </Container>
    </StyledServices>
  )
};

const StyledServices = styled.div`
  padding: 10% 0%;
    .services-single{
    background-color: #333E4C;
     &:nth-of-type(odd){
          flex-direction: row-reverse;
        }
      .col-md-6{
        padding: 0;
      }
      .sub-title {
        color: #fff;
      }
      .services-single__left{
        padding-top: 87.85%;
        //position: relative;
       // &:after{
       // content: '';
       // height: 100%;
       // width: 100%;
       // left: 0;
       // top: 0;
       // background-color: rgba(0,0,0,0.5);
       // position: absolute;
       //}
        img{
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .services-single__right{
        padding-top: 100px;
        padding-left: 85px;
        padding-right: 85px;
        height: 100%;
      }
    }

  @media (max-width: 991px) {
    .services-single__right {
      padding-top: 10px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .sub-title {
        margin-bottom: 20px;
      }
    }

    .col-md-6 {
      &:nth-of-type(even) .services-single__right {
        padding-left: 30px !important;
      }

      &:nth-of-type(odd) .services-single__right {
        padding-right: 30px !important;
      }
    }

  }

  @media (max-width: 1660px) {
    .services-single__right {
      padding-top: 10px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .sub-title {
        margin-bottom: 20px;
      }
    }

    .col-md-6 {
      &:nth-of-type(even) .services-single__right {
        padding-left: 64px !important;
      }

      &:nth-of-type(odd) .services-single__right {
        padding-right: 30px !important;
      }
    }

  }

  @media (max-width: 1024px) {
    .services-single__right {
      padding-top: 10px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .sub-title {
        margin-bottom: 20px;
        font-size: 28px;
      }
    }

    .col-md-6 {
      &:nth-of-type(even) .services-single__right {
        padding-left: 30px !important;
      }

      &:nth-of-type(odd) .services-single__right {
        padding-right: 30px !important;
      }
    }

  }

  @media (max-width: 767px) {
    .services-single {
      padding-bottom: 50px;

      .col-md-6:nth-last-of-type(1) {
        padding: 0px;
      }

      &:nth-of-type(even) {
        display: flex;
        //flex-direction: column-reverse;
      }

      .col-md-6 .services-single__right {
        padding-left: 15px !important;
        padding-right: 15px !important;

        .sub-title {
          margin-top: 10px;
          margin-bottom: 10px;
            }
          }
      }
    }
`;

export default withRouter(AllServices);
