import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux'
import {setFooterOffset} from '../redux/actions/footer'
import mainLogo from '../localization/images/Logo.svg';
import {Link} from "react-router-dom";
import {setSectionId} from "../redux/actions/menu";
import mark from "../assets/images/Mask Group 13.svg"

const Footer = (props) => {
  let { setSectionId } = props
  useEffect(() => {
    let elem = document.getElementById("main-container")
    if(elem && elem.offsetLeft) {
      props.getFooterOffset(elem.offsetLeft)
    }
  }, [])
  return (
      <StyledFooter id="footer" className="footer">
        <img src={mark} className="mark"/>
        <Container className=" pt-110">
          <Row>
            <Col md={4}>
              <div className="footer__top">
                <img src={mainLogo} alt=""/>
              </div>
            </Col>
            <Col md={8}>
              <Row className="desktop-menu">
                <div className="footer__menu">
                  <div className="footer__menu__single">
                    <h4>About Us</h4>
                    <ul>
                      <li onClick={() => setSectionId('who-we-are')}>
                        <Link to={'/about'}>Our Services</Link>
                      </li>
                      <li onClick={() => setSectionId('mission')}>
                        <Link to={'/about'}>Referrals</Link>
                      </li>
                      <li onClick={() => setSectionId('award')}>
                        <Link to={'/about'}>Events</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer__menu__single">
                    <h4>SOCIAL</h4>
                    <ul>
                      <li onClick={() => setSectionId('division')}>
                        <Link to={'/'}>Facebook</Link>
                      </li>
                      <li onClick={() => setSectionId('division')}>
                        <Link to={'/'}>Youtube</Link>
                      </li>
                      <li onClick={() => setSectionId('division')}>
                        <Link to={'/'}>Google</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer__menu__single">
                    <h4>CONTACT US</h4>
                    <ul>
                      <li onClick={() => setSectionId('garments-services')}>
                        <Link to={'/contact'}>Location</Link>
                      </li>
                      <li onClick={() => setSectionId('printing-services')}>
                        <Link to={'/contact'}>Map</Link>
                      </li>
                      <li onClick={() => setSectionId('embroidery-services')}>
                        <Link to={'/contact'}>Enquiry</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer__menu__single">
                    <h4>TERMS & CONDITIONS</h4>
                    <ul>
                      <li onClick={() => setSectionId('media')}>
                        <Link to={'/'}>Privacy Policy</Link>
                      </li>
                      <li onClick={() => setSectionId('media')}>
                        <Link to={'/'}>Sitemap</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Row>
              <Row>
                <Col className="footer__bottom">
                  <p>© GPH Ispat 2020. All Rights Reserved.</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="footer__menu-mobile">

            <div className="footer__menu-mobile__single">
              <ul>
                <li><Link to={'/about'}>About Us</Link></li>
                <li><Link to={'/error'}>Social</Link></li>
                <li><Link to={'/contact'}>Contact Us</Link></li>
                <li><Link to={'/media'}>Terms and Conditions</Link></li>
              </ul>
            </div>

            {/*<div className="footer__menu-mobile__single division">*/}
            {/*    <ul>*/}
            {/*        <li><Link to={'/division'}>Divisions</Link></li>*/}
            {/*        <li><Link to={'/division'}>Washing</Link></li>*/}
            {/*        <li><Link to={'/division'}>Knitting</Link></li>*/}
            {/*        <li><Link to={'/division'}>Printing</Link></li>*/}
            {/*        <li><Link to={'/division'}>Garments</Link></li>*/}
            {/*        <li><Link to={'/division'}>Embroidery</Link></li>*/}

            {/*    </ul>*/}
            {/*</div>*/}

          </Row>

        </Container>
      </StyledFooter>
  )
};

const StyledFooter = styled.div`
  background-color: #303030;
  position: relative;
  .mark {
    position: absolute;
    bottom: 0;
    background-color: #303030;
  }

  //&:after{
  //  content: '';
  //  position: absolute;
  //  bottom: 80px;
  //  height: 1px;
  //  left: 0;
  //  width: 100%;
  //  background-color:rgba(0,0,0,0.25) ;
  //}

  .footer__top{
    margin-bottom: 58px;
    img{
      height: 55px;
    }
  }
  .footer__menu{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-bottom: 80px;
    border-bottom: .001rem solid #464749;
    &__single{
      display: flex;
      flex:1;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0 15px;
      h4{
        margin: 0 0 40px 0;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 500;
        color: #ffffff;
      }
      ul{
        li{
          a{
            font-size: 14px;
            font-weight: 100;
            line-height: 20px;
            display: block;
            margin-bottom: 14px;
            text-transform: capitalize;
            color: #ffffff;
            &:hover{
              text-decoration: underline;
            }
          }
          &:nth-last-of-type(1) a{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer__bottom{
    padding: 17px 15px;
    p{
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 3px;
      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
      a{
        color: #fff;
        &:hover{
          text-decoration: underline !important;
        }
      }
    }

  }
  .footer__menu-mobile{
    display: flex;
    margin-bottom: 60px;
  }
  .footer__menu-mobile__single{
  {
    padding: 0 15px;
    display: flex;
    flex: 1;
    ul{
      li{
        a{
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          display: block;
          margin-bottom: 20px;
          text-transform: capitalize;
          color: #fff;
        }
        &:nth-last-of-type(1) a{
          margin-bottom: 0;
        }
      }
    }
    &.division{
      ul{
        li{
          a{
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 8px;
            display: block;
            line-height: 24px;
          }
          &:nth-of-type(1) a{
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  }

  .footer__menu-mobile{
    width: 100%;
  }

  @media(min-width:768px){
    .footer__menu-mobile{
      display: none;
    }
  }
  @media(max-width:767px){
    .mark {
      display: none;
    }
    .footer__menu-mobile{
      margin-bottom: 94px;
    }
    .desktop-menu{
      display: none;
    }
  }

  @media (max-width:1030px){
    .footer__menu{
      flex-wrap: wrap;
      width: 100%;
    }
    .footer__menu__single{
      min-width: 25%;
      max-width: 25%;
      margin-bottom: 40px;
    }
  }
`;

function mapDispatchToProps(dispatch) {
  return {
    getFooterOffset: data => dispatch(setFooterOffset(data)),
    setSectionId: data => dispatch(setSectionId(data))
  }
}

export default connect(null, mapDispatchToProps)(Footer);







