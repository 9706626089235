import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchEventPublicationPage} from "../../redux/actions/event-publication";
import StyledGrid from "../../components/StyledGrid";
import {withRouter} from 'react-router-dom'
import styled from "styled-components";
import {Container, Table} from 'react-bootstrap';
import { BACKGROUND_IMAGE } from '../../config'
import ParallelPages from "../../components/ParallelPages";
import EventPublication from "../../components/EventPublication";
import SubTitle from "../../components/SubTitle";
import ReactHtmlParser from "react-html-parser";
import ReportAndMarket from "../../components/ReportAndMarket";
import PhotoGallery from "../../components/PhotoGallery";
import VideoGallery from "../../components/VideoGallery";

class PublicationInner extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if(this.props.match.params) {
      let params = {
        [ApiParams.page_slug]: this.props.match.params.page_slug
      }

      this.props.getEventPublicationPage(params)
    }
  }

  render() {
    let { publicationPage } = this.props;
    let pageData = publicationPage ? publicationPage.page_data : null
    let pageSub = pageData ? pageData.title : ''

    let bannerImagePath = publicationPage ?
      publicationPage.images ?
        publicationPage.images.list ?
          publicationPage.images.list[0] ?
            publicationPage.images.list[0].path + publicationPage.images.list[0].img_name : null
          : null
        : null
      : null

    let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

    let mediaImages = pageData ?
      pageData.template === 'images' ?
        publicationPage.images.list.filter(item => item.sort_order !== 0)
        : [] : []

    let videos = pageData ?
      pageData.template === 'video' ?
        publicationPage.posts ?
          publicationPage.posts.list ?
            publicationPage.posts.list : []
          : []
        : []
      : []

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <Menu/>
        <InnerBanner
          subTitle={pageSub}
          image={banner}
        />
        {pageData ? pageData.template === 'video' ? (
          <VideoGallery videos={videos} />
        ) : pageData.template === 'images' ? (
          <PhotoGallery mediaImages={mediaImages} />
        ) : (
          <StyledDivision>
            <div className="division-content__wrap" >
              <div className="division-content__wrap__at-a-glance">
                <SubTitle
                  text={pageData.sub_title}
                  margin={'0 0 70px 0'}
                  color='#ff6868'
                  fontSize={42}
                  lineHeight={30}
                  textAlign='center'
                />
                <Table hover variant="dark">
                  {
                    pageData.description ? ReactHtmlParser(pageData.description)
                      : null}
                </Table>
              </div>
            </div>
          </StyledDivision>
        ) : null}

        {pageData ? pageData.slug === 'code-of-conduct' ? <ReportAndMarket/> : <EventPublication/> : null}

        <ParallelPages
          backgroundColor={'#2E3744'}
          titleColor={'#fff'}
          titleText={'parallel pages'}
          color={'#69788D'}
          hoverColor={'#fff'}
          page={'publications'}
        />
      </motion.div>
    )
  }
};

const StyledDivision = styled.div`
  background-color: #262E39;
  padding: 10% 20%;
  .division-content__wrap{
    position: relative;
    z-index: 2;
    &__at-a-glance{
      position: relative;
      //&:before{
      //  content: '';
      //  position: absolute;
      //  height: 1px;
      //  left: -100px;
      //  right: -100px;
      //  background-color: #DFE6E5;
      //  top: 0;
      //}
      .table-dark {
        background-color: #262E39;
      }
      table{
        tr{
          border-color:#FAFAFA;
          &:nth-of-type(odd) {
            background-color: #2B333D;
          }
          &:nth-of-type(even) {
            background-color: #262E39;
          }
          &:nth-last-of-type(1){
            border-bottom: 0 solid #7d7d7d;
          }
          td{
            padding-left: 25px;
            padding-top: 25px;
            padding-bottom: 25px;
            font-size: 17px;
            font-weight: 100;
            line-height: 20px;
            color: #FAFAFA;
            
            img {
              padding: 5px 5px 8px 5px;
              width: 24px;
            }
          }
        }
      }
    }
  }
  @media(max-width: 480px) {
    padding: 10%;
  }
  @media(max-width: 365px) {
    padding: 6%;
  }
`

function mapStateToProps(state) {
  return {
    publicationPage: state.publicationReducer.publicationPage,
    sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getEventPublicationPage: params => dispatch(fetchEventPublicationPage(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicationInner));
