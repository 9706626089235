import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {COMMON} from "../../constants/common";
import {ApiServices} from "../../../network/ApiServices";

function* fetchCommonPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    );

    yield put({
      type: COMMON.FETCH_COMMON_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: COMMON.FETCH_COMMON_PAGE.FAILURE
    })
  }
}

function* fetchSustainablityPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    );

    yield put({
      type: COMMON.FETCH_SUSTAINABILITY_INNER.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: COMMON.FETCH_SUSTAINABILITY_INNER.FAILURE
    })
  }
}

function* fetchProductPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    );

    yield put({
      type: COMMON.FETCH_PRODUCTS_INNER.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: COMMON.FETCH_PRODUCTS_INNER.FAILURE
    })
  }
}

function* fetchInvestorPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    );

    yield put({
      type: COMMON.FETCH_INVESTORS_INNER.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: COMMON.FETCH_INVESTORS_INNER.FAILURE
    })
  }
}

export default function* commonSaga() {
  yield takeLatest(COMMON.FETCH_COMMON_PAGE.MAIN, fetchCommonPageData)
  yield takeLatest(COMMON.FETCH_SUSTAINABILITY_INNER.MAIN, fetchSustainablityPageData)
  yield takeLatest(COMMON.FETCH_PRODUCTS_INNER.MAIN, fetchProductPageData)
  yield takeLatest(COMMON.FETCH_INVESTORS_INNER.MAIN, fetchInvestorPageData)
}
