import {DIVISION} from "../../constants/division";

const initialState = {
  divPageData: [],
  divPageLoading: false,
  divPageErr: '',
  divListData: [],
  divListLoading: false,
  divListErr: '',
  divListChild: [],
  divListChildLoading: false,
  divListChildErr: '',
  divListChildPage: [],
  divChildPageLoading: false,
  divChildPageErr: ''
}

export default function divisionReducer(state = initialState, action) {
  switch (action.type) {
    case DIVISION.FETCH_DIVISION_PAGE.MAIN:
      return {
        ...state,
        divPageData: [],
        divPageLoading: true,
        divPageErr: ''
      }

    case DIVISION.FETCH_DIVISION_PAGE.SUCCESS:
      return {
        ...state,
        divPageData: action.result,
        divChildPageLoading: false,
        divPageErr: ''
      }

    case DIVISION.FETCH_DIVISION_PAGE.FAILURE:
      return {
        ...state,
        divPageData: [],
        divChildPageLoading: false,
        divPageErr: 'Some error Occurs'
      }

    case DIVISION.FETCH_DIVISION_LIST.MAIN:
      return {
        ...state,
        divListData: [],
        divListLoading: true,
        divListErr: ''
      }

    case DIVISION.FETCH_DIVISION_LIST.SUCCESS:
      return {
        ...state,
        divListData: action.result,
        divListLoading: false,
        divListErr: ''
      }

    case DIVISION.FETCH_DIVISION_LIST.FAILURE:
      return {
        ...state,
        divListData: [],
        divListLoading: false,
        divListErr: 'Some error occurs'
      }

    case DIVISION.FETCH_DIVISION_LIST_CHILD.MAIN:
      return {
        ...state,
        divListChild: [],
        divListChildLoading: true,
        divListChildErr: ''
      }

    case DIVISION.FETCH_DIVISION_LIST_CHILD.SUCCESS:
      return {
        ...state,
        divListChild: action.result,
        divListChildLoading: false,
        divListChildErr: ''
      }

    case DIVISION.FETCH_DIVISION_LIST_CHILD.FAILURE:
      return {
        ...state,
        divListChild: [],
        divListChildLoading: false,
        divListChildErr: 'Some error occurs'
      }

    case DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.MAIN:
      return {
        ...state,
        divListChildPage: [],
        divChildPageLoading: true,
        divChildPageErr: ''
      }

    case DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.SUCCESS:
      return {
        ...state,
        divListChildPage: action.result,
        divChildPageLoading: false,
        divChildPageErr: ''
      }

    case DIVISION.FETCH_DIVISION_LIST_CHILD_PAGE.FAILURE:
      return {
        ...state,
        divListChildPage: [],
        divChildPageLoading: false,
        divChildPageErr: 'Some error occurs'
      }

    default:
      return state
  }
}
