import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";
import {Link} from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";

const subTitle = ({textAlign, text, fontSize, fontWeight, color, letterSpacing, textTransform, lineHeight, margin}) => {
    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledSubTitle
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    textTransform={textTransform}
                    margin={margin}
                    textAlign={textAlign}
                    className={`${isVisible ? 'anim-active' : ''} fade-up sub-title`}
                >
                    {ReactHtmlParser(text)}
                </StyledSubTitle>
            }
        </VisibilitySensor>
    )
};


const StyledSubTitle = styled.h3`
    font-size: ${props => props.fontSize || 14}px;
    font-weight:${props => props.fontWeight || 500};
    margin:${props => props.margin || '0'}; ;
    line-height:${props => props.lineHeight || 21}px;
    color:${props => props.color || `${hover}`};
    text-transform:${props => props.textTransform || 'uppercase'};
    letter-spacing:${props => props.letterSpacing || 0}px;
    text-align: ${props => props.textAlign || null};
    @media (max-width:767px){
      font-size: 20px;
      margin-bottom: 30px;
    }
`;

export default subTitle;
