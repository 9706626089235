import {QUANTUM} from "../../constants/quantum";

export function fetchQuantumPage(params) {
  return {
    type: QUANTUM.FETCH_QUANTUM_PAGE.MAIN,
    params
  }
}

export function fetchQuantumTableOne(params) {
  return {
    type: QUANTUM.FETCH_TABLE_SECTION_ONE.MAIN,
    params
  }
}

export function fetchQuantumTableTwo(params) {
  return {
    type: QUANTUM.FETCH_TABLE_SECTION_TWO.MAIN,
    params
  }
}
