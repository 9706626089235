import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import {Container, Row, Col} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import Lines from '../../../components/VerticalLine';
import StyledGrid from "../../../components/StyledGridQuantam";

// img
import MissionImg from '../../../assets/images/dynamic/mission.jpg';
import goDown from '../../../assets/images/goDown.svg';
import $ from "jquery";

const GridSection = ({ posts }) => {
  let missionData = posts ? posts.filter(post => post.data.slug === 'mission-vision') : null

  useEffect(()=>{
    let getOffset = $('.container').offset().left + 15;
    if($(window).width() > 768){
      $('.mission-vision-banner-thumb').css({'padding-left': getOffset+170});
      $('.mission-vision-banner-thumb >img').css({'left': getOffset+70})
    }
  });

  return (
    <StyleMissionVision>
      <Container id="mission">
        <StyledGrid data={missionData} lineHeight={45} fontSize={42} />
      </Container>
    </StyleMissionVision>
  )
};

const StyleMissionVision = styled.div`
  position: relative;
  background-color: #2E3744;
  .mission-vision-banner-thumb{
    padding-left: 170px;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    //@media(min-width: 992px){
    //  max-width: 960px;
    //}
    //@media(min-width: 1200px){
    //  max-width: 1140px;
    //}
    //@media  (min-width: 1440px) {
    //    width: 1366px;
    //}
    >img{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 70px;
    }
    &__img{
      padding-top: 54.64%;
      position: relative;
      overflow: hidden;
       >img{
         width: 100%;
         object-fit: cover;
         position: absolute;
         top: 0;
         left: 0;
         bottom: 0;
       }
    }
 
  }
  .mission-vision-content{
    margin-top: 100px;
    padding-left:170px;
    ul{
      margin-top: 30px;
      li{
        margin-bottom: 20px;
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
      }
    }
      
  }
  
  @media(max-width:991px){
    .mission-vision-banner-thumb{
    width: 90%;
    padding-left: 0;
    margin:0 auto;
      > img{
        display: none;
      }
    }
    .mission-vision-content{
      padding-left: 0;
    }
  }
  
  @media(max-width:767px){
    .mission-vision-banner-thumb{
      width: calc(100% - 30px);
    }
    .mission-vision-content{
      margin-top: 60px;
      ul li{
        margin-bottom: 15px;
      }
    }
    
    .sub-title{
      margin-top: 60px;
    }
    p{
      font-weight: 300;
    }
  }
  .go-down{
    animation: bouncing .800s ease-in-out infinite alternate;
  }
 @keyframes bouncing{
      0%{
        transform: translateY(0px);
      }
      100%{
        transform: translateY(15px);
      }
    }

`;

const StyledServices = styled.div`
    .services-single{
    background-color: #F9F9F9;
     &:nth-of-type(even){
          flex-direction: row-reverse;
        }
      .col-md-6{
        padding: 0;
      }
      .services-single__left{
        padding-top: 87.85%;
        position: relative;
        &:after{
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.5);
        position: absolute;
       }
        img{
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .services-single__right{
        padding-top: 100px;
        padding-left: 85px;
        padding-right: 85px;
        height: 100%;
      }
    }
    @media(max-width:991px){
      .services-single__right{
        padding-top: 10px !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title{
          margin-bottom: 20px;
        }
      }
      .col-md-6{
         &:nth-of-type(even) .services-single__right{
          padding-left: 30px !important;
        }
         &:nth-of-type(odd) .services-single__right{
          padding-right: 30px !important;
        }
      }
   
    }
    @media(max-width:767px){
      .services-single{
      padding-bottom: 50px;
       .col-md-6:nth-last-of-type(1){
        padding:0 15px;
      }
      &:nth-of-type(even){
        display: flex;
        //flex-direction: column-reverse;
      }
         .col-md-6 .services-single__right{
            padding-left: 15px !important;
            padding-right: 15px !important;
            
            .sub-title{
               margin-top:10px;
               margin-bottom: 10px;
            }
          }
      }
    }
`;

export default GridSection;
