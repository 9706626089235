import React, {Component} from 'react';
import Banner from './components/Banner';
import Form from './components/form';
import Menu from '../../components/Menu';
import {fetchFormData} from "../../redux/actions/form";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Map from './components/ContactMap';
import {fetchContactPageData} from "../../redux/actions/contact";
import InnerBanner from "../../components/InnerBanner";
import {baseUrl} from "../../components/BaseUrl";
import Address from "./components/OfficeAddress";
import styled from 'styled-components';

class Contact extends Component{
    componentDidMount() {
      window.scrollTo(0, 0);
      let param = {
        [ApiParams.form_slug]: 'contact-form'
      };
      this.props.getFormData(param)

      let param2 = {
        [ApiParams.page_type]: 'contact'
      }
      this.props.getContactData(param2)
    }

    render() {
      let { address } = this.props

      let pageData = address ? address.page_data : null
      let pageSub = pageData ? pageData.title : ''

      let bannerImagePath = address ?
        address.images ?
          address.images.list ?
            address.images.list[0] ?
              address.images.list[0].path + address.images.list[0].img_name : null
            : null
          : null
        : null

      let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

      let backgroundImage = address ?
        address.images ?
          address.images.list ?
            address.images.list.length ?
              address.images.list.find(item => item.short_title === 'steel') : null
            : null
          : null
        : null

      let background = backgroundImage ? baseUrl + backgroundImage.path + backgroundImage.img_name : ''

      let mapImage = address ?
        address.images ?
          address.images.list ?
            address.images.list.length ?
              address.images.list.find(item => item.short_title === 'map') : null
            : null
          : null
        : null

      let mapBackground = mapImage ? baseUrl + mapImage.path + mapImage.img_name : ''

      let posts = address ? address.posts ? address.posts.list  : [] : []

      return(
        <motion.div variants={PageAnimation} animate="show" exit="exit" initial="hidden">
          <Menu />
          <InnerBanner
            subTitle={pageSub}
            image={banner}
          />
          <Address posts={posts} background={background} />
          {/*<Map />*/}
          <img src={mapBackground} width={'100%'} />
          <Form />
        </motion.div>
      )
    }
};

const StyledMap = styled.div`
  background-image: url(${props => props.background ||  ''});
`

function mapStateToProps(state) {
  return {
    address: state.contactReducer.address
  }
}

let mapDispatchToProps = dispatch => {
  return {
    getFormData: params => dispatch(fetchFormData(params)),
    getContactData: (params) => dispatch(fetchContactPageData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
