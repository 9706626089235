import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import ceoImg from '../../../localization/images/chairman.png';
import ceoImgMobile from '../../../assets/images/dynamic/ceo-mobile.jpg';
import Button from "../../../components/Button"
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import Modal from "../../../components/Modal";
import $ from "jquery";

const CeoMessage = ({ posts }) => {

  let [openModal, setOpenModal] = useState(false)
  let [title, setTitle] = useState('')
  let [description, setDescription] = useState('')

  let ceoPost = posts ? posts.find(post => post.data.slug === 'ceo') : null
  let ceoData = ceoPost ? ceoPost.data ? ceoPost.data : null : null

  let ceoImageList = ceoPost ? ceoPost.images ? ceoPost.images : [] : []

  let deskObj = ceoImageList ? ceoImageList.find(image => image.short_title === 'ceo') : null

  let deskImage = deskObj ? baseUrl + deskObj.path + deskObj.img_name : ''

  let handleModal = () => {
    setOpenModal(!openModal)
  }

  let handlePost = (title, description) => {
    setTitle(title)
    setDescription(description)
    handleModal()
  }

  useEffect(()=>{
    let getOffset = $('.container').offset().left;
    if($(window).width() > 768){
      $('.ceo-message__text').css({'padding-left': getOffset});
    }
  });

  return (
    <StyledCeoMessage className="ceo-message">
      <Container fluid>
        <Modal
            show={openModal}
            handleClose={handleModal}
            post={description}
            title={title}
        />
        <Row>
          <Col xs={{span: 8}}>
            <div className="ceo-message__text pt-110">
              <SubTitle
                text={ceoData ? ceoData.title : ''}
                margin={'0 0 30px 0'}
                color="#474747"
                fontSize={54}
              />
              <SubTitle
                text={'chairman'}
                margin={'0 0 30px 0'}
                color="#474747"
                fontSize={18}
              />
              <Text color='#474747' margin={'0 0 20px 0'} fontSize={16} line={6} fontWeight={300}>
                <img src={deskImage} alt=""/>
                <p>
                  {ceoData ? ReactHtmlParser(ceoData.description) : ''}
                </p>
              </Text>
              <div onClick={() => handlePost(ceoData ? ceoData.title : '', ceoData ? ceoData.description : '')}>
                <Button
                    text={'Read more'}
                    color={'#474747'}
                    border={'1px solid #474747'}
                    fontSize={16}
                    margin={'0 0 30px 0'}
                />
              </div>
            </div>
          </Col>
          <Col xs={{ span: 4}}>
            <img src={deskImage} alt=""/>
          </Col>
        </Row>
      </Container>
    </StyledCeoMessage>
  )
};

const StyledCeoMessage = styled.div`
    background-color: #E2E2E2;
    background-position: left;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;

  .container {
    margin-right: 0;
    padding-right: 0;
  }
  .col-4 {
    padding-right: 0;
    direction: rtl;
    img{
      
      width: 100%;
      height: 100%;
      object-fit: cover;
    } 
  }
  
  .col-8 {
    img {
      display: none;
    }
  }

    @media(max-width:800px){
    .pt-110, .pt-150 {
      padding-right: 24px;
    }
      .col-8{
        min-width: 100%;
        margin-left: 0;
        img{
          display: block;
          max-width:100%;
          object-fit: contain;
          margin-bottom: 30px;
        }
      }
      p{
        font-size: 16px;
        font-weight: 300;
      }
      .col-4 {
        img {
          display: none;
        }
      }
    }
  @media(max-width:768px){
    .pt-110, .pt-150 {
      padding-right: 0px;
    }
  }


`;
export default CeoMessage;
