import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import SubTitle from './SubTitle';
import PreloadImage from 'react-preload-image';


const InnerBanner = ({title, subTitle,text, image})=>{
    return(
        <StyledBanner className="inner-banner">
            <PreloadImage
              style={{
                position: 'absolute',
                width: '100%',
                height:'100%',
                top:0,
                left:0,
                backgroundColor: '#DDD'
            }} src={image}
            />
            <img src={image} alt=""/>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="inner-banner__content">
                            <SubTitle
                                text={subTitle}
                                fontSize={'24'}
                                fontWeight={'500'}
                                letterSpacing={'2'}
                                margin={'0 0 20px 0'}
                                color='#ffffff'
                            />
                            {/*<Title lineHeight={'60'} text={title} color={'#FFF'} fontSize={'54'} font={'54'}*/}
                            {/*       margin={'0 0 30px 0'}/>*/}
                            {/*<Text color={'#FFF'}><p>{text}</p></Text>*/}
                        </div>

                        {/*<div className="inner-banner__goDown">*/}
                        {/*    <img src={goDown} alt=""/>*/}
                        {/*</div>*/}

                    </Col>
                </Row>
            </Container>
        </StyledBanner>
    )
};

const StyledBanner = styled.div`
  height: 60vh;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background-color: rgba(0,0,0,0.6);
    background-image: linear-gradient(to bottom, transparent, #00000090);
  }

  .container {
    height: 100%;
    position: relative;

    .row {
      height: 100%;
    }

    .col-md-12 {
      height: 100%;
    }

    p {
      width: 80%;
    }
  }

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .inner-banner__content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    width: 28%;
    padding: 16px 0 0 16px;
    // background-color: #00000061;
  }

  .inner-banner__goDown {
    position: absolute;
    right: 0;
    bottom: 150px;
    z-index: 2;
    animation: bouncing .800s ease-in-out infinite alternate;
  }

  @media (max-width: 767px) {
    .inner-banner__goDown {
      display: none;
    }

    .inner-banner__content {
      bottom: 0;
      left: 0;
      width: calc(100% - 30px);

      p {
        width: 100%;
      }
    }

    .sub-title {
      margin-bottom: 30px;
      font-size: 16px;
    }

    .title {
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
  @keyframes bouncing {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(15px);
    }
  }
`;

export default InnerBanner;
