import {createGlobalStyle} from 'styled-components';
import {darkGreen, hover} from './globalStyleVars';
import modalClose from './assets/images/close.svg';
import React,{useEffect} from 'react'
import './assets/fonts/stylesheet.css'

export default createGlobalStyle`
    body {
      background-image: url('https://res.cloudinary.com/app-alo-social/image/upload/v1617531146/Rectangle_7_2x_ratfhp.png');
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      -webkit-background-size: cover;
      background-size: cover;
    }
   
    body{
       font-family: 'DINNext' , sans-serif;
       margin: 0;
       padding: 0;
       overflow-x: hidden;
       color: #1B1D1D;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
    }
    a:hover {
      color: ${hover};
    }
    
    ::selection {
      background: ${hover};
      color: #000;
    }
    
    p, a, h1, h2, h4, h3 {
      color: #1B1D1D;
     
    }
    
    ul {
      margin: 0;
      padding: 0
    }
    
    li {
      list-style: none;
    }
    a:hover, a:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
    
    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
      outline: none;
      box-shadow: none;
    }
    
    table {
      width: 100%;
    }
    .form-control {
      box-shadow: none;
      outline: 0;
    
      &:focus {
        box-shadow: none;
      }
    }
  
    .p-0{
      padding:0 !important;
    }
    .pt-150{
      padding-top: 150px;
    }
    .pb-150{
      padding-bottom: 150px;
    }
    .pt-110{
      padding-top: 110px;
    }
    .pb-110{
      padding-bottom: 110px;
    }
    .swiper-button-disabled{
      opacity: .2;
    }
    .gmnoprint,.gm-control-active{
      opacity:0;
    }
    
    .swiper-button-next:after{
      display: none;
    }
    .swiper-button-prev:after{
      display: none;
    }
    
    @media  (min-width: 1440px) {
      .container {
        //min-width: 1366px;
        min-width: 80%;
        margin: auto;
      }
    }
    
    @media (max-width: 991px){
     
        .container {
            min-width: 95%;
        }
    }
    
    @media (max-width: 768px){
        .container, .container-md, .container-sm {
            max-width: 90%;
            overflow: hidden;
        }
    }
    
    @media (max-width: 767px){
        .container, .container-sm {
            max-width: 100%;
        }
          .pt-110,.pt-150{
              padding-top: 99px;
            }
            .pb-110,.pb-150{
              padding-bottom: 99px;
          }
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    
    #root{
      overflow-x: hidden;
    }
    
    
    .modal-header{
      padding: 0;
      padding-bottom: 27px;
      margin-bottom: 40px;
      border-bottom: 1px solid #ABABAB;
    }
    
     .modal-dialog{
        width: 80%;
        max-width:unset;
     }
      .modal-content{
        border-radius: 0;
        padding: 50px 70px;
        background-color: #262E39;
        .close{
          height: 50px;
          width: 50px;
          background-image: url(${modalClose});
          background-color: #262E39;
          background-size: 25px;
          background-position: center;
          padding: 0;
          background-repeat: no-repeat;
          position: absolute;
          right: -34px;
          opacity: 1;
          top: 16px;
          transition:all .3s ease;
          &:hover{
            background-color: ${darkGreen};
          }
          span{
              display: none;
          }
          
        }
      }
      .modal-body{
        padding: 0;
        .table-dark {
          background-color: #262E39;
        }
        table{
          color: #fff;
          tr{
            border-color:#FAFAFA;
            &:nth-of-type(odd) {
              background-color: #2B333D;
            }
            &:nth-of-type(even) {
              background-color: #262E39;
            }
            &:nth-last-of-type(1){
              border-bottom: 1px solid #7d7d7d;
            }
            td{
              padding-left: 25px;
              padding-top: 25px;
              padding-bottom: 25px;
              font-size: 17px;
              font-weight: 100;
              line-height: 20px;
              color: #FAFAFA;
              border-top: 1px solid #262E39 !important;
              border-bottom: 1px solid #262E39 !important;
            }
          }
        }
      }
      .modal-title{
          h2{
            font-size: 42px !important;
            font-weight: 500;
            line-height: 42px;
            margin-bottom: 0;
            color: #fff;
            text-transform: capitalize;
          }
      }
      
      @media(min-width: 768px){
        .modal-content .table-responsive{
          overflow: visible;
        }
      }
      
      @media (max-width:767px) {
        .modal-dialog{
          width: 100%;
          margin: 0;
        }
        .modal-content{
            padding: 50px 15px;
             .close{
              height: 40px;
              width: 40px;
              right: 16px;
              background-size: 20px;
            }
        }
        .modal-title h2 {
            font-size: 25px !important;
            line-height: 30px;
        }
      }
      
      
  .css-yk16xz-control,.css-1pahdxg-control{
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color:#D9D9D9 !important;
    box-shadow: none !important;
    .css-1wa3eu0-placeholder{
      font-weight: 300;
      line-height: 21px;
      color: rgba(0,0,0,0.5);
      outline: none;
    }
    .css-1okebmr-indicatorSeparator{
      display: none;
    }
    .css-tlfecz-indicatorContainer,.css-1gtu0rj-indicatorContainer{
      margin-right: 10px;
    }
  }
  .css-2613qy-menu{
    border-radius: 0 !important;
    margin-top: 0 !important;
  }
  
  .fade-up{
    transition: opacity .2s ease, transform .2s ease;
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s;
  }
  
  .anim-active{
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .2s;
    transition: opacity .6s ease, transform .6s ease;
  }
  
  .info-window{
    max-width:200px;
  }
  .gm-style-iw{
    border-radius: 0 !important;
  }
`;



