export const  PageAnimation = {
    hidden:{
        opacity:0,
        // y:100
    },
    show:{
        opacity:1,
        // y:0,
        transition:{
            duration:.4
        }
    },
    exit:{
        opacity:0,
        // y:100,
        transition:{
            duration:.4
        }
    }
};