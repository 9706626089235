import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {hover} from '../globalStyleVars';
import mainLogo from '../localization/images/Logo.svg';
import hambuerger from '../assets/images/menu-_hambuerger.svg'
import hambuergerWhite from '../assets/images/close.svg';
import {Link, useLocation} from 'react-router-dom';
import menuBg from '../assets/images/dynamic/about-detail.jpg';
import $ from 'jquery';
import {setSectionId} from "../redux/actions/menu";
import {connect} from 'react-redux'

const Menu = ({logo, color, img, setSectionId}) => {
    let [menuItems, setMenuItems] = useState(false);
    let [newClass, setNewClass] = useState('');
    let [mobileSlide, setMobileSlide] = useState('');

    let handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenuItems(!menuItems);
    };

    let handleClose = () => {
        setMenuItems(false)
    };

    let {pathname} = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass('menu-fixed')
            } else {
                setNewClass('')
            }
        });

        // menu click on mobile
        $('.mobile-menu .menuItems__top__single').each(function () {
           let $this = $(this);
            if($this.find('ul').length > 0){
                $this.addClass('hasChild')
            }
        });

        $('.hasChild').on('click',function () {
            var $this_ul = $(this).find('ul'),
                $this = $(this);
            $('.hasChild ul').not($this_ul).slideUp();
            $('.hasChild').not($this).removeClass('active');
            $this_ul.slideToggle();
            $this.toggleClass('active');
        });

        $('.mobile-menu .menuItems__top__single li a').click(function () {
            $('.menu-items').removeClass('true');
            $('.menu-bar').removeClass('true');

        })
    }, []);

    return (
        <div>
            <StyledMenu className={`menu-bar ${newClass} ${menuItems}`} color={color}>
                <Container>
                    <Row>
                        <Col className="menu-bar__logo"><Link to="/"><img src={logo || mainLogo} alt=""/></Link> </Col>
                        {/*<Col className="menu-bar__logo"><a href="/"><img src={logo || mainLogo} alt=""/></a> </Col>*/}
                        <Col xs={9} className="menu-bar__menu-lists">
                            <ul onClick={handleClose}>
                                <li><Link className={pathname === '/' ? 'active' : ''} to="/">Home</Link></li>
                                <li>
                                    <Link className={pathname === '/about' ? 'active' : ''} to="/about">About Us</Link>
                                </li>
                                <li><Link className={pathname === '/quantum' ? 'active' : ''} to="/quantum">Quantam EAF</Link></li>
                                <li>
                                    <div className="dropdown">
                                        <Link className={pathname === '/products' ? 'active' : ''} to="/products">Product</Link>
                                        <ul className="drop">
                                            <li className="dropdown-nested">
                                                <Link to="/products/products-list">Products</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/products-detail/product-specification">Product Specification</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/products-detail/chemical-composition">Chemical Composition</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/products-detail/mechanical-properties">Mechanical Properties</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/products-detail/production-process">Production Process</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/products">Service</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown">
                                        <Link className={pathname === '/sustainability' ? 'active' : ''} to="/sustainability">Sustainability</Link>
                                        <ul className="drop">
                                            <li className="dropdown-nested">
                                                <Link to="/sustainability/environment">Environment</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/sustainability/remarkable-customer">Remarkable Customer</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/sustainability/finance">Finance</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/sustainability/social">Social</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/sustainability/technological">Technological</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/sustainability/compliance">Compliance</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown">
                                        <Link className={pathname === '/investor' ? 'active' : ''} to="/investor">Investor</Link>
                                        <ul className="drop">
                                            <li className="dropdown-nested">
                                                <Link to="/investor/annual-report">Annual Report</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor/share-holdings-status">Share Holdings Status</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor/corporate-governance">Corporate Governance</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor/code-of-conduct">Code Of Conduct</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor">Share Related Matter</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor/right-share-related-matter">Sensitive Information</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor/proxy-form">Proxy From</Link>
                                            </li>
                                            <li className="dropdown-nested">
                                                <Link to="/investor/notice-for-shareholders">Notice For Shareholders</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li><Link className={pathname === '/career' ? 'active' : ''} to="/career">Career</Link></li>
                                <li><Link className={pathname === '/contact' ? 'active' : ''} to="/contact">Contact</Link></li>
                                <li className="menu-button" onClick={handleMenuToggle}>
                                    <a href="javascript:void(0)">
                                        <img src={img || hambuerger} alt=""/>
                                        <img src={hambuergerWhite} alt=""/>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </StyledMenu>

            {/*toggle menu */}


            <StyleToggleMenu className={`menu-items ${menuItems}`}>
                <Container>
                    <div className="mobile-menu" >
                        <div className="menuItems__top">
                            <div className={`menuItems__top__single`}>
                                <Link to={'/about'}>GPH ISPAT</Link>
                                {/*<ul className="has-sub">*/}
                                {/*    <li onClick={() => setSectionId('who-we-are')}>*/}
                                {/*        <Link onClick={handleClose} to={'/about'}>Who we are</Link>*/}
                                {/*    </li>*/}
                                {/*    <li onClick={() => setSectionId('mission')}>*/}
                                {/*        <Link onClick={handleClose} to={'/about'}>Vision & Mission</Link>*/}
                                {/*    </li>*/}
                                {/*    <li onClick={() => setSectionId('award')}>*/}
                                {/*        <Link onClick={handleClose} to={'/about'}>Certification</Link>*/}
                                {/*    </li>*/}
                                {/*    <li><Link onClick={handleClose} to={'/career'}>Career</Link></li>*/}
                                {/*</ul>*/}
                            </div>


                            <div className="menuItems__top__single">
                                <Link to={'/quantum'}>Quantum EAF</Link>
                                {/*<ul className="has-sub">*/}
                                {/*    <li onClick={() => setSectionId('division')}>*/}
                                {/*        <Link onClick={handleClose} to={'/division'}>Washing</Link>*/}
                                {/*    </li>*/}
                                {/*    <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Knitting</Link></li>*/}
                                {/*    <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Printing</Link></li>*/}
                                {/*    <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Garments</Link></li>*/}
                                {/*    <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Embroidery</Link></li>*/}
                                {/*    <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Composite</Link></li>*/}
                                {/*</ul>*/}
                            </div>


                            <div className="menuItems__top__single">
                                <Link to={'/products'}>Product</Link>
                                <ul className="has-sub">
                                    <li>
                                        <Link to="/products/products-list">Products</Link>
                                    </li>
                                    <li>
                                        <Link to="/products-detail/product-specification">Product Specification</Link>
                                    </li>
                                    <li>
                                        <Link to="/products-detail/chemical-composition">Chemical Composition</Link>
                                    </li>
                                    <li>
                                        <Link to="/products-detail/mechanical-properties">Mechanical Properties</Link>
                                    </li>
                                    <li>
                                        <Link to="/products-detail/production-process">Production Process</Link>
                                    </li>
                                    <li>
                                        <Link to="/products">Service</Link>
                                    </li>
                                </ul>
                            </div>


                            <div className="menuItems__top__single">
                                <Link to={'/sustainability'}>Sustainability</Link>
                                <ul className="has-sub">
                                    <li>
                                        <Link to="/sustainability/environment">Environment</Link>
                                    </li>
                                    <li>
                                        <Link to="/sustainability/remarkable-customer">Remarkable Customer</Link>
                                    </li>
                                    <li>
                                        <Link to="/sustainability/finance">Finance</Link>
                                    </li>
                                    <li>
                                        <Link to="/sustainability/social">Social</Link>
                                    </li>
                                    <li>
                                        <Link to="/sustainability/technological">Technological</Link>
                                    </li>
                                    <li>
                                        <Link to="/sustainability/compliance">Compliance</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="menuItems__top__single">
                                <Link to={'/investor'}>Investor</Link>
                                <ul className="has-sub">
                                    <li>
                                        <Link to="/investor/annual-report">Annual Report</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor/share-holdings-status">Share Holdings Status</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor/corporate-governance">Corporate Governance</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor/code-of-conduct">Code Of Conduct</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor">Share Related Matter</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor/right-share-related-matter">Sensitive Information</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor/proxy-form">Proxy From</Link>
                                    </li>
                                    <li>
                                        <Link to="/investor/notice-for-shareholders">Notice For Shareholders</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="menuItems__top__single">
                                <Link to={'/career'}>Career</Link>
                            </div>

                            <div className="menuItems__top__single">
                                <Link to={'/contact'}>Contact</Link>
                            </div>

                        </div>
                    </div>
                </Container>
            </StyleToggleMenu>


        </div>
    )
};
const StyledMenu = styled.div`
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  height: 90px;
  align-items: center;
  display: flex;
  background-color: #4B5260;
  transition: background-color .3s ease;
  
  .container {
    height: 100%;
  }

  .row {
    align-items: center;
    height: 100%;
  }

  &.menu-fixed {
    background-color: #4B5260;

    .menu-bar__menu-lists {
      ul {
        li {
          a {
            &:hover, &.active {
                  color: ${hover}
                }
              }
              
            }
          }
      }
  }

  &.true {
    background-color: #4B5260;
  }

  .menu-bar__logo {
    img {
      height: 54px;
      object-fit: contain;
    }
  }

  //.col-9 {
  //  max-width: 75%;
  //}

  .menu-bar__menu-lists {
    height: 100%;
    ul{
      height: 100%;
      li{
        height: 100%;
        a {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
    .dropdown{
      position: relative;
      height: 100%;
      &:hover {
        .drop {
          visibility: visible;
          opacity: 1;
          transform: none;
        }
      }
      .drop {
        position: absolute;
        top: 90px;
        display: flow-root;
        width: 100%;
        min-width: 190px;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;
        transform: translateY(30px);
        box-shadow: 1.5px 1.5px #88888821;
        background-color: #757b88;
        height: auto;
        li{
          height: auto;
          a {
            height: auto;
          }
        }
        
       .dropdown-nested {
         padding: 4px 14px 3px 14px;
         cursor: pointer;
         font-size: 15px !important;
         color: #fff;
         a{
           font-size: 15px !important;
           color: #fff;
           font-weight: normal;
         }
         &:hover {
           background-color: #00000050;
         }
       }
      }
    }
    
    
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        a {
          font-size: 18px;
          font-weight: 100;
          line-height: 27px;
            text-transform: capitalize;
            color:${props => props.color || '#ffffff'};
            transition:color .3s ease;
            &:hover,&.active{
              color:${hover};
            }
          }
          &:nth-last-of-type(1) a{
            transition:opacity .4s ease;
            transition-delay: 0s;
            &:hover{
              opacity:.4 !important;
            }
          }
          &:nth-last-of-type(1) a{
            img:nth-last-of-type(1){
              display: none;
              height: 22px;
            }
          }
        }
      }
    }
    
    &.true{
      li:not(:last-child) a{
       opacity: 0 !important;
       visibility: hidden !important;
      }
      li:nth-last-of-type(1) a img{
        &:nth-of-type(1) {
          display: none;
        }

        &:nth-of-type(2) {
          display: block !important;
        }
      }
    }

    @media (min-width: 992px) {
        .menu-button {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .menu-button {
            display: block;
        }
    }

  @media (max-width: 991px) {
    .col-9 {
      max-width: 20%;
    }

    .menu-bar__menu-lists {
      ul {
        justify-content: flex-end;

        li:not(:last-child) {
          display: none;
        }
      }
    }
  }

  @media(max-width:767px){
      height: 65px;
      .menu-bar__logo img{
        height: 45px;
      }
    }
`;

const StyleToggleMenu = styled.div`
    position: fixed;
    z-index: 8;
    height: 100vh;
    width: 100%;
    background: ${hover};
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition:all .6s ease;
    &.true{
      opacity:1;
      visibility: visible;
      
    }
  .menuItems__top {
    height: calc(100vh - 130px);
    display: flex;
    padding:80px;
    position: relative;
    //background:  #0366C2;
    margin-top: 90px;
    max-height:600px;
    
    &__bg{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 100%;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.8);
        right: 0;
        bottom: 0;
      }
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__single {
      position: relative;
      z-index: 3;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: max-content;
      flex-direction: column;
      padding-right: 8px;
      > a {
        font-size: 25px;
        font-weight: 500;
        color: #ffffff;
        text-transform: capitalize;
        line-height: 30px;
        margin-bottom: 26px;
        transition:letter-spacing .6s ease;
        &:hover{
          letter-spacing: 2px;
        }
      }

      ul {
        text-align: left;
        li {
          a {
            display: block;
            font-size: 16px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 18px;
            transition:padding .4s ease;
            &:hover{
              padding-left: 10px;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }
      }

    }
  }
  
  @media(min-width:1440px){
    @media(min-width:800px){
       display: flex;
        align-items: center;
        .menuItems__top{
          padding: 100px;
          margin-top: 0;
        }
    }
   
  }


  @media (min-width: 992px) {
    .mobile-menu {
      display: none;
    }
  }

  @media (max-width: 991px) {
    height: 100vh;
    padding-top: 30px;
    background-color: #465467;
    .menuItems__top {
      height: auto;
      display: block;
      padding: 0px 0px 0 0px;
      position: relative;
      min-height: 100vh;
      background-color: transparent;

      &__bg {
        display: none !important;
      }

      &__single {
        display: block;
        width: 100%;

        > a {
          font-size: 17px;
          display: block;
          margin-bottom: 15px
        }

        ul {
          display: none;
        }
      }
    }

    .Footer__social {
      margin-top: 50px;
    }

    .desktop-menu {
      display: none;
    }

    .mobile-menu {
      .menuItems__top__single.active > a {
        color: #ffffff;
        opacity: .7;
      }

      ul {
        li {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }

  .menuItems__top__single {
    position: relative;

    &:after {
      content: '';
      background-image: url(${hambuergerWhite});
      background-size: 13px;
      position: absolute;
      right: -17px;
    height: 50px;
    width: 50px;
    top: -12px;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(45deg);
      margin: auto;
      z-index: 3;
      display: none;
      transition: all .4s ease;
    }
    ul{
      margin-bottom: 30px;
    }
    &.hasChild{
      &:after{
        display: block;
      }
    }
    &.active{
      &:after{
        transform:none;
      }
      > a{
        color: ${hover};
      ul li a{
        color: #d4d4d4 !important;
      }
      }
    }
  

}
`;

function mapDispatchToProps(dispatch) {
    return {
        setSectionId: data => dispatch(setSectionId(data))
    }
}
export default connect(null, mapDispatchToProps)(Menu);
