import {ABOUT} from "../../constants/about";

let initialState = {
  aboutMain: [],
  aboutMainLoading: false,
  aboutMainErr: '',
  aboutHistory: [],
  aboutHistoryLoading: false,
  aboutHistoryErr: ''
};

export default function aboutReducer(state = initialState, action) {
  switch (action.type) {
    case ABOUT.FETCH_ABOUT_PAGE.MAIN:
      return {
        ...state,
        aboutMain: [],
        aboutMainLoading: true,
        aboutMainErr: ''
      };

    case ABOUT.FETCH_ABOUT_PAGE.SUCCESS:
      return {
        ...state,
        aboutMain: action.result,
        aboutMainLoading: false,
        aboutMainErr: ''
      };

    case ABOUT.FETCH_ABOUT_PAGE.FAILURE:
      return {
        ...state,
        aboutMain: [],
        aboutMainLoading: false,
        aboutMainErr: 'Error from Server'
      };

    case ABOUT.FETCH_ABOUT_HISTORY.MAIN:
      return {
        ...state,
        aboutHistory: [],
        aboutHistoryLoading: true,
        aboutHistoryErr: ''
      };

    case  ABOUT.FETCH_ABOUT_HISTORY.SUCCESS:
      return {
        ...state,
        aboutHistory: action.result,
        aboutHistoryLoading: false,
        aboutHistoryErr: ''
      };

    case ABOUT.FETCH_ABOUT_HISTORY.FAILURE:
      return {
        ...state,
        aboutHistory: [],
        aboutHistoryLoading: false,
        aboutHistoryErr: 'Error from server'
      };

    default:
      return state
  }
}
