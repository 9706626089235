import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import BannerImg from "../../assets/images/dynamic/inner-banner.jpg";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchCommonPageData} from "../../redux/actions/common";
import StyledGrid from "../../components/StyledSmallGrid";
import {withRouter} from 'react-router-dom'
import styled from "styled-components";
import {Container} from 'react-bootstrap';

class CommonComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if(this.props.match.params) {
      let params = {
        [ApiParams.page_type]: this.props.match.params.page_type
      }

      this.props.getCommonPageData(params)
    }
  }

  render() {
    let { commonPage } = this.props;
    let pageData = commonPage ? commonPage.page_data : null
    let pageSub = pageData ? pageData.title : ''

    let bannerImagePath = commonPage ?
      commonPage.images ?
        commonPage.images.list ?
          commonPage.images.list[0] ?
            commonPage.images.list[0].path + commonPage.images.list[0].img_name : null
          : null
        : null
      : null

    let banner = bannerImagePath ? baseUrl + bannerImagePath : ''

    let posts = commonPage ? commonPage.posts ? commonPage.posts.list : [] : []

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <Menu/>
        <InnerBanner
          subTitle={pageSub}
          image={banner}
        />
        {pageData ?
            <StyledMidSection>
              <Container>
                <StyledGrid
                  data={posts}
                  fontSize={this.props.match.params.page_type === 'news' ? 16 : 42}
                  lineHeight={40}
                  page={this.props.match.params.page_type === 'news' ? null: this.props.match.params.page_type }
                />
              </Container>
            </StyledMidSection>
          : null}
      </motion.div>
    )
  }
};

const StyledMidSection = styled.div`
  position: relative;
  background-color: #2E3744;

 @keyframes bouncing{
      0%{
        transform: translateY(0px);
      }
      100%{
        transform: translateY(15px);
      }
    }

`;


function mapStateToProps(state) {
  return {
    commonPage: state.commonReducer.commonPage,
    sectionId: state.menuReducer.sectionId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCommonPageData: params => dispatch(fetchCommonPageData(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommonComponent));

