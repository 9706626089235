import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Select from "react-select";
import {connect} from 'react-redux'
import {setSectionId} from "../../../redux/actions/menu";

const options = [
    {value: 'Knitting', label: 'Knitting'},
    {value: 'Printing', label: 'Printing'},
    {value: 'Garments', label: 'Garments'}
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#1EC3AA' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};

class ServiceFilter extends Component {
    state = {
        activePage: 0
    };

    handleActivePage = (key, slug) => {
        this.setState({ activePage: key })
        this.props.setSectionId(slug)
    };

    handleMobileOption = (slug) => {
        this.props.setSectionId(slug)
    };

    render() {
        let { services } = this.props

        let mobileOptions = services ? services.map(service => {
            let option = {
                value: service.data.slug,
                label: service.data.title
            };
            return option
        }) : []

        return (
          <div>
              <StyledFilterServices className="services-filter">
                  <Container>
                      <Row>
                          <Col xs={12}>
                              <SubTitle text={'all services'} color={'#58585A'} margin={'0 0 30px 0'}/>
                          </Col>

                          <Col xs={12}>
                              <ul className="services__menu">
                                  {/*{serviceList.length ? serviceList.map((service, key) => {*/}
                                  {/*    if(key === 0) {*/}
                                  {/*        return (*/}
                                  {/*          <li><Link to={`${path}`}>{service.title}</Link></li>*/}
                                  {/*        )*/}
                                  {/*    } else {*/}
                                  {/*        return <li><Link to={`${path}/${service.slug}`}>{service.title}</Link></li>*/}
                                  {/*    }*/}
                                  {/*}) : null}*/}
                                  {services.length ? services.map((service, key) => (
                                    <li
                                      className={key === this.state.activePage ? "active" : ""}
                                      onClick={() => this.handleActivePage(key, service.data.slug)}
                                    >
                                        {/*<a>{service.data.title}</a>*/}
                                        {service.data.title}
                                    </li>
                                  )) : ''}
                              </ul>
                          </Col>

                          <Col sm={12} className='mobile-filter'>
                              <Select
                                styles={customStyles}
                                className='select-here'
                                placeholder='Select Division'
                                options={mobileOptions}
                                onChange={op => this.handleMobileOption(op.value)}
                              />
                          </Col>

                      </Row>
                  </Container>
              </StyledFilterServices>
          </div>
        )
    }
};


// filter
const StyledFilterServices = styled.div`
  background-color: #F3FCFB;
  padding:50px 0;
  .container{
    overflow: visible;
  }
  .services__menu{
    display: flex;
    justify-content: space-between;
  li{
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: rgba(0,0,0,0.15);
    display: block;
    text-transform: capitalize;
    transition:color .3s ease;
      &:hover{
        color: #000;
      }
    a{
      font-size: 30px;
      font-weight: bold;
      line-height: 30px;
      color: rgba(0,0,0,0.15);
      display: block;
      text-transform: capitalize;
      transition:color .3s ease;
      &:hover{
        color: #000;
      }
    }
     &.active a{
      color: #000;
    }
  }
 }
 
 @media(max-width:991px){
  .services__menu li a{
    font-size: 18px;
  }
 }
 
 @media(max-width:767px){
 padding: 30px 0;
  .services__menu{
     display: none;
  }
  .sub-title{
    margin-bottom: 0;
  }
 }

  .css-g1d714-ValueContainer{
    padding-left: 0;
  }
  .css-yk16xz-control,.css-1pahdxg-control{
    padding-left: 0;
     border:none;
    border-bottom:1px solid #D9D9D9 !important;
    background-color: transparent !important;
  }
  .css-tlfecz-indicatorContainer{
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
   .css-1uccc91-singleValue{
    font-size: 20px;
    font-weight: bold;
   }
    
    @media(min-width:768px){
      .mobile-filter{
        display: none;  
      }
    }
`;

function mapDispatchToProps(dispatch) {
    return {
        setSectionId: data => dispatch(setSectionId(data))
    }
}

export default connect(null, mapDispatchToProps)(ServiceFilter);
