export const QUANTUM = {
  FETCH_QUANTUM_PAGE: {
    MAIN: 'FETCH_QUANTUM_PAGE',
    SUCCESS: 'FETCH_QUANTUM_PAGE_SUCCESS',
    FAILURE: 'FETCH_QUANTUM_PAGE_FAILURE'
  },
  FETCH_TABLE_SECTION_ONE: {
    MAIN: 'FETCH_TABLE_SECTION_ONE',
    SUCCESS: 'FETCH_TABLE_SECTION_ONE_SUCCESS',
    FAILURE: 'FETCH_TABLE_SECTION_ONE_FAILURE'
  },
  FETCH_TABLE_SECTION_TWO: {
    MAIN: 'FETCH_TABLE_SECTION_TWO',
    SUCCESS: 'FETCH_TABLE_SECTION_TWO_SUCCESS',
    FAILURE: 'FETCH_TABLE_SECTION_TWO_FAILURE'
  }
}
