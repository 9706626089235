import React, {Component} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import {hover} from '../../../globalStyleVars';
import {connect} from 'react-redux';
import {fetchHomeBannerSlider} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import SubText from '../../../components/SubText'
import $ from 'jquery';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img
import TopQuotation from '../../../assets/images/up-quotation.svg'
import DownQuotation from '../../../assets/images/down-quotation.svg'
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";

function RightArrow(props) {

    const {className, style, onClick} = props;
    return (
        <li className="go-right" onClick={onClick}><img src={DownQuotation} height="51" width="51" alt=""/></li>
    );
}

function LeftArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className="go-left" onClick={onClick}><img src={TopQuotation} height="51" width="51" alt=""/></li>
    );
}

class CareerSlider extends Component {
  state = {
    leftOffset: 0
  }

  componentDidMount() {
    let elem = document.getElementById("main-container")
    if (elem && elem.offsetLeft) {
      this.setState({leftOffset: elem.offsetLeft})
    }

    $('#ScrollTo').click(function () {

      var $target = $(this.hash);
      $target = $target.length && $target ||
        $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        $('html,body')
        .animate({scrollTop: targetOffset}, 1000);
        return false;
      }

    });
  }

  render() {
    let { posts } = this.props

    let sliderPost = posts ? posts.filter(item => item.data.slug === 'testimonial') : []

    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5500,
      autoplay: true,
      pauseOnHover: false,
      nextArrow: <RightArrow/>,
      prevArrow: <LeftArrow/>
    };

    return (
      <StyledSlide offset={this.state.leftOffset} className="pt-110 pb-110">
        <SubText text={'Testimonial'} fontSize={56}/>
        <Slick {...settings}>
          {sliderPost ? sliderPost.map((item) => (
            <div key={item.data.id}>
              <Container>
                <div className="content-inner">
                  <p>
                    {ReactHtmlParser(item.data.description)}
                  </p>
                  <h2>{ReactHtmlParser(item.data["Extra description one"])}</h2>
                  <h4>{ReactHtmlParser(item.data["Extra description two"])}</h4>
                </div>
              </Container>
            </div>
          )) : ''}
        </Slick>
      </StyledSlide>
    )
  }
}

const StyledSlide = styled.div`
  background-color: #272E39;
  text-align: center;

  .container {
    text-align: center;
    width: 100%;
  }

  .content-inner {
    width: 60%;
    display: inline-block;
    padding-top: 32px;
    padding-bottom: 32px;

    h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: #FFF;
      transform: translateY(35px);
      transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
      opacity: 0;
      text-transform: uppercase;
      padding-top: 24px;
    }

    h4 {
      font-size: 13px;
      font-weight: 100;
      line-height: 0;
      color: #FFF;
      transform: translateY(35px);
      transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
      opacity: 0;
      text-transform: capitalize;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      color: #FFF;
      transform: translateY(35px);
      //transition-delay:.3s;
      padding-top: 32px;
      transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
      opacity: 0;
      margin: 0;
    }
  }
  
  .slick-dots {
    position: absolute;
    bottom: -48px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      button {
        &:before {
          color: #fff;
          font-size: 8px;
          opacity: 1;
        }
      }
    }

    li.slick-active button:before {
      color: #fff;
      opacity: 1;
      border: 1px solid #fff;
      border-radius: 10px;
    }
  }

  .slick-current {
    opacity: 1 !important;
    transition-delay: 0s !important;
    //transition: opacity 100ms ease 0s, visibility 100ms ease 0s !important;
  }

  .slick-active, .slick-current {
    h2, h4, p, .sub-title, .dc-btn {
      transform: none;
      opacity: 1;
    }

    .sub-title {
      transition-delay: .4s;
    }

    h2 {
      transition-delay: .7s;
    }

    p {
      transition-delay: 1s;
    }

    .dc-btn {
      cubic-bezier(0.16, 1, 0.3, 1);
      transition-delay: 1.3s;
    }
  }

  .go-left {
    position: absolute;
    z-index: 2;
    left: 23%;
    top: -64px;
    bottom: 0;
    height: fit-content;
    margin: auto;
    cursor: pointer;
  }

  .go-right {
    position: absolute;
    z-index: 2;
    right: 23%;
    top: 0;
    bottom: -110px;
    height: fit-content;
    margin: auto;
    cursor: pointer;
  }
  
  @media(max-width: 1450px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 22%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 22%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }
  }

  @media(max-width: 1366px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 20%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 20%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }
  }

  @media(max-width: 1266px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 18%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 18%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }
  }

  @media(max-width: 1199px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 21%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 21%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }
  }

  @media(max-width: 1110px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 19%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 19%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }
  }

  @media(max-width: 1024px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 18%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 18%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }
  }

  @media(max-width: 991px) {
    .go-left {
      position: absolute;
      z-index: 2;
      left: 16%;
      top: -64px;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: 16%;
      top: 0;
      bottom: -110px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }
  }

  @media(max-width: 767px) {
    .go-left {
      display: none;
    }
    .go-right {
      display: none;
    }
    .sub-title {
      font-size: 32px;
    }
    .content-inner {
      width: 100%;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
`;

const StyledRadius = styled.div`
  position: absolute;
  z-index: 5;
  left: 15px;
  color: #FFF;
  top: 50%;
  margin: 0 auto;
  transform: rotate(-90deg) translateX(-50%);
  height: 26px;
  width: fit-content;
  transform-origin: left center;

  p {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledGoDown = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 50px;


  p {
    color: ${hover};
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 20px;
    position: relative;
    white-space: nowrap;
    transform: rotate(-269deg);
    transform-origin: left bottom;
    bottom: 90px;
    left: 14px;

    a {
      font-size: 12px;
      line-height: 24px;
      font-weight: 300;
      color: ${hover};
      text-transform: uppercase;
    }
  }
`;

export default CareerSlider;
