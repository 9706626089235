import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {PUBLICATION} from "../../constants/event-publication";

function* fetchEventPublications(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: PUBLICATION.FETCH_EVENT_PUBLICATIONS.SUCCESS,
      result: result.data
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: PUBLICATION.FETCH_EVENT_PUBLICATIONS.FAILURE
    })
  }
}

function* fetchEventPublicationPage(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    )

    yield put({
      type: PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.SUCCESS,
      result: result.data
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.FAILURE
    })
  }
}

export default function* publicationSaga() {
  yield takeLatest(PUBLICATION.FETCH_EVENT_PUBLICATIONS.MAIN, fetchEventPublications);
  yield takeLatest(PUBLICATION.FETCH_EVENT_PUBLICATIONS_PAGE.MAIN, fetchEventPublicationPage);
}
