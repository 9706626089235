import React, { Component } from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import div1 from "../../../assets/images/dynamic/div1.jpg";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import {baseUrl} from '../../../components/BaseUrl';
import { ApiParams } from "../../../constants/apiParams";
import { connect } from 'react-redux'
import { fetchDivisionListChildData } from '../../../redux/actions/division';
import Lines from '../../../components/VerticalLine';
import PreloadImage from 'react-preload-image';

class TabItems extends Component {
  componentDidMount() {
    if(!this.props.clicked) {
      let params = {
        [ApiParams.page_id]: this.props.tab_id
      };
      this.props.getDivisionListChildData(params)
    }
  }

  render() {
    let { divListChild } = this.props
    let tabItems = divListChild ? divListChild : []
    return (
      <StyledDivicionItem className="division-items">
        <Lines />
        <Container>
          <Row>
            {tabItems ? tabItems.map((tab, key) => {
              let itemImg = tab.images ?
                tab.images.list[0] ? baseUrl + tab.images.list[0].path + '/' + tab.images.list[0].img_name : div1
                : div1
              let { page_data }= tab
              return (
                <Col key={page_data.id} md={6}>
                  <div className="division-items__single">
                    <Link to={`/division-detail/${page_data.slug}`} />
                    <div className="division-items__single__img">
                        <PreloadImage style={{
                            position: 'absolute',
                            width: '100%',
                            height:'100%',
                            top:0,
                            left:0,
                            backgroundColor: '#DDD'
                        }} src={itemImg} />
                      {/*<img src={itemImg} alt=""/>*/}
                    </div>

                    <div className="division-items__single__content">
                      <SubTitle
                        text={page_data.sub_title}
                        color={'#989898'}
                        margin={'0 0 15px 0'}
                      />
                      <Title
                        fontSize={'20'}
                        fontWeight={'bold'}
                        lineHeight={'30'}
                        margin={'0 0 15px 0'}
                        text={page_data.title}
                      />
                      <Text
                        fontWeight={'300'}
                        color={'#1EC3AA'}
                        fontSize={'14'}
                      >
                        <p>{page_data.short_desc}</p>
                      </Text>
                    </div>
                  </div>
                </Col>
              )
            }) : null}
          </Row>
        </Container>
      </StyledDivicionItem>
    )
  }
}

const StyledDivicionItem = styled.div`
  padding-top: 100px;
  min-height: 70vh;
  position: relative;
  .row {
    display: flex;
    justify-content: space-between;
  }
  .col-md-6{
    max-width: calc(50% - 45px);
  }
  .division-items__single{
    margin-bottom: 100px;
    position: relative;
    z-index: 3;
    >a{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3;
    }
    &__img{
      padding-top: 63.64%;
      position: relative;
      overflow: hidden;
      img{
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transition:all 2.5s ease;
        transform: scale(1.01);
      }
    }
    &__content{
      margin-top: 29px;
    }
    &:hover{
      img {
        transform: scale(1.05);
        filter:brightness(120%);
      }
    }
  }
  @media(max-width:992px){
    .col-md-6{
      min-width:calc(50% - 15px);
    }
  }
  @media(max-width:767px){
    padding-top: 80px;
  }
  @media(max-width:600px){
    .col-md-6{
      min-width:calc(100%);
    }
    .division-items__single{
      margin-bottom: 40px;
      &__content{
        margin-top: 13px;
        h3{
          display: none;
        }
        h2{
          margin-bottom: 10px;
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    divListChild: state.divisionReducer.divListChild
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDivisionListChildData: params => dispatch(fetchDivisionListChildData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabItems)
